import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useGetLinkUrlWithJotform } from 'components/domain/jotform/jotformGclid.utils';

const Link = styled(BsLink)(({ theme }) => ({
  ...theme.link.uiWhite,
  textDecoration: 'none !important',
}));

const FooterFeedbackBoxWrapper = styled.div(({ theme: { shadows } }) =>
  sx2CssThemeFn({
    boxShadow: shadows.blockShadow,
    display: 'flex',
    alignItems: 'center',
    pt: ['20px', 0],
  })
);

const FeedbackBoxModal = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      maxWidth: ['initial', '572px'],
      maxHeight: ['80vh', '600px'],
      height: '100%',
      borderRadius: 'unset',
      padding: 0,
      mx: [3, 0],
      mb: ['20%', 0],
      overflowY: 'auto',
    },
    '.modal-header': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      borderBottom: 'none',
      zIndex: 1,
    },
  })
);

const ResponsiveIFrame = styled.iframe(
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: '10px',
    width: '100%',
    height: '100%',
    border: 'none',
  })
);

const FooterFeedbackBox = () => {
  const { t } = useTranslation('common');
  const [
    isShowFeedbackModal,
    showFeedbackModal,
    hideFeedbackModal,
  ] = useToggleState(false);

  const jotformLink = useGetLinkUrlWithJotform(
    t('common:footer_feedback_box.link')
  );

  return (
    <FooterFeedbackBoxWrapper>
      <Link onClick={showFeedbackModal} icon={{ name: 'feedback' }}>
        <span className="link-text">
          {t('common:footer_feedback_box.linkName')}
        </span>
      </Link>
      <FeedbackBoxModal
        name="FeedbackBoxModal"
        show={isShowFeedbackModal}
        title=""
        onClose={hideFeedbackModal}
      >
        <ResponsiveIFrame
          title={`footer_feedback_box`}
          src={jotformLink}
          width="100%"
        />
      </FeedbackBoxModal>
    </FooterFeedbackBoxWrapper>
  );
};

export default FooterFeedbackBox;
