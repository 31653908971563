import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetBookmarkGroupsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetBookmarkGroupsQuery = {
  __typename?: 'Query';
  bookmarkPage: {
    __typename?: 'BookmarkPage';
    allBookmarkGroups: {
      __typename?: 'AllBookmarkGroupResponse';
      bookmarkGroups?: Array<{
        __typename?: 'BookmarkGroup';
        name?: string | null;
        groupId?: string | null;
        bookmarksCount: number;
        groupImage?: {
          __typename?: 'Image';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
        shareUrl?: {
          __typename?: 'InternalLink';
          caption?: string | null;
          targetPageType: Types.PageType;
          uri: string;
        } | null;
      }> | null;
      page: {
        __typename?: 'Page';
        pageNumber: number;
        resultsPerPage?: number | null;
        resultsTotal?: number | null;
      };
    };
  };
};

export const GetBookmarkGroupsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBookmarkGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookmarkPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allBookmarkGroups' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'pageNumber' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resultsPerPage' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resultsTotal' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bookmarkGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bookmarksCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupImage' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'criteria' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_mobile',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '274',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '250',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_desktop',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '274',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '250',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ResizedImageWithMeta',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shareUrl' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'targetPageType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'page' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resultsPerPage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resultsTotal' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetBookmarkGroupsQuery__
 *
 * To run a query within a React component, call `useGetBookmarkGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkGroupsQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetBookmarkGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBookmarkGroupsQuery,
    GetBookmarkGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetBookmarkGroupsQuery,
    GetBookmarkGroupsQueryVariables
  >(GetBookmarkGroupsDocument, options);
}
export function useGetBookmarkGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBookmarkGroupsQuery,
    GetBookmarkGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetBookmarkGroupsQuery,
    GetBookmarkGroupsQueryVariables
  >(GetBookmarkGroupsDocument, options);
}
export type GetBookmarkGroupsQueryHookResult = ReturnType<
  typeof useGetBookmarkGroupsQuery
>;
export type GetBookmarkGroupsLazyQueryHookResult = ReturnType<
  typeof useGetBookmarkGroupsLazyQuery
>;
export type GetBookmarkGroupsQueryResult = Apollo.QueryResult<
  GetBookmarkGroupsQuery,
  GetBookmarkGroupsQueryVariables
>;
