import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import HelpOverlay from 'components/domain/helpOverlay/HelpOverlay';
import { HelpOverlayMobile } from 'components/domain/helpOverlay/HelpOverlayMobile';
import {
  trackHelpOverlayCloseClick,
  trackHelpOverlayOpenClick,
} from 'components/domain/tracking/helpOverlayTracking';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

const CONTACT_SITE_TOOL_DATA_ID = 'contacts-site-tool';

const ContactsSiteTool = () => {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const helpOverlayButtonRef = useRef<HTMLButtonElement>(null);
  const helpOverlayDropdownRef = useRef<HTMLDivElement>(null);

  const [isHelpVisible, , closeHelp, toggleHelp] = useToggleState(false);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [helpOverlayDropdownRef, helpOverlayButtonRef],
    () => {
      if (!isHelpVisible) return;
      closeHelp();
    }
  );

  return (
    <>
      <SiteToolsButtonsWrapper
        className={`site-tool-button ${
          isHelpVisible ? 'help-overlay-shown' : ''
        }`}
        data-id={CONTACT_SITE_TOOL_DATA_ID}
      >
        <Button
          variant="headerBtn"
          icon={{ name: isHelpVisible ? 'close-icon' : 'contact' }}
          ref={helpOverlayButtonRef}
          onClick={() => {
            toggleHelp();

            isHelpVisible
              ? trackHelpOverlayCloseClick()
              : trackHelpOverlayOpenClick();
          }}
        />
        <span className="tooltip">{t('contact.button')}</span>
      </SiteToolsButtonsWrapper>

      {mobile && (
        <HelpOverlayMobile
          close={() => {
            closeHelp();
            trackHelpOverlayCloseClick();
          }}
          visible={isHelpVisible}
        />
      )}

      {!mobile && isHelpVisible && (
        <HelpOverlay dropDownRef={helpOverlayDropdownRef} onClose={closeHelp} />
      )}
    </>
  );
};

export default ContactsSiteTool;
