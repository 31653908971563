import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { AddressDetailsFragmentDoc } from 'graphql/fragments/AddressDetails.generated';
import { CoordinatesFragmentDoc } from 'graphql/fragments/Coordinates.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { OpeningHoursFragmentDoc } from 'graphql/fragments/OpeningHours.generated';
import { DisrupterFragmentDoc } from 'graphql/fragments/Disrupter.generated';
export type CommonAgencyFragment = {
  __typename?: 'Agency';
  id: string;
  title: string;
  brand: Types.AgencyBrand;
  mainText?: string | null;
  opened?: boolean | null;
  addressDetails: {
    __typename?: 'AddressDetails';
    city?: string | null;
    country?: string | null;
    region?: string | null;
    street?: string | null;
    zip?: string | null;
  };
  coordinates?: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  } | null;
  url: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
};

export type BaseAgencyFragment = {
  __typename?: 'Agency';
  timezone: string;
  id: string;
  title: string;
  brand: Types.AgencyBrand;
  mainText?: string | null;
  opened?: boolean | null;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  openingHours: Array<{
    __typename?: 'OpeningHours';
    open: boolean;
    shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
  }>;
  review?: {
    __typename?: 'Review';
    averageRating: number;
    totalReviewCount: number;
  } | null;
  addressDetails: {
    __typename?: 'AddressDetails';
    city?: string | null;
    country?: string | null;
    region?: string | null;
    street?: string | null;
    zip?: string | null;
  };
  coordinates?: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  } | null;
  url: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
};

export type AgencyRatingFragment = {
  __typename?: 'Review';
  averageRating: number;
  totalReviewCount: number;
};

export type AgencyFullTextSearchItemFragment = {
  __typename?: 'AgencyFullTextSearchItem';
  name: string;
  highlightedText?: string | null;
  opened?: boolean | null;
  bookmarked: boolean;
  mainText: string;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  addressDetails?: {
    __typename?: 'AddressDetails';
    city?: string | null;
    country?: string | null;
    region?: string | null;
    street?: string | null;
    zip?: string | null;
  } | null;
  disrupters?: Array<{
    __typename?: 'DisrupterObject';
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
  }> | null;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const CommonAgencyFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commonAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'addressDetails' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'url' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'opened' } },
        ],
      },
    },
    ...AddressDetailsFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const AgencyRatingFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'agencyRating' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Review' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalReviewCount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const BaseAgencyFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'baseAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'commonAgency' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '315' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'openingHours' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'review' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'agencyRating' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CommonAgencyFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...OpeningHoursFragmentDoc.definitions,
    ...AgencyRatingFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const AgencyFullTextSearchItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'agencyFullTextSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AgencyFullTextSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'addressDetails' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'opened' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'disrupter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '328' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '272' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bookmarked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...AddressDetailsFragmentDoc.definitions,
    ...DisrupterFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
