import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type RenameBookmarkGroupMutationVariables = Types.Exact<{
  groupId: Types.Scalars['String'];
  groupName: Types.Scalars['String'];
}>;

export type RenameBookmarkGroupMutation = {
  __typename?: 'Mutation';
  renameBookmarkGroup: {
    __typename?: 'BookmarkGroupRenameResponse';
    status?: Types.Status | null;
  };
};

export const RenameBookmarkGroupDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameBookmarkGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type RenameBookmarkGroupMutationFn = Apollo.MutationFunction<
  RenameBookmarkGroupMutation,
  RenameBookmarkGroupMutationVariables
>;

/**
 * __useRenameBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useRenameBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameBookmarkGroupMutation, { data, loading, error }] = useRenameBookmarkGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useRenameBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RenameBookmarkGroupMutation,
    RenameBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RenameBookmarkGroupMutation,
    RenameBookmarkGroupMutationVariables
  >(RenameBookmarkGroupDocument, options);
}
export type RenameBookmarkGroupMutationHookResult = ReturnType<
  typeof useRenameBookmarkGroupMutation
>;
export type RenameBookmarkGroupMutationResult = Apollo.MutationResult<RenameBookmarkGroupMutation>;
export type RenameBookmarkGroupMutationOptions = Apollo.BaseMutationOptions<
  RenameBookmarkGroupMutation,
  RenameBookmarkGroupMutationVariables
>;
