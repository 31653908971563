import * as React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import {
  RouterLink,
  IRouterLinkProps,
} from '@hotelplan/components.common.router-link';

const BreadcrumbList = styled.ul.attrs({
  'data-id': `breadcrumb-list`,
})(({ theme: { fontSizes, media } }) => ({
  fontSize: fontSizes[1],
  rowGap: '4px',
  [media.tablet]: {
    fontSize: fontSizes[2],
  },
}));

const BreadcrumbLink = styled(Link).attrs({
  'data-id': `breadcrumb-link`,
})<{ delimiter: string }>(({ delimiter, theme }) => ({
  display: 'inline',
  ...theme.link.breadcrumbLink,
  '&:not(:last-child):after': {
    position: 'absolute',
    display: 'inline-block',
    content: `"${delimiter}"`,
    marginLeft: '17px',
  },
}));

const BreadcrumbLabel = styled.div.attrs({
  'data-id': `breadcrumb-label`,
})<{ disabled?: boolean }>(({ disabled, theme: { colors, space } }) => ({
  color: disabled ? `black` : colors.primary,
  cursor: disabled ? 'auto' : 'pointer',
  display: 'inline-block',
  marginRight: space[2],
  border: '2px solid rgba(0,0,0,0.05)',
  borderRadius: '5px',
  padding: '4px 8px 4px 8px',
  marginBottom: space[2],
}));

interface IBreadcrumbProps {
  items: {
    label?: string | null;
    link?: IRouterLinkProps;
    disabled?: boolean;
    onClick?: () => void;
  }[];
  testId?: string;
  className?: string;
  delimiter?: string;
}

const Breadcrumb: React.FC<IBreadcrumbProps> = props => {
  const { items, testId, delimiter = '/', className } = props;

  return (
    <BreadcrumbList className={className} data-id={testId}>
      {items.map((item, i) => {
        return (
          <React.Fragment key={i}>
            {item.disabled || !item.link ? (
              <BreadcrumbLabel disabled={item.disabled} onClick={item.onClick}>
                {item.label}
              </BreadcrumbLabel>
            ) : (
              <RouterLink {...item.link} passHref>
                <BreadcrumbLink delimiter={delimiter} onClick={item.onClick}>
                  {item.label}
                </BreadcrumbLink>
              </RouterLink>
            )}
          </React.Fragment>
        );
      })}
    </BreadcrumbList>
  );
};

export default Breadcrumb;
