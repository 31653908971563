import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type PopUpMenuQueryQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type PopUpMenuQueryQuery = {
  __typename?: 'Query';
  popUpMenu: {
    __typename?: 'PopUpMenuData';
    items: Array<{
      __typename?: 'IconMenuItem';
      title?: string | null;
      icon: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      };
      link?:
        | { __typename?: 'ExternalLink' }
        | {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          }
        | null;
    }>;
  };
};

export const PopUpMenuQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'popUpMenuQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'popUpMenu' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'criteria' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: 'string',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '50' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '50' },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ResizedImageWithMeta',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'internalLinkFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __usePopUpMenuQueryQuery__
 *
 * To run a query within a React component, call `usePopUpMenuQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopUpMenuQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopUpMenuQueryQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function usePopUpMenuQueryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PopUpMenuQueryQuery,
    PopUpMenuQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PopUpMenuQueryQuery,
    PopUpMenuQueryQueryVariables
  >(PopUpMenuQueryDocument, options);
}
export function usePopUpMenuQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PopUpMenuQueryQuery,
    PopUpMenuQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PopUpMenuQueryQuery,
    PopUpMenuQueryQueryVariables
  >(PopUpMenuQueryDocument, options);
}
export type PopUpMenuQueryQueryHookResult = ReturnType<
  typeof usePopUpMenuQueryQuery
>;
export type PopUpMenuQueryLazyQueryHookResult = ReturnType<
  typeof usePopUpMenuQueryLazyQuery
>;
export type PopUpMenuQueryQueryResult = Apollo.QueryResult<
  PopUpMenuQueryQuery,
  PopUpMenuQueryQueryVariables
>;
