import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type CreateBookmarkGroupMutationVariables = Types.Exact<{
  groupName: Types.Scalars['String'];
}>;

export type CreateBookmarkGroupMutation = {
  __typename?: 'Mutation';
  createBookmarkGroup: {
    __typename?: 'BookmarkGroupCreateResponse';
    status?: Types.Status | null;
    groupId?: string | null;
    groupName?: string | null;
  };
};

export const CreateBookmarkGroupDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBookmarkGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type CreateBookmarkGroupMutationFn = Apollo.MutationFunction<
  CreateBookmarkGroupMutation,
  CreateBookmarkGroupMutationVariables
>;

/**
 * __useCreateBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkGroupMutation, { data, loading, error }] = useCreateBookmarkGroupMutation({
 *   variables: {
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useCreateBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBookmarkGroupMutation,
    CreateBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateBookmarkGroupMutation,
    CreateBookmarkGroupMutationVariables
  >(CreateBookmarkGroupDocument, options);
}
export type CreateBookmarkGroupMutationHookResult = ReturnType<
  typeof useCreateBookmarkGroupMutation
>;
export type CreateBookmarkGroupMutationResult = Apollo.MutationResult<CreateBookmarkGroupMutation>;
export type CreateBookmarkGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateBookmarkGroupMutation,
  CreateBookmarkGroupMutationVariables
>;
