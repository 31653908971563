import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { getNavigationLinks } from '@hotelplan/components.common.menu';
import { Navigable } from '@hotelplan/graphql.types';
import { useGetHeaderQuery } from 'graphql/layout/GetHeader.generated';
import tptLogo from 'public/images/Tpt-logo.svg';
import thLogo from 'public/images/TravelHouse-logo.svg';

export function useHeaderNavigation() {
  const { data, loading } = useGetHeaderQuery();
  const [t, { language }] = useTranslation();

  const logo = useMemo<INormalizedImage>(() => {
    const hardcodedLogo = language === 'de' ? thLogo : tptLogo;

    return (
      data?.pageHeader?.logo || {
        alt: t('copyright.mainText'),
        resized: [
          { id: '1x_desktop', url: hardcodedLogo },
          { id: '1x_mobile', url: hardcodedLogo },
        ],
      }
    );
  }, [data, language]);

  return useMemo(
    () => ({
      loading,
      logo,
      navigationLinks: getNavigationLinks(
        (data?.pageHeader.navigationMenu || []) as Array<Navigable>
      ),
    }),
    [data, loading]
  );
}
