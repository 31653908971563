import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';

const ResetButton = styled(Button)(({ theme: { space, colors } }) => ({
  position: 'absolute',
  right: space[3],
  top: '50%',
  transform: 'translateY(-50%)',
  color: colors.lightGreySecond,
  svg: {
    color: colors.lightGreySecond,
  },
  '&:hover': {
    backgroundColor: 'initial',
  },
}));

export default ResetButton;
