import { useCreateBookmarkGroupMutation } from 'graphql/bookmark/CreateBookmarkGroup.generated';

export function useCreateBookmarkGroup() {
  const [mutation, { data, loading }] = useCreateBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['GetBookmarkGroups'],
  });

  async function createBookmarkGroup(groupName: string) {
    return mutation({
      variables: {
        groupName,
      },
    });
  }

  return {
    createBookmarkGroup,
    data,
    loading,
  };
}
