import { useEffect, useState } from 'react';
import { useAddBookmarkItemsMutation } from 'graphql/bookmark/AddBookmarkItems.generated';
import { useDeleteBookmarkItemsMutation } from 'graphql/bookmark/DeleteBookmarkItems.generated';
import { TBookmarkInput } from './Bookmark.types';

interface IUpdateBookmarks {
  (
    toAdd: TBookmarkInput[] | undefined,
    toDelete: TBookmarkInput[] | undefined
  ): Promise<void>;
}

export function useUpdateBookmarks(): {
  loading: boolean;
  updateBookmarks: IUpdateBookmarks;
} {
  const [loading, setLoading] = useState<boolean>(false);
  const [bookmarkUpdateList, setBookmarkUpdateList] = useState<
    {
      toAdd: TBookmarkInput[];
      toDelete: TBookmarkInput[];
    }[]
  >([]);
  const [addBookmarksMutation] = useAddBookmarkItemsMutation();
  const [deleteBookmarksMutation] = useDeleteBookmarkItemsMutation();

  const updateBookmarks: IUpdateBookmarks = async (toAdd, toDelete) => {
    if (toAdd?.length || toDelete?.length) {
      setBookmarkUpdateList(prevState => {
        return prevState.concat([
          {
            toAdd: toAdd || [],
            toDelete: toDelete || [],
          },
        ]);
      });
    }
  };

  useEffect(() => {
    const processUpdate = async () => {
      if (!loading && bookmarkUpdateList.length) {
        setLoading(true);

        const bookmarkUpdateItem = bookmarkUpdateList[0];

        setBookmarkUpdateList(prev => {
          return prev.slice(1);
        });

        try {
          if (bookmarkUpdateItem.toAdd.length) {
            await addBookmarksMutation({
              variables: {
                bookmarks: bookmarkUpdateItem.toAdd,
              },
            });
          }

          if (bookmarkUpdateItem.toDelete.length) {
            await deleteBookmarksMutation({
              variables: { bookmarks: bookmarkUpdateItem.toDelete },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };

    processUpdate();
  }, [loading, bookmarkUpdateList]);

  return {
    updateBookmarks,
    loading,
  };
}
