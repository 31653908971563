import React, { Dispatch, useEffect } from 'react';
import { useGetBookmarkItemsQuery } from 'graphql/bookmark/GetBookmarkItems.generated';
import {
  BookmarkContextActionType,
  TBookmarkContextAction,
} from './Bookmark.types';

interface IBookmarkGroupQueryItem {
  groupId: string;
  dispatch: Dispatch<TBookmarkContextAction>;
}

const BookmarkGroupItemsQueryItem: React.FC<IBookmarkGroupQueryItem> = ({
  groupId,
  dispatch,
}) => {
  const { data, loading, error } = useGetBookmarkItemsQuery({
    variables: { groupId },
    ssr: false,
  });

  useEffect(() => {
    if (!loading) {
      let items = [];

      if (data?.bookmarkPage?.bookmarkItems && !error) {
        items = data.bookmarkPage.bookmarkItems;
      }

      dispatch({
        type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS,
        payload: {
          id: groupId,
          items: items.map(i => ({
            id: i.link.objectId,
            pageType: i.link.targetPageType,
          })),
        },
      });
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (loading) {
      dispatch({
        type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS_LOADING,
        payload: {
          id: groupId,
          loading: true,
        },
      });
    }
  }, [loading]);

  return null;
};

export default BookmarkGroupItemsQueryItem;
