import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { Status } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  BookmarkModalPopupType,
  TShareBookmarkGroupReplace,
  useBookmarkModalPopupContext,
} from './BookmarkModalPopupContext';
import {
  BookmarkPopup,
  BookmarkPopupButtonRow,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentView,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './BookmarkPopup.styles';
import useAddSharedBookmarkGroup from './useAddSharedBookmarkGroup';
import useRedirectToBookmarkPage from './useRedirectToBookmarkPage';

const BookmarkGroupShareModalPopupButtonRow = styled(BookmarkPopupButtonRow)(
  () =>
    sx2CssThemeFn({
      flexDirection: ['column-reverse' as const, 'row'],
      button: {
        width: ['100%', 'initial'],
        marginTop: ['10px', 'initial'],
        '&:last-child': {
          marginTop: [0, 'initial'],
        },
      },
    })
);

const BookmarkGroupShareModalPopup: React.FC = () => {
  const bookmarkModalPopupContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common', 'bookmark']);
  const { redirect: redirectToBookmarkPage } = useRedirectToBookmarkPage();
  const {
    payload: { bookmarkGroup },
  } = bookmarkModalPopupContext.modal as TShareBookmarkGroupReplace;

  const {
    addSharedBookmarkGroupMutation,
    addSharedBookmarkGroupMutationData,
    addSharedBookmarkGroupMutationLoading,
    addSharedBookmarkGroupMutationError,
  } = useAddSharedBookmarkGroup();

  useEffect(() => {
    if (
      addSharedBookmarkGroupMutationData?.addSharedBookmarkGroup?.status ===
        Status.Failure ||
      addSharedBookmarkGroupMutationError
    ) {
      bookmarkModalPopupContext.open({
        type: BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_ERROR,
      });
    } else if (
      addSharedBookmarkGroupMutationData?.addSharedBookmarkGroup?.status ===
      Status.Success
    ) {
      bookmarkModalPopupContext.close();
      redirectToBookmarkPage();
    }
  }, [addSharedBookmarkGroupMutationError, addSharedBookmarkGroupMutationData]);

  const onApply = useCallback(() => {
    if (
      addSharedBookmarkGroupMutationLoading ||
      addSharedBookmarkGroupMutationData
    )
      return;

    addSharedBookmarkGroupMutation({
      variables: { bookmarkGroup },
    });
  }, [
    addSharedBookmarkGroupMutationLoading,
    addSharedBookmarkGroupMutationData,
  ]);

  const onCancel = useCallback(() => {
    bookmarkModalPopupContext.close();
    redirectToBookmarkPage();
  }, []);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark:group.share.replace.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={onCancel}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent view={BookmarkPopupContentView.COMPACT}>
        <BookmarkPopupTextRow>
          <p>{t('bookmark:group.share.replace.text-1')}</p>
          <p>{t('bookmark:group.share.replace.text-2')}</p>
        </BookmarkPopupTextRow>
        <BookmarkGroupShareModalPopupButtonRow>
          <Button variant="tagBtn" onClick={onCancel}>
            {t('bookmark:group.share.replace.cancel')}
          </Button>
          <Button
            disabled={addSharedBookmarkGroupMutationLoading}
            className="form-submit-button"
            onClick={onApply}
          >
            {t('bookmark:group.share.replace.apply')}
          </Button>
        </BookmarkGroupShareModalPopupButtonRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default withLazyHydrate(BookmarkGroupShareModalPopup, {
  whenIdle: true,
  noWrapper: true,
});
