import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AddFullTextSearchHistoryMutationVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type AddFullTextSearchHistoryMutation = {
  __typename?: 'Mutation';
  addFullTextSearchHistory: {
    __typename?: 'SrlHistoryResponse';
    status: Types.Status;
    message?: string | null;
  };
};

export const AddFullTextSearchHistoryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFullTextSearchHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFullTextSearchHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keywords' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type AddFullTextSearchHistoryMutationFn = Apollo.MutationFunction<
  AddFullTextSearchHistoryMutation,
  AddFullTextSearchHistoryMutationVariables
>;

/**
 * __useAddFullTextSearchHistoryMutation__
 *
 * To run a mutation, you first call `useAddFullTextSearchHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFullTextSearchHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFullTextSearchHistoryMutation, { data, loading, error }] = useAddFullTextSearchHistoryMutation({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useAddFullTextSearchHistoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddFullTextSearchHistoryMutation,
    AddFullTextSearchHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddFullTextSearchHistoryMutation,
    AddFullTextSearchHistoryMutationVariables
  >(AddFullTextSearchHistoryDocument, options);
}
export type AddFullTextSearchHistoryMutationHookResult = ReturnType<
  typeof useAddFullTextSearchHistoryMutation
>;
export type AddFullTextSearchHistoryMutationResult = Apollo.MutationResult<AddFullTextSearchHistoryMutation>;
export type AddFullTextSearchHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddFullTextSearchHistoryMutation,
  AddFullTextSearchHistoryMutationVariables
>;
