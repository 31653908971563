import { TFunction } from 'i18next';
import first from 'lodash/first';
import last from 'lodash/last';
import { OpeningHours, Shift } from '@hotelplan/graphql.types';

export function mapOpeningHoursToSchedules(
  t: TFunction,
  openingHours: OpeningHours[]
): string[] {
  const time = (hms: string) => hms.split(':').splice(0, 2).join(':');

  const shortDay = (i = 0) =>
    t(
      [
        'common:week.shorten.monday',
        'common:week.shorten.tuesday',
        'common:week.shorten.wednesday',
        'common:week.shorten.thursday',
        'common:week.shorten.friday',
        'common:week.shorten.saturday',
        'common:week.shorten.sunday',
      ][i]
    );

  const WORKING_DAYS = openingHours.filter(item => item.open).length;

  const daysForSchedule = openingHours
    .map(({ shifts, open }, index) =>
      open || (!open && index < WORKING_DAYS) ? shifts : []
    )
    .map(item => {
      return item.length === 0
        ? [{ from: t('agency:openingHours.closed') }]
        : item;
    })
    .map((shifts: Shift[]) => {
      return shifts
        .map(item => {
          return item.to
            ? t('agency:openingHours.shift', {
                from: time(item.from),
                to: time(item.to),
              })
            : t('agency:openingHours.from', { from: time(item.from) });
        })
        .join(' / ');
    })
    .reduce((acc: { [key: string]: number[] }, schedule, i) => {
      if (acc[schedule]?.length) {
        acc[schedule].push(i);
      } else {
        acc[schedule] = [i];
      }
      return acc;
    }, {});

  return Object.entries(daysForSchedule)
    .sort((a, b) => (a[1][0] > b[1][0] ? 1 : -1))
    .filter(([schedules]) => schedules)
    .map(([schedule, days]) => {
      const closedDaysWithoutWeekend = days.filter(day => day < WORKING_DAYS);
      if (closedDaysWithoutWeekend.length === 0) return;
      return closedDaysWithoutWeekend.length === 1
        ? `${shortDay(first(days))}: ${schedule}`
        : `${t('agency:openingHours.daysRange', {
            from: shortDay(first(days)),
            to: shortDay(last(days)),
          })}: ${schedule}`;
    });
}
