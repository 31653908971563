import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type CopyBookmarkMutationVariables = Types.Exact<{
  pageType: Types.PageType;
  groupIdFrom: Types.Scalars['String'];
  groupIdTo: Types.Scalars['String'];
  objectId: Types.Scalars['String'];
}>;

export type CopyBookmarkMutation = {
  __typename?: 'Mutation';
  copyBookmark: {
    __typename?: 'BookmarkResponse';
    status: Types.Status;
    message?: string | null;
  };
};

export const CopyBookmarkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyBookmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIdFrom' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIdTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupIdFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIdFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupIdTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIdTo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type CopyBookmarkMutationFn = Apollo.MutationFunction<
  CopyBookmarkMutation,
  CopyBookmarkMutationVariables
>;

/**
 * __useCopyBookmarkMutation__
 *
 * To run a mutation, you first call `useCopyBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyBookmarkMutation, { data, loading, error }] = useCopyBookmarkMutation({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      groupIdFrom: // value for 'groupIdFrom'
 *      groupIdTo: // value for 'groupIdTo'
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useCopyBookmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyBookmarkMutation,
    CopyBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CopyBookmarkMutation,
    CopyBookmarkMutationVariables
  >(CopyBookmarkDocument, options);
}
export type CopyBookmarkMutationHookResult = ReturnType<
  typeof useCopyBookmarkMutation
>;
export type CopyBookmarkMutationResult = Apollo.MutationResult<CopyBookmarkMutation>;
export type CopyBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CopyBookmarkMutation,
  CopyBookmarkMutationVariables
>;
