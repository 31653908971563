import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { Container } from 'components/domain/container';
import {
  CardsSection,
  AdaptiveCardsSlider,
} from 'components/domain/customizable-slider/CardsSection';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';

interface ISliderSectionProps {
  count?: number;
  title?: string;
  titleColor?: string;
  itemsPerPage?: number;
  className?: string;
  customContent?: React.ReactNode;
  imageHeight?: number;
  isSliderPagination?: boolean;
  trackingSource?: PaginationArrowClickSource;
  sectionHeaderTitle?: string;
  showHeader?: boolean;
}

const ITEMS_PER_PAGE = 3;

export function RecommendationSliderSection({
  title,
  titleColor,
  count,
  itemsPerPage = ITEMS_PER_PAGE,
  className,
  children,
  customContent,
  imageHeight,
  isSliderPagination,
  trackingSource,
  sectionHeaderTitle,
  showHeader = isSliderPagination,
}: PropsWithChildren<ISliderSectionProps>): React.ReactElement {
  const [page, setPage] = useState(0);
  const totalPages = Math.ceil(count / itemsPerPage);
  const { t } = useTranslation();

  return (
    <CardsSection
      className={className}
      title={title}
      sectionTitle={
        showHeader
          ? sectionHeaderTitle
            ? sectionHeaderTitle
            : t('product.recommender.section.title')
          : null
      }
      totalCount={showHeader && count}
      page={page}
      totalPages={totalPages}
      onPrevPage={() => setPage(current => (current > 0 ? current - 1 : 0))}
      onNextPage={() =>
        setPage(current => (current < totalPages - 1 ? current + 1 : 0))
      }
      headerStyle={{ color: titleColor }}
      isSliderPagination={isSliderPagination}
      imageHeight={imageHeight}
      trackingSource={trackingSource}
    >
      {customContent ? <>{customContent}</> : null}
      <AdaptiveCardsSlider
        page={page}
        onChangePage={next =>
          setPage(current =>
            next > 0 || next < totalPages - 1 ? next : current
          )
        }
        perPage={itemsPerPage}
        total={count}
      >
        {children}
      </AdaptiveCardsSlider>
    </CardsSection>
  );
}

export const RecommendationListContainer = styled(Container)(
  ({ theme: { media, space } }) => ({
    padding: `50px ${space[3]}`,
    paddingRight: 0,
    scrollMarginTop: '100px',
    [media.tablet]: {
      padding: '80px 40px',
    },
  })
);
