import { NetworkStatus, QueryResult } from '@apollo/client';
import { useCallback } from 'react';
import { useFetchingPagination } from '@hotelplan/libs.hooks';
import { calculateNextPage } from '@hotelplan/libs.utils';
import {
  HistoryUserRecordFragment,
  HistoryUserResponseFragment,
} from 'graphql/fragments/HistoryUserResponse.generated';

export interface IHistoryItems {
  items: HistoryUserRecordFragment[];
  page: number;
  hasMore: boolean;
  nextPage(): void | Promise<void>;
  loading: boolean;
}

interface IHistoryOptions<TData, TVariables = any> {
  useHistoryQuery(): QueryResult<TData, TVariables>;
  getHistoryResponse(data?: TData): HistoryUserResponseFragment | undefined;
}

export function useLastSearchedViewed<TData = any, TVariables = any>({
  useHistoryQuery,
  getHistoryResponse,
}: IHistoryOptions<TData, TVariables>): IHistoryItems {
  const { data, fetchMore, loading, networkStatus } = useHistoryQuery();

  const {
    userRecords = [],
    page: responsePage = {
      pageNumber: 0,
      resultsPerPage: 0,
      resultsTotal: 0,
    },
  } = getHistoryResponse(data) || {};

  const { pageNumber, resultsPerPage, resultsTotal } = responsePage;

  const nextPageNumber = calculateNextPage(
    pageNumber,
    resultsPerPage,
    resultsTotal
  );

  const onFetchMore = useCallback(
    async function () {
      await fetchMore({
        variables: {
          page: nextPageNumber,
        },
      });
    },
    [fetchMore, nextPageNumber]
  );

  const { items, page, hasMore, nextPage } = useFetchingPagination(
    userRecords,
    {
      page: pageNumber,
      perPage: resultsPerPage,
      total: resultsTotal,
      fetchMore: onFetchMore,
    }
  );

  return {
    items: networkStatus === NetworkStatus.refetch ? [] : items,
    page,
    hasMore,
    nextPage,
    loading,
  };
}
