import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FlaglineText } from 'theme/headings';
import { Button } from '@hotelplan/components.common.buttons';
import { useExactSearchContext } from './ExactSearchContext';

const ExactSearchSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const ExactSearchOptions = styled.div(({ theme: { colors, space } }) => ({
  display: 'flex',
  background: colors.lightGreySecond,
  border: `1px solid ${colors.greenLight}`,
  borderRadius: '8px',
  padding: '5px',
  marginLeft: space[4],
  gap: '4px',
}));

const OptionButton = styled(Button).attrs({
  variant: 'iconBtn',
})<{ bg?: string; isCloseIcon?: boolean }>(
  ({ theme: { space, colors, icons }, bg, isCloseIcon }) => ({
    background: colors[bg] || 'transparent',
    borderRadius: '5px',
    padding: space[2],
    marginRight: isCloseIcon ? space[2] : null,
    '.icon': icons.md,
    '&:hover,&:focus,&:active': {
      backgroundColor: colors[bg] || 'transparent',
      borderColor: 'none',
      svg: {
        fill: 'inherit',
      },
    },
  })
);

interface IIconOptions {
  checkmarkIcon: string;
  checkmarkBg: string;
  closeIcon: string;
  closeBg: string;
}

const iconOptions = {
  checked: {
    checkmarkIcon: `checkmark-white`,
    checkmarkBg: 'redWeb',
    closeIcon: `close-orange`,
    closeBg: 'transparent',
  },
  unChecked: {
    checkmarkIcon: `checkmark-orange`,
    checkmarkBg: 'transparent',
    closeIcon: `close-white`,
    closeBg: 'redWeb',
  },
};

function checkIcon(isExactSearch: boolean): IIconOptions {
  if (isExactSearch) {
    return iconOptions.checked;
  }

  return iconOptions.unChecked;
}

export default function ExactSearch(): ReactElement {
  const [t] = useTranslation('search');
  const exactSearchState = useExactSearchContext();
  const [isExactSearch, setExactSearch] = exactSearchState || [];

  const { checkmarkIcon, checkmarkBg, closeIcon, closeBg } = checkIcon(
    isExactSearch
  );

  return (
    <ExactSearchSection className="exact-search">
      <FlaglineText>{t('search:exact.search.title')}</FlaglineText>
      <ExactSearchOptions>
        <OptionButton
          bg={closeBg}
          icon={{ name: closeIcon }}
          onClick={function () {
            setExactSearch(false);
          }}
          isCloseIcon
        />
        <OptionButton
          bg={checkmarkBg}
          icon={{ name: checkmarkIcon }}
          onClick={function () {
            setExactSearch(true);
          }}
        />
      </ExactSearchOptions>
    </ExactSearchSection>
  );
}
