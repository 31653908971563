import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { PageFragmentDoc } from 'graphql/fragments/Page.generated';
import { AgencyFullTextSearchItemFragmentDoc } from 'graphql/agency/BaseAgency.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { DisrupterFragmentDoc } from 'graphql/fragments/Disrupter.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFulltextSearchQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      geo: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      themes: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      roundtrip: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      adventuretravel: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          googleStaticMapImage?: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          } | null;
          productInfoB2C?: {
            __typename?: 'SrlProductInfoB2C';
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      agenciesWithDetails: {
        __typename?: 'AgencySrlFullTextSearchResult';
        title: string;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
        items: Array<{
          __typename?: 'AgencyFullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          opened?: boolean | null;
          bookmarked: boolean;
          mainText: string;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          addressDetails?: {
            __typename?: 'AddressDetails';
            city?: string | null;
            country?: string | null;
            region?: string | null;
            street?: string | null;
            zip?: string | null;
          } | null;
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
      };
      extraHotels: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          productInfoB2C?: {
            __typename?: 'SrlProductInfoB2C';
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      regularHotels: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          productInfoB2C?: {
            __typename?: 'SrlProductInfoB2C';
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      blogArticles: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
      other: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
    staticContent: { __typename?: 'SrlStaticContent'; zeroResultText: string };
  };
};

export type AgencyWithDetailsFragment = {
  __typename?: 'AgencySrlFullTextSearchResult';
  title: string;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
  items: Array<{
    __typename?: 'AgencyFullTextSearchItem';
    name: string;
    highlightedText?: string | null;
    opened?: boolean | null;
    bookmarked: boolean;
    mainText: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    addressDetails?: {
      __typename?: 'AddressDetails';
      city?: string | null;
      country?: string | null;
      region?: string | null;
      street?: string | null;
      zip?: string | null;
    } | null;
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
};

export type GetFulltextSearchGeoQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchGeoQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      geo: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchThemesQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchThemesQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      themes: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchAdventureTravelProductsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchAdventureTravelProductsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      adventuretravel: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          googleStaticMapImage?: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          } | null;
          productInfoB2C?: {
            __typename?: 'SrlProductInfoB2C';
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchRoundTripProductsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchRoundTripProductsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      roundtrip: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchExtraHotelsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchExtraHotelsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      extraHotels: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          productInfoB2C?: {
            __typename?: 'SrlProductInfoB2C';
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchRegularHotelsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchRegularHotelsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      regularHotels: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          productInfoB2C?: {
            __typename?: 'SrlProductInfoB2C';
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          productInfoB2B?: {
            __typename?: 'SrlProductInfoB2B';
            duration: string;
            priceFrom: string;
            route: string;
            lead: string;
            typeFlags?: Array<string> | null;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchAgenciesQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchAgenciesQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      agenciesWithDetails: {
        __typename?: 'AgencySrlFullTextSearchResult';
        title: string;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
        items: Array<{
          __typename?: 'AgencyFullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          opened?: boolean | null;
          bookmarked: boolean;
          mainText: string;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          addressDetails?: {
            __typename?: 'AddressDetails';
            city?: string | null;
            country?: string | null;
            region?: string | null;
            street?: string | null;
            zip?: string | null;
          } | null;
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
      };
    };
  };
};

export type GetFulltextSearchBlogArticlesQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchBlogArticlesQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      blogArticles: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type GetFulltextSearchOtherQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetFulltextSearchOtherQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      other: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          highlightedText?: string | null;
          debugInfo?: string | null;
          breadcrumbs?: Array<string> | null;
          bookmarked: boolean;
          mainText: string;
          image: {
            __typename?: 'Image';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          };
          disrupters?: Array<{
            __typename?: 'DisrupterObject';
            text: string;
            fontColor?: string | null;
            backgroundColor?: string | null;
          }> | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export type FullSearchResultFragment = {
  __typename?: 'SrlFullTextSearchResult';
  title: string;
  items: Array<{
    __typename?: 'FullTextSearchItem';
    name: string;
    highlightedText?: string | null;
    debugInfo?: string | null;
    breadcrumbs?: Array<string> | null;
    bookmarked: boolean;
    mainText: string;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
  }>;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
};

export type FullSearchResultWithProductFragment = {
  __typename?: 'SrlFullTextSearchResult';
  title: string;
  items: Array<{
    __typename?: 'FullTextSearchItem';
    name: string;
    highlightedText?: string | null;
    debugInfo?: string | null;
    breadcrumbs?: Array<string> | null;
    bookmarked: boolean;
    mainText: string;
    productInfoB2C?: {
      __typename?: 'SrlProductInfoB2C';
      lead: string;
      typeFlags?: Array<string> | null;
    } | null;
    productInfoB2B?: {
      __typename?: 'SrlProductInfoB2B';
      duration: string;
      priceFrom: string;
      route: string;
      lead: string;
      typeFlags?: Array<string> | null;
    } | null;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
  }>;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
};

export type FullSearchResultWithProductB2BFragment = {
  __typename?: 'SrlFullTextSearchResult';
  title: string;
  items: Array<{
    __typename?: 'FullTextSearchItem';
    name: string;
    highlightedText?: string | null;
    debugInfo?: string | null;
    breadcrumbs?: Array<string> | null;
    bookmarked: boolean;
    mainText: string;
    productInfoB2B?: {
      __typename?: 'SrlProductInfoB2B';
      duration: string;
      priceFrom: string;
      route: string;
      lead: string;
      typeFlags?: Array<string> | null;
    } | null;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
  }>;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
};

export type FullSearchResultWithStaticMapFragment = {
  __typename?: 'SrlFullTextSearchResult';
  title: string;
  items: Array<{
    __typename?: 'FullTextSearchItem';
    name: string;
    highlightedText?: string | null;
    debugInfo?: string | null;
    breadcrumbs?: Array<string> | null;
    bookmarked: boolean;
    mainText: string;
    googleStaticMapImage?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
    productInfoB2C?: {
      __typename?: 'SrlProductInfoB2C';
      lead: string;
      typeFlags?: Array<string> | null;
    } | null;
    productInfoB2B?: {
      __typename?: 'SrlProductInfoB2B';
      duration: string;
      priceFrom: string;
      route: string;
      lead: string;
      typeFlags?: Array<string> | null;
    } | null;
    image: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
    disrupters?: Array<{
      __typename?: 'DisrupterObject';
      text: string;
      fontColor?: string | null;
      backgroundColor?: string | null;
    }> | null;
  }>;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
};

export type FullSearchItemWithProductFragment = {
  __typename?: 'FullTextSearchItem';
  name: string;
  highlightedText?: string | null;
  debugInfo?: string | null;
  breadcrumbs?: Array<string> | null;
  bookmarked: boolean;
  mainText: string;
  productInfoB2C?: {
    __typename?: 'SrlProductInfoB2C';
    lead: string;
    typeFlags?: Array<string> | null;
  } | null;
  productInfoB2B?: {
    __typename?: 'SrlProductInfoB2B';
    duration: string;
    priceFrom: string;
    route: string;
    lead: string;
    typeFlags?: Array<string> | null;
  } | null;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  disrupters?: Array<{
    __typename?: 'DisrupterObject';
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
  }> | null;
};

export type FullSearchItemWithProductB2BFragment = {
  __typename?: 'FullTextSearchItem';
  name: string;
  highlightedText?: string | null;
  debugInfo?: string | null;
  breadcrumbs?: Array<string> | null;
  bookmarked: boolean;
  mainText: string;
  productInfoB2B?: {
    __typename?: 'SrlProductInfoB2B';
    duration: string;
    priceFrom: string;
    route: string;
    lead: string;
    typeFlags?: Array<string> | null;
  } | null;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  disrupters?: Array<{
    __typename?: 'DisrupterObject';
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
  }> | null;
};

export type FullSearchItemWithStaticMapFragment = {
  __typename?: 'FullTextSearchItem';
  name: string;
  highlightedText?: string | null;
  debugInfo?: string | null;
  breadcrumbs?: Array<string> | null;
  bookmarked: boolean;
  mainText: string;
  googleStaticMapImage?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
  productInfoB2C?: {
    __typename?: 'SrlProductInfoB2C';
    lead: string;
    typeFlags?: Array<string> | null;
  } | null;
  productInfoB2B?: {
    __typename?: 'SrlProductInfoB2B';
    duration: string;
    priceFrom: string;
    route: string;
    lead: string;
    typeFlags?: Array<string> | null;
  } | null;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  disrupters?: Array<{
    __typename?: 'DisrupterObject';
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
  }> | null;
};

export type ProductInfoB2BFragment = {
  __typename?: 'SrlProductInfoB2B';
  duration: string;
  priceFrom: string;
  route: string;
  lead: string;
  typeFlags?: Array<string> | null;
};

export type ProductInfoB2CFragment = {
  __typename?: 'SrlProductInfoB2C';
  lead: string;
  typeFlags?: Array<string> | null;
};

export type FullSearchItemFragment = {
  __typename?: 'FullTextSearchItem';
  name: string;
  highlightedText?: string | null;
  debugInfo?: string | null;
  breadcrumbs?: Array<string> | null;
  bookmarked: boolean;
  mainText: string;
  image: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
  disrupters?: Array<{
    __typename?: 'DisrupterObject';
    text: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
  }> | null;
};

export const AgencyWithDetailsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'agencyWithDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AgencySrlFullTextSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'agencyFullTextSearchItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PageFragmentDoc.definitions,
    ...AgencyFullTextSearchItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FullSearchItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FullTextSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '380' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '274' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'disrupter' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'breadcrumbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookmarked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...DisrupterFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FullSearchResultFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlFullTextSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fullSearchItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemFragmentDoc.definitions,
    ...PageFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ProductInfoB2BFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productInfoB2B' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlProductInfoB2B' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'route' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeFlags' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const FullSearchItemWithProductB2BFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchItemWithProductB2B' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FullTextSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fullSearchItem' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productInfoB2B' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productInfoB2B' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemFragmentDoc.definitions,
    ...ProductInfoB2BFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ProductInfoB2CFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productInfoB2C' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlProductInfoB2C' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeFlags' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const FullSearchItemWithProductFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchItemWithProduct' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FullTextSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fullSearchItemWithProductB2B' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productInfoB2C' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productInfoB2C' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemWithProductB2BFragmentDoc.definitions,
    ...ProductInfoB2CFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FullSearchResultWithProductFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchResultWithProduct' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlFullTextSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fullSearchItemWithProduct' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemWithProductFragmentDoc.definitions,
    ...PageFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FullSearchResultWithProductB2BFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchResultWithProductB2B' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlFullTextSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fullSearchItemWithProductB2B' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemWithProductB2BFragmentDoc.definitions,
    ...PageFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FullSearchItemWithStaticMapFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchItemWithStaticMap' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FullTextSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fullSearchItemWithProduct' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'googleStaticMapImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '380' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '274' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemWithProductFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const FullSearchResultWithStaticMapFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fullSearchResultWithStaticMap' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlFullTextSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fullSearchItemWithStaticMap' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchItemWithStaticMapFragmentDoc.definitions,
    ...PageFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetFulltextSearchDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geo' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'themes' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roundtrip' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithProductB2B',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adventuretravel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithStaticMap',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agenciesWithDetails' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'agencyWithDetails',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extraHotels' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithProduct',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'regularHotels' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithProduct',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blogArticles' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'other' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staticContent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zeroResultText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultFragmentDoc.definitions,
    ...FullSearchResultWithProductB2BFragmentDoc.definitions,
    ...FullSearchResultWithStaticMapFragmentDoc.definitions,
    ...AgencyWithDetailsFragmentDoc.definitions,
    ...FullSearchResultWithProductFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchQuery,
    GetFulltextSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchQuery,
    GetFulltextSearchQueryVariables
  >(GetFulltextSearchDocument, options);
}
export function useGetFulltextSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchQuery,
    GetFulltextSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchQuery,
    GetFulltextSearchQueryVariables
  >(GetFulltextSearchDocument, options);
}
export type GetFulltextSearchQueryHookResult = ReturnType<
  typeof useGetFulltextSearchQuery
>;
export type GetFulltextSearchLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchLazyQuery
>;
export type GetFulltextSearchQueryResult = Apollo.QueryResult<
  GetFulltextSearchQuery,
  GetFulltextSearchQueryVariables
>;
export const GetFulltextSearchGeoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchGeo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geo' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchGeoQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchGeoQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchGeoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchGeoQuery,
    GetFulltextSearchGeoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchGeoQuery,
    GetFulltextSearchGeoQueryVariables
  >(GetFulltextSearchGeoDocument, options);
}
export function useGetFulltextSearchGeoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchGeoQuery,
    GetFulltextSearchGeoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchGeoQuery,
    GetFulltextSearchGeoQueryVariables
  >(GetFulltextSearchGeoDocument, options);
}
export type GetFulltextSearchGeoQueryHookResult = ReturnType<
  typeof useGetFulltextSearchGeoQuery
>;
export type GetFulltextSearchGeoLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchGeoLazyQuery
>;
export type GetFulltextSearchGeoQueryResult = Apollo.QueryResult<
  GetFulltextSearchGeoQuery,
  GetFulltextSearchGeoQueryVariables
>;
export const GetFulltextSearchThemesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchThemes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'themes' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchThemesQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchThemesQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchThemesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchThemesQuery,
    GetFulltextSearchThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchThemesQuery,
    GetFulltextSearchThemesQueryVariables
  >(GetFulltextSearchThemesDocument, options);
}
export function useGetFulltextSearchThemesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchThemesQuery,
    GetFulltextSearchThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchThemesQuery,
    GetFulltextSearchThemesQueryVariables
  >(GetFulltextSearchThemesDocument, options);
}
export type GetFulltextSearchThemesQueryHookResult = ReturnType<
  typeof useGetFulltextSearchThemesQuery
>;
export type GetFulltextSearchThemesLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchThemesLazyQuery
>;
export type GetFulltextSearchThemesQueryResult = Apollo.QueryResult<
  GetFulltextSearchThemesQuery,
  GetFulltextSearchThemesQueryVariables
>;
export const GetFulltextSearchAdventureTravelProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchAdventureTravelProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adventuretravel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithStaticMap',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultWithStaticMapFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchAdventureTravelProductsQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchAdventureTravelProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchAdventureTravelProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchAdventureTravelProductsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchAdventureTravelProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchAdventureTravelProductsQuery,
    GetFulltextSearchAdventureTravelProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchAdventureTravelProductsQuery,
    GetFulltextSearchAdventureTravelProductsQueryVariables
  >(GetFulltextSearchAdventureTravelProductsDocument, options);
}
export function useGetFulltextSearchAdventureTravelProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchAdventureTravelProductsQuery,
    GetFulltextSearchAdventureTravelProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchAdventureTravelProductsQuery,
    GetFulltextSearchAdventureTravelProductsQueryVariables
  >(GetFulltextSearchAdventureTravelProductsDocument, options);
}
export type GetFulltextSearchAdventureTravelProductsQueryHookResult = ReturnType<
  typeof useGetFulltextSearchAdventureTravelProductsQuery
>;
export type GetFulltextSearchAdventureTravelProductsLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchAdventureTravelProductsLazyQuery
>;
export type GetFulltextSearchAdventureTravelProductsQueryResult = Apollo.QueryResult<
  GetFulltextSearchAdventureTravelProductsQuery,
  GetFulltextSearchAdventureTravelProductsQueryVariables
>;
export const GetFulltextSearchRoundTripProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchRoundTripProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roundtrip' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithProductB2B',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultWithProductB2BFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchRoundTripProductsQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchRoundTripProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchRoundTripProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchRoundTripProductsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchRoundTripProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchRoundTripProductsQuery,
    GetFulltextSearchRoundTripProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchRoundTripProductsQuery,
    GetFulltextSearchRoundTripProductsQueryVariables
  >(GetFulltextSearchRoundTripProductsDocument, options);
}
export function useGetFulltextSearchRoundTripProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchRoundTripProductsQuery,
    GetFulltextSearchRoundTripProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchRoundTripProductsQuery,
    GetFulltextSearchRoundTripProductsQueryVariables
  >(GetFulltextSearchRoundTripProductsDocument, options);
}
export type GetFulltextSearchRoundTripProductsQueryHookResult = ReturnType<
  typeof useGetFulltextSearchRoundTripProductsQuery
>;
export type GetFulltextSearchRoundTripProductsLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchRoundTripProductsLazyQuery
>;
export type GetFulltextSearchRoundTripProductsQueryResult = Apollo.QueryResult<
  GetFulltextSearchRoundTripProductsQuery,
  GetFulltextSearchRoundTripProductsQueryVariables
>;
export const GetFulltextSearchExtraHotelsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchExtraHotels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extraHotels' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithProduct',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultWithProductFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchExtraHotelsQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchExtraHotelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchExtraHotelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchExtraHotelsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchExtraHotelsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchExtraHotelsQuery,
    GetFulltextSearchExtraHotelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchExtraHotelsQuery,
    GetFulltextSearchExtraHotelsQueryVariables
  >(GetFulltextSearchExtraHotelsDocument, options);
}
export function useGetFulltextSearchExtraHotelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchExtraHotelsQuery,
    GetFulltextSearchExtraHotelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchExtraHotelsQuery,
    GetFulltextSearchExtraHotelsQueryVariables
  >(GetFulltextSearchExtraHotelsDocument, options);
}
export type GetFulltextSearchExtraHotelsQueryHookResult = ReturnType<
  typeof useGetFulltextSearchExtraHotelsQuery
>;
export type GetFulltextSearchExtraHotelsLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchExtraHotelsLazyQuery
>;
export type GetFulltextSearchExtraHotelsQueryResult = Apollo.QueryResult<
  GetFulltextSearchExtraHotelsQuery,
  GetFulltextSearchExtraHotelsQueryVariables
>;
export const GetFulltextSearchRegularHotelsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchRegularHotels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'regularHotels' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fullSearchResultWithProduct',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultWithProductFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchRegularHotelsQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchRegularHotelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchRegularHotelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchRegularHotelsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchRegularHotelsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchRegularHotelsQuery,
    GetFulltextSearchRegularHotelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchRegularHotelsQuery,
    GetFulltextSearchRegularHotelsQueryVariables
  >(GetFulltextSearchRegularHotelsDocument, options);
}
export function useGetFulltextSearchRegularHotelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchRegularHotelsQuery,
    GetFulltextSearchRegularHotelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchRegularHotelsQuery,
    GetFulltextSearchRegularHotelsQueryVariables
  >(GetFulltextSearchRegularHotelsDocument, options);
}
export type GetFulltextSearchRegularHotelsQueryHookResult = ReturnType<
  typeof useGetFulltextSearchRegularHotelsQuery
>;
export type GetFulltextSearchRegularHotelsLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchRegularHotelsLazyQuery
>;
export type GetFulltextSearchRegularHotelsQueryResult = Apollo.QueryResult<
  GetFulltextSearchRegularHotelsQuery,
  GetFulltextSearchRegularHotelsQueryVariables
>;
export const GetFulltextSearchAgenciesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchAgencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agenciesWithDetails' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'agencyWithDetails',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AgencyWithDetailsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchAgenciesQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchAgenciesQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchAgenciesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchAgenciesQuery,
    GetFulltextSearchAgenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchAgenciesQuery,
    GetFulltextSearchAgenciesQueryVariables
  >(GetFulltextSearchAgenciesDocument, options);
}
export function useGetFulltextSearchAgenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchAgenciesQuery,
    GetFulltextSearchAgenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchAgenciesQuery,
    GetFulltextSearchAgenciesQueryVariables
  >(GetFulltextSearchAgenciesDocument, options);
}
export type GetFulltextSearchAgenciesQueryHookResult = ReturnType<
  typeof useGetFulltextSearchAgenciesQuery
>;
export type GetFulltextSearchAgenciesLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchAgenciesLazyQuery
>;
export type GetFulltextSearchAgenciesQueryResult = Apollo.QueryResult<
  GetFulltextSearchAgenciesQuery,
  GetFulltextSearchAgenciesQueryVariables
>;
export const GetFulltextSearchBlogArticlesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchBlogArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blogArticles' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchBlogArticlesQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchBlogArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchBlogArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchBlogArticlesQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchBlogArticlesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchBlogArticlesQuery,
    GetFulltextSearchBlogArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchBlogArticlesQuery,
    GetFulltextSearchBlogArticlesQueryVariables
  >(GetFulltextSearchBlogArticlesDocument, options);
}
export function useGetFulltextSearchBlogArticlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchBlogArticlesQuery,
    GetFulltextSearchBlogArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchBlogArticlesQuery,
    GetFulltextSearchBlogArticlesQueryVariables
  >(GetFulltextSearchBlogArticlesDocument, options);
}
export type GetFulltextSearchBlogArticlesQueryHookResult = ReturnType<
  typeof useGetFulltextSearchBlogArticlesQuery
>;
export type GetFulltextSearchBlogArticlesLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchBlogArticlesLazyQuery
>;
export type GetFulltextSearchBlogArticlesQueryResult = Apollo.QueryResult<
  GetFulltextSearchBlogArticlesQuery,
  GetFulltextSearchBlogArticlesQueryVariables
>;
export const GetFulltextSearchOtherDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFulltextSearchOther' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'other' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fullSearchResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FullSearchResultFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFulltextSearchOtherQuery__
 *
 * To run a query within a React component, call `useGetFulltextSearchOtherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulltextSearchOtherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulltextSearchOtherQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetFulltextSearchOtherQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFulltextSearchOtherQuery,
    GetFulltextSearchOtherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFulltextSearchOtherQuery,
    GetFulltextSearchOtherQueryVariables
  >(GetFulltextSearchOtherDocument, options);
}
export function useGetFulltextSearchOtherLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFulltextSearchOtherQuery,
    GetFulltextSearchOtherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFulltextSearchOtherQuery,
    GetFulltextSearchOtherQueryVariables
  >(GetFulltextSearchOtherDocument, options);
}
export type GetFulltextSearchOtherQueryHookResult = ReturnType<
  typeof useGetFulltextSearchOtherQuery
>;
export type GetFulltextSearchOtherLazyQueryHookResult = ReturnType<
  typeof useGetFulltextSearchOtherLazyQuery
>;
export type GetFulltextSearchOtherQueryResult = Apollo.QueryResult<
  GetFulltextSearchOtherQuery,
  GetFulltextSearchOtherQueryVariables
>;
