import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { IMenuLink } from '@hotelplan/components.common.menu';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const IPAD_MINI_SCREEN = `@media screen and (min-width: 768px) and (max-width: 1024px)`;
const IPAD_MINI_LANDSCAPE_SCREEN = `@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`;

const FooterMenuWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    [media.desktop]: {
      flexWrap: 'nowrap',
    },
    [IPAD_MINI_LANDSCAPE_SCREEN]: {
      mb: -4,
    },
  })
);

const FooterMenuColumn = styled.ul(({ theme: { media } }) =>
  sx2CssThemeFn({
    padding: 0,
    flexBasis: '100%',
    borderBottom: 'none',
    borderColor: 'borderColor',
    [IPAD_MINI_SCREEN]: {
      flexBasis: '47%',
      mb: 4,
      pr: 3,
    },
    [media.desktop]: {
      flexBasis: '260px',
      marginRight: '40px',
    },
    marginBottom: [4, 2],
    [IPAD_MINI_LANDSCAPE_SCREEN]: {
      mb: 3,
    },
  })
);

const FooterMenuMainItem = styled.li.attrs({
  ['data-id']: `footer-main-link`,
})<{ fullWidth?: boolean }>(
  sx2CssThemeFn({
    margin: [`0 0 10px`, `0 0 20px 0`],
    fontWeight: '700',
    fontSize: '15px',
    textTransform: `uppercase`,
    letterSpacing: '1px',
  }),
  ({ fullWidth }) =>
    fullWidth
      ? sx2CssThemeFn({
          width: ['100%', 'auto'],
          a: {
            width: ['100%', 'auto'],
          },
        })
      : {}
);

const FooterMenuLinksWrapper = styled.ul(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: ['100%', 'auto'],
  })
);

const FooterMenuSubItem = styled.li.attrs({
  ['data-id']: `footer-sub-link`,
})(
  sx2CssThemeFn({
    fontSize: [0, 1],
    marginBottom: 2,
  })
);

const FooterMenu: React.FC<{ links: IMenuLink[] }> = ({ links }) => {
  return (
    <FooterMenuWrapper>
      {links.map((link, index) => {
        return (
          <FooterMenuColumn key={`footer-menu-column-${index}`}>
            <FooterMenuMainItem fullWidth={!link.subLinks}>
              <RouterLink {...link.link} passHref>
                <Link target={link.target}>{link.label}</Link>
              </RouterLink>
            </FooterMenuMainItem>

            <FooterMenuLinksWrapper>
              {link.subLinks?.map((subLink, i) => {
                return (
                  <FooterMenuSubItem key={`footer-menu-sub-item-${i}`}>
                    <RouterLink {...subLink.link} passHref>
                      <Link target={subLink.target}>{subLink.label}</Link>
                    </RouterLink>
                  </FooterMenuSubItem>
                );
              })}
            </FooterMenuLinksWrapper>
          </FooterMenuColumn>
        );
      })}
    </FooterMenuWrapper>
  );
};

export default FooterMenu;
