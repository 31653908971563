export enum FeatureList {
  ShakingRequestButton = 'shaking-request-button',
  DisableNearbyRoutes = 'disable-nearby-routes',
  AutoSuggest = 'th-b2c-autosuggest',
  JotformAlternativeUrl = 'jotform-alternative-url',
  GEO_MAP_NEW_ITERATION = 'th-geo-map-new-iteration',
  MULTIPLE_BOOKMARKS = 'th-multiple-bookmarks',
  PAGINATION_VARIANT = 'th-pagination-variant',
  Co2FootprintEnabled = 'th-co2-footprint',
  cmsSearchThSorting = 'cms-search-th-sorting',
  cmsSearchThSortingB2b = 'cms-search-th-sorting-b2b',
  geoProductRecommenderView = 'th-geo-product-recommender-view',
  shareBookmarks = 'th-share-bookmarks',
  SplitPageviewEvent = 'split-pageview-event',
}
