import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { AddressDetailsFragmentDoc } from 'graphql/fragments/AddressDetails.generated';
import { CoordinatesFragmentDoc } from 'graphql/fragments/Coordinates.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetAgencyBoxDataQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetAgencyBoxDataQuery = {
  __typename?: 'Query';
  agency: {
    __typename?: 'Agency';
    title: string;
    addressDetails: {
      __typename?: 'AddressDetails';
      city?: string | null;
      country?: string | null;
      region?: string | null;
      street?: string | null;
      zip?: string | null;
    };
    coordinates?: {
      __typename?: 'GeoCoordinates';
      latitude: number;
      longitude: number;
    } | null;
    url: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
  };
};

export type AgencyBoxFragment = {
  __typename?: 'Agency';
  title: string;
  addressDetails: {
    __typename?: 'AddressDetails';
    city?: string | null;
    country?: string | null;
    region?: string | null;
    street?: string | null;
    zip?: string | null;
  };
  coordinates?: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  } | null;
  url: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
};

export const AgencyBoxFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'agencyBox' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'addressDetails' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'url' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AddressDetailsFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetAgencyBoxDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgencyBoxData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'agencyBox' },
                },
              ],
            },
          },
        ],
      },
    },
    ...AgencyBoxFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetAgencyBoxDataQuery__
 *
 * To run a query within a React component, call `useGetAgencyBoxDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyBoxDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyBoxDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetAgencyBoxDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAgencyBoxDataQuery,
    GetAgencyBoxDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAgencyBoxDataQuery,
    GetAgencyBoxDataQueryVariables
  >(GetAgencyBoxDataDocument, options);
}
export function useGetAgencyBoxDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAgencyBoxDataQuery,
    GetAgencyBoxDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAgencyBoxDataQuery,
    GetAgencyBoxDataQueryVariables
  >(GetAgencyBoxDataDocument, options);
}
export type GetAgencyBoxDataQueryHookResult = ReturnType<
  typeof useGetAgencyBoxDataQuery
>;
export type GetAgencyBoxDataLazyQueryHookResult = ReturnType<
  typeof useGetAgencyBoxDataLazyQuery
>;
export type GetAgencyBoxDataQueryResult = Apollo.QueryResult<
  GetAgencyBoxDataQuery,
  GetAgencyBoxDataQueryVariables
>;
