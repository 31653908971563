import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSearchOverlayQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetSearchOverlayQuery = {
  __typename?: 'Query';
  searchOverlay: {
    __typename?: 'SearchOverlay';
    keywords: Array<string>;
    mostSearched: Array<string>;
    searchTrends: Array<string>;
  };
};

export type SearchOverlayFragment = {
  __typename?: 'SearchOverlay';
  keywords: Array<string>;
  mostSearched: Array<string>;
  searchTrends: Array<string>;
};

export const SearchOverlayFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'searchOverlay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchOverlay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mostSearched' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchTrends' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const GetSearchOverlayDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSearchOverlay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOverlay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchQuery' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchQuery' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'searchOverlay' },
                },
              ],
            },
          },
        ],
      },
    },
    ...SearchOverlayFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetSearchOverlayQuery__
 *
 * To run a query within a React component, call `useGetSearchOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchOverlayQuery({
 *   variables: {
 *      context: // value for 'context'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetSearchOverlayQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSearchOverlayQuery,
    GetSearchOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSearchOverlayQuery,
    GetSearchOverlayQueryVariables
  >(GetSearchOverlayDocument, options);
}
export function useGetSearchOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSearchOverlayQuery,
    GetSearchOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSearchOverlayQuery,
    GetSearchOverlayQueryVariables
  >(GetSearchOverlayDocument, options);
}
export type GetSearchOverlayQueryHookResult = ReturnType<
  typeof useGetSearchOverlayQuery
>;
export type GetSearchOverlayLazyQueryHookResult = ReturnType<
  typeof useGetSearchOverlayLazyQuery
>;
export type GetSearchOverlayQueryResult = Apollo.QueryResult<
  GetSearchOverlayQuery,
  GetSearchOverlayQueryVariables
>;
