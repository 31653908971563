import { debounce } from 'lodash';
import noop from 'lodash/noop';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useGetBookmarkGroupsQuery } from 'graphql/bookmark/GetBookmarkGroups.generated';
import {
  BookmarkContextActionType,
  IBookmarkContext,
  IBookmarkContextState,
  IBookmarkGroupState,
  TAddBookmarkInput,
  TAddBookmarkToNewGroupInput,
  TBookmarkInput,
} from './Bookmark.types';
import {
  bookmarkContextReducer,
  getBookmarkItemsToUpdate,
} from './BookmarkContext.utils';
import BookmarkGroupItemsQueryItem from './BookmarkGroupItemsQueryItem';
import useAddBookmarkItemToNewGroup from './useAddBookmarkItemToNewGroup';
import { useAddBookmarkItemWithoutGroup } from './useAddBookmarkItemWithoutGroup';
import { useUpdateBookmarks } from './useUpdateBookmarks';

const initialBookmarkContextState: IBookmarkContextState = {
  loading: false,
  bookmarkGroups: [],
  count: 0,
  initialized: false,
};

const defaultBookmarkContext = {
  ...initialBookmarkContextState,
  addBookmark: noop,
  addBookmarkToNewGroup: () => {
    return new Promise<TBookmarkInput | undefined>(() => {
      return undefined;
    });
  },
  removeBookmark: noop,
};

export const BookmarkContext = createContext<IBookmarkContext>(
  defaultBookmarkContext
);

const DEFAULT_BOOKMARK_UPDATE_DEBOUNCE_TIME = 1000; //ms

export function BookmarkContextProvider(
  props: PropsWithChildren<{ bookmarkUpdateDebounceTime?: number }>
): ReturnType<FC> {
  const initialized = useRef(false);
  const [state, dispatch] = useReducer(
    bookmarkContextReducer,
    initialBookmarkContextState
  );
  const { data, loading: groupsLoading } = useGetBookmarkGroupsQuery({
    ssr: false,
  });
  const {
    addBookmarkItem,
    loading: addBookmarkItemLoading,
  } = useAddBookmarkItemWithoutGroup();
  const { addBookmarkItemToNewGroup } = useAddBookmarkItemToNewGroup();
  const [debouncedBookmarkGroups, setDebouncedBookmarkGroups] = useState<
    IBookmarkGroupState[] | undefined
  >(undefined);
  const prevDebouncedBookmarkGroups = useRef<IBookmarkGroupState[]>();
  const { updateBookmarks } = useUpdateBookmarks();

  async function doUpdateBookmarks(
    prevDebouncedValue: IBookmarkGroupState[],
    debouncedValue: IBookmarkGroupState[]
  ) {
    const toUpdate = getBookmarkItemsToUpdate(
      prevDebouncedValue,
      debouncedValue
    );

    await updateBookmarks(toUpdate.toAdd, toUpdate.toDelete);
  }

  const setDebouncedBookmarkGroupsFunction = debounce(() => {
    setDebouncedBookmarkGroups(state.bookmarkGroups);
  }, props.bookmarkUpdateDebounceTime || DEFAULT_BOOKMARK_UPDATE_DEBOUNCE_TIME);

  useEffect(() => {
    if (!groupsLoading && !state.bookmarkGroups.find(i => i.loading)) {
      setDebouncedBookmarkGroupsFunction();
    }

    return () => {
      setDebouncedBookmarkGroupsFunction.cancel();
    };
  }, [state.bookmarkGroups, groupsLoading]);

  useEffect(() => {
    if (
      prevDebouncedBookmarkGroups.current != undefined &&
      debouncedBookmarkGroups != undefined
    ) {
      doUpdateBookmarks(
        prevDebouncedBookmarkGroups.current,
        debouncedBookmarkGroups
      );
    }

    prevDebouncedBookmarkGroups.current = debouncedBookmarkGroups;
  }, [debouncedBookmarkGroups]);

  const handlers = useMemo(() => {
    return {
      addBookmark: (bookmark: TAddBookmarkInput) => {
        if (bookmark.groupId) {
          dispatch({
            type: BookmarkContextActionType.ADD_BOOKMARK_ITEM,
            payload: bookmark as TBookmarkInput,
          });
        } else if (state.bookmarkGroups.length) {
          dispatch({
            type: BookmarkContextActionType.ADD_BOOKMARK_ITEM,
            payload: {
              ...bookmark,
              groupId: state.bookmarkGroups[0].id,
            } as TBookmarkInput,
          });
        } else {
          addBookmarkItem(bookmark.pageType, bookmark.objectId);
        }
      },
      addBookmarkToNewGroup: (bookmark: TAddBookmarkToNewGroupInput) => {
        return addBookmarkItemToNewGroup(
          bookmark.pageType,
          bookmark.objectId,
          bookmark.groupName
        );
      },
      removeBookmark: (bookmark: TBookmarkInput) => {
        dispatch({
          type: BookmarkContextActionType.REMOVE_BOOKMARK_ITEM,
          payload: bookmark,
        });
      },
    };
  }, [state.bookmarkGroups.length]);

  const context: IBookmarkContext = useMemo(
    () => ({
      ...state,
      ...handlers,
    }),
    [handlers, state]
  );

  useEffect(() => {
    if (data?.bookmarkPage?.allBookmarkGroups?.bookmarkGroups?.length) {
      dispatch({
        type: BookmarkContextActionType.SET_BOOKMARK_GROUPS,
        payload: data.bookmarkPage.allBookmarkGroups.bookmarkGroups.map(g => ({
          id: g.groupId,
          name: g.name,
          image: g.groupImage,
          shareUrl: g.shareUrl,
        })),
      });

      setDebouncedBookmarkGroups(undefined);
    }
  }, [data]);

  useEffect(() => {
    dispatch({
      type: BookmarkContextActionType.SET_BOOKMARK_CONTEXT_LOADING,
      payload:
        groupsLoading ||
        addBookmarkItemLoading ||
        !!state.bookmarkGroups.find(g => g.loading),
    });
  }, [groupsLoading, addBookmarkItemLoading, state.bookmarkGroups]);

  useEffect(() => {
    if (!groupsLoading && !state.bookmarkGroups.find(group => group.loading)) {
      const count = state.bookmarkGroups.length
        ? state.bookmarkGroups.reduce((prevValue, currentValue) => {
            return prevValue + currentValue.items.length;
          }, 0)
        : 0;

      if (state.count !== count) {
        dispatch({
          type: BookmarkContextActionType.SET_BOOKMARK_COUNT,
          payload: count,
        });
      }

      if (!initialized.current && !state.loading) {
        initialized.current = true;
        dispatch({
          type: BookmarkContextActionType.INITIALIZE,
        });
      }
    }
  }, [state.bookmarkGroups, state.loading]);

  return (
    <BookmarkContext.Provider value={context}>
      {state.bookmarkGroups.map(g => {
        return (
          <BookmarkGroupItemsQueryItem
            key={g.uuid}
            groupId={g.id}
            dispatch={dispatch}
          />
        );
      })}
      {props.children}
    </BookmarkContext.Provider>
  );
}

export function useBookmarkContext(): IBookmarkContext {
  return useContext(BookmarkContext);
}
