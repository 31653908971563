import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
export type TextMenuEntryFragment = {
  __typename?: 'TextMenuItem';
  caption: string;
  link:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
        objectId?: string | null;
      };
};

export const TextMenuEntryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'textMenuEntry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMenuItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
