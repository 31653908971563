import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { HistoryUserResponseFragmentDoc } from 'graphql/fragments/HistoryUserResponse.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetLastViewedQueryVariables = Types.Exact<{
  page: Types.Scalars['Int'];
  context: Types.RequestContextInput;
}>;

export type GetLastViewedQuery = {
  __typename?: 'Query';
  history: {
    __typename?: 'HistoryContext';
    recentViewed: {
      __typename?: 'HistoryUserResponse';
      userRecords?: Array<{
        __typename?: 'HistoryUserRecord';
        title: string;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        };
      }> | null;
      page: {
        __typename?: 'Page';
        pageNumber: number;
        resultsPerPage?: number | null;
        resultsTotal?: number | null;
      };
    };
  };
};

export const GetLastViewedDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLastViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'history' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'recentViewed' },
                  name: { kind: 'Name', value: 'recentViewedWithPage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'pageNumber' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resultsPerPage' },
                            value: { kind: 'IntValue', value: '5' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'historyUserResponse' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HistoryUserResponseFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetLastViewedQuery__
 *
 * To run a query within a React component, call `useGetLastViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastViewedQuery({
 *   variables: {
 *      page: // value for 'page'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetLastViewedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLastViewedQuery,
    GetLastViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetLastViewedQuery,
    GetLastViewedQueryVariables
  >(GetLastViewedDocument, options);
}
export function useGetLastViewedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLastViewedQuery,
    GetLastViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetLastViewedQuery,
    GetLastViewedQueryVariables
  >(GetLastViewedDocument, options);
}
export type GetLastViewedQueryHookResult = ReturnType<
  typeof useGetLastViewedQuery
>;
export type GetLastViewedLazyQueryHookResult = ReturnType<
  typeof useGetLastViewedLazyQuery
>;
export type GetLastViewedQueryResult = Apollo.QueryResult<
  GetLastViewedQuery,
  GetLastViewedQueryVariables
>;
