import React, { useRef } from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { trackMenu } from '@hotelplan/libs.tracking';
import { MenuEventType } from '@hotelplan/libs.tracking/dist/trackingEvents/trackMenu';
import MobileNav from 'components/domain/mobile-bottom-menu/MobileNav';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

const ContactsSiteTool: React.FC = () => {
  const mobileNavButtonRef = useRef<HTMLButtonElement>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);

  const [isMobileNavShown, , closeMobileNav, toggleNavigation] = useToggleState(
    false
  );

  const handleCloseMobileNav = () => {
    closeMobileNav();
    trackMenu({
      type: MenuEventType.MENU_CLOSE,
    });
  };

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [mobileNavButtonRef, mobileNavRef],
    () => {
      if (!isMobileNavShown) return;
      handleCloseMobileNav();
    }
  );

  return (
    <>
      <SiteToolsButtonsWrapper
        className={`site-tool-button ${isMobileNavShown ? 'nav-shown' : ''}`}
      >
        <Button
          variant="headerBtn"
          icon={{ name: isMobileNavShown ? 'menu-active' : 'menu' }}
          ref={mobileNavButtonRef}
          onClick={() => {
            toggleNavigation();
            trackMenu({
              type: isMobileNavShown
                ? MenuEventType.MENU_CLOSE
                : MenuEventType.MENU_OPEN,
            });
          }}
        />
      </SiteToolsButtonsWrapper>
      {isMobileNavShown ? (
        <MobileNav dropDownRef={mobileNavRef} onClose={handleCloseMobileNav} />
      ) : null}
    </>
  );
};

export default ContactsSiteTool;
