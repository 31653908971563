import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BrandLogos } from '@hotelplan/components.common.brands-logos';
import {
  FooterCopyright,
  FooterSocialMedia,
  ReasonsOfConfidence,
} from '@hotelplan/components.common.footer';
import { Image } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { LinksList } from '@hotelplan/components.common.links-list';
import { IMenuLink } from '@hotelplan/components.common.menu';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { languageDefault } from '@hotelplan/libs.language';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import LanguageControl from 'components/domain/language-control/LanguageControl';
import { PageType } from 'graphql/types/types';
import FooterFeedbackBox from './FooterFeedbackBox';
import FooterMenu from './FooterMenu';
import { usePageFooter } from './usePageFooter';

const FooterWrapper = styled.footer(({ theme: { media } }) =>
  sx2CssThemeFn({
    '.footer-copyright': {
      fontSize: '10px',
      fontWeight: 'bold',
      paddingTop: 3,
      paddingBottom: [3, 5],
      color: 'black',
    },
    '.th-brands': {
      flexShrink: [null, 0],
      flexDirection: [null, 'column'],
      alignItems: [null, 'flex-start'],
      justifyContent: 'flex-start',
      padding: '0px',
      '.brand-link': {
        marginRight: [5, '0px'],
        padding: '0px',
        marginBottom: [null, 4],
        maxWidth: [null, '130px'],

        width: '100%',
        img: {
          maxHeight: 'fit-content',
          maxWidth: [null, '130px'],
          width: '100%',
        },
      },
      [media.tablet]: {
        paddingTop: 4,
      },
      [media.desktop]: {
        paddingTop: 3,
      },
    },
    'a:hover:not(.newsletter-link, .lang-link, .link)': {
      color: 'white',
      textDecoration: 'underline',
    },
  })
);

const FooterContentWrapper = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'darkGrey',
    color: 'white',
    position: 'relative',
  })
);

const FooterBody = styled(Container).attrs({
  ['data-id']: `footer-body`,
})(({ theme: { icons } }) =>
  sx2CssThemeFn({
    py: [5, '40px'],
    borderBottom: '1px solid #727771',
    '.th-social': {
      flexWrap: 'wrap',
      width: '100%',
      maxWidth: [null, '200px'],
      justifyContent: 'flex-start',
      border: 'none',
      marginBottom: '0px',
      '.social-media-title': {
        display: 'none',
      },
    },
    '.social-media-list': {
      justifyContent: 'flex-start',
      paddingBottom: [2, 3],
      paddingRight: '0px',
      paddingLeft: '0px',
    },
    '.social-media-list a': {
      marginRight: '14px',
      '&:last-child': {
        marginRight: 0,
      },
      marginTop: 0,
      marginBottom: [0, 3],
    },
    '.social-media-icon': {
      ...icons.xxlg,
    },
  })
);

const FooterMainContentWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '@media screen and (max-width: 896px) and (orientation: landscape)': {
      flexWrap: 'nowrap',
    },
    [media.tablet]: {
      flexWrap: 'nowrap',
    },
  })
);

const LinksListWrapper = styled(LinksList)(
  ({ theme: { space, media, fontSizes } }) => ({
    padding: `${space[4]} 0px`,
    border: 'none',
    marginBottom: '0px',
    columnGap: space[3],
    '.list-item': {
      flex: '0 0 auto',
      a: {
        fontSize: fontSizes[1],
      },
      fontSize: fontSizes[1],
    },
    [media.tablet]: {
      padding: `${space[4]} 0px`,
    },
  })
);

const FooterLogoWrapper = styled.div(({ theme: { space, media } }) => ({
  display: 'flex',
  marginBottom: space[3],
  width: '210px',
  height: '65px',
  [media.tablet]: {
    marginRight: '85px',
    flexShrink: 0,
  },
}));

const FooterLogo = styled(Link)({
  img: {
    width: '100%',
    height: '100%',
  },
});

const FooterBottom = styled(Container)(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    paddingTop: '40px',
    paddingBottom: 6,
    borderBottom: '1px solid #727771',
    justifyContent: 'space-between',
    [media.tablet]: {
      flexWrap: 'wrap',
    },
  })
);

const FooterLinks = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    [media.mobile]: {
      width: '100%',
    },
  })
);

const NewsletterLink = styled(Link)(
  ({ theme: { space, media, buttons, colors } }) => ({
    ...buttons.main,
    marginBottom: space[3],
    textAlign: 'center',
    backgroundColor: colors.redWeb,
    borderColor: colors.redWeb,
    [media.tablet]: {
      marginBottom: '40px',
    },
  })
);

const ReasonsOfConfidenceWrapper = styled(ReasonsOfConfidence)(
  sx2CssThemeFn({
    background: 'transparent',
    padding: '0px',
    marginTop: 3,
    marginRight: 3,
    flexBasis: 'auto',
    flexGrow: '1',
    ul: {
      display: 'grid',
      gridTemplateColumns: [null, `repeat(3, 256px)`],
      gap: [null, '0 44px'],
    },
    li: {
      fontSize: 1,
      letterSpacing: '0.5px',
      paddingLeft: '20px',
      lineHeight: 1.4,
      p: {
        marginBottom: 0,
      },
      'p:not(:last-child)': {
        marginBottom: 2,
      },
      '&:before': {
        content: "''",
        backgroundImage: 'url(/images/svg/chevron-right-solid.svg)',
        width: '10px',
        height: '10px',
        fontSize: '14px',
        top: '5px',
        backgroundSize: '7px',
      },
    },
    a: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  })
);

const Tagline = styled.span(({ theme: { space } }) => ({
  display: 'block',
  fontSize: '15px',
  textTransform: 'uppercase',
  paddingRight: space[3],
}));

const FooterFeedbackBoxWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: [`column-reverse`, 'row'],
    justifyContent: 'space-between',
    '.links-list': {
      marginLeft: 0,
    },
  })
);

const Footer: React.FC<{ menuLinks?: IMenuLink[]; tagline?: string }> = ({
  menuLinks,
  tagline,
}) => {
  const [t, { language }] = useTranslation('common');
  const {
    navLinks,
    brandLogos,
    socialMedia,
    reasonsOfConfidence,
    logo,
  } = usePageFooter();

  const newsletterSubscriptionRoute = getRouteByPageTypeLocale(
    PageType.NewsletterSubscription,
    language
  );

  const routeProps = newsletterSubscriptionRoute
    ? {
        href: newsletterSubscriptionRoute.page,
        as: newsletterSubscriptionRoute.builder({}),
        locale: null,
      }
    : {};

  const routerPropsLogo = useMemo(() => {
    const route = getRouteByPageTypeLocale(PageType.Home, language);

    return getTargetPageData({
      targetPageType: PageType.Home,
      uri: route
        ? route.builder({})
        : language !== languageDefault
        ? `/${language}`
        : '/',
    });
  }, [language]);

  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <FooterBottom>
          {logo ? (
            <div>
              <FooterLogoWrapper>
                <RouterLink {...routerPropsLogo} passHref>
                  <FooterLogo>
                    <Image
                      width={226}
                      height={72}
                      {...logo}
                      className="footer-logo"
                    />
                  </FooterLogo>
                </RouterLink>
              </FooterLogoWrapper>
              <Tagline>{tagline}</Tagline>
            </div>
          ) : null}
          <ReasonsOfConfidenceWrapper
            text={reasonsOfConfidence.text}
            title={reasonsOfConfidence.title}
          />
          <BrandLogos brandLogos={brandLogos} className="th-brands" />
        </FooterBottom>
      </FooterContentWrapper>

      <FooterContentWrapper>
        <FooterBody>
          <FooterMainContentWrapper>
            <FooterMenu links={menuLinks} />
            <FooterLinks>
              <RouterLink {...routeProps} passHref>
                <NewsletterLink className="newsletter-link">
                  {t('newsletter.button')}
                </NewsletterLink>
              </RouterLink>
              <FooterSocialMedia items={socialMedia} className="th-social" />
              <LanguageControl />
            </FooterLinks>
          </FooterMainContentWrapper>
        </FooterBody>
      </FooterContentWrapper>

      <FooterContentWrapper>
        <Container>
          <FooterFeedbackBoxWrapper>
            <LinksListWrapper links={navLinks} />
            <FooterFeedbackBox />
          </FooterFeedbackBoxWrapper>
        </Container>
      </FooterContentWrapper>
      <Container>
        <FooterCopyright copyright={t('copyright.mainText')} />
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
