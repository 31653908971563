import { SEARCH_SECTION_KEY_PREFIX } from './SearchSection';

export const clearSectionsPosition = (): void => {
  const savedSectionsPositionsKeys = Object.keys(sessionStorage).filter(
    key => key.includes(SEARCH_SECTION_KEY_PREFIX) && key
  );
  savedSectionsPositionsKeys.forEach(key => {
    sessionStorage.removeItem(key);
  });
};
