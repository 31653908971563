import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { languageList } from '@hotelplan/libs.language';
import useLanguage from './useLanguage';

const LanguageControlWrapper = styled.div(({ theme: { media } }) => ({
  display: 'flex',
  fontSize: '12px',
  fontWeight: '900',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  position: 'absolute',
  '@media screen and (max-width: 896px) and (orientation: landscape)': {
    position: 'static',
  },
  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    position: 'static',
  },
  top: '28px',
  right: '35px',
  [media.tablet]: {
    position: 'static',
    top: 'auto',
    right: 'auto',
  },
  '.lang-link:hover': {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

const Language = styled(Link)(({ theme: { space, colors } }) => ({
  padding: space[1],
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '2px',
    bottom: '0px',
    left: '0px',
    background: 'transparent',
  },
  '&.active:after': {
    background: 'white',
  },
  '&:hover': {
    color: colors.white,
    '&:after': {
      background: colors.white,
    },
  },
}));

const LanguageControl: React.FC = () => {
  const [language, changeLanguage] = useLanguage();

  function handleChangeLanguage(event) {
    const nextLang = event.currentTarget.getAttribute(`data-lang`);
    changeLanguage(nextLang);
  }

  return (
    <LanguageControlWrapper>
      {languageList.map(lang => {
        return (
          <Language
            key={`language-${lang}`}
            className={lang === language ? 'active lang-link' : 'lang-link'}
            data-lang={lang}
            onClick={handleChangeLanguage}
          >
            {lang}
          </Language>
        );
      })}
    </LanguageControlWrapper>
  );
};

export default LanguageControl;
