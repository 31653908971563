import React, { useMemo } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { NotificationInfo, PageType } from '@hotelplan/platform-graphql-api';
import { BookmarkContextProvider } from 'components/domain/bookmark/BookmarkContext';
import BookmarkModalPopup from 'components/domain/bookmark/BookmarkModalPopup';
import { INormalizedBreadcrumb } from 'components/domain/breadcrumbs-section/BreadcrumbsSection';
import {
  ExternalOverlay,
  useExternalOverlay,
} from 'components/domain/external-overlay';
import Footer from 'components/domain/footer/Footer';
import Header from 'components/domain/header/Header';
import { useHeaderNavigation } from 'components/domain/header/useHeaderNavigation';
import HeaderBreadcrumbs from 'components/domain/HeaderBreadcrumbs';
import { GclIdProvider } from 'components/domain/jotform/useGclIdValue';
import { LayoutProviderWrapper } from 'components/domain/layout/Layout.context';
import { MetaModules } from 'components/domain/meta-modules';
import MobileBottomMenu from 'components/domain/mobile-bottom-menu/MobileBottomMenu';
import SearchOverlay from 'components/domain/search/SearchOverlay';
import { StructuredDataBreadcrumbsPages } from 'components/domain/structured-data/StructuredData.utils';
import { StructuredDataBreadcrumbs } from 'components/domain/structured-data/StructuredDataBreadcrumbs';
import { useGetInfoNotificationQuery } from 'graphql/notifications/GetInfoNotification.generated';
import OgType from './OgType';

const Layout: React.FC<{
  tagline?: string;
  pageType: PageType;
  gclidValue: string;
  breadcrumbs?: INormalizedBreadcrumb[];
  origin?: string;
}> = ({ children, tagline, pageType, gclidValue, breadcrumbs, origin }) => {
  const { data } = useGetInfoNotificationQuery({});
  const { logo, navigationLinks } = useHeaderNavigation();
  const { show, close, link, loading } = useExternalOverlay();
  const { mobile } = useDeviceType();

  const notification: NotificationInfo | undefined = data?.notificationInfoData;

  const renderBreadcrumbs = useMemo(() => {
    if (!breadcrumbs?.length) return null;
    return (
      <>
        <HeaderBreadcrumbs breadcrumbs={breadcrumbs} />
        {origin && StructuredDataBreadcrumbsPages.includes(pageType) && (
          <StructuredDataBreadcrumbs
            breadcrumbs={breadcrumbs}
            origin={origin}
          />
        )}
      </>
    );
  }, [breadcrumbs, origin]);

  return (
    <LayoutProviderWrapper>
      <GclIdProvider gclidValue={gclidValue}>
        <BookmarkContextProvider>
          <OgType pageType={pageType} />
          <Header
            logo={logo}
            menuLinks={navigationLinks}
            pageType={pageType}
            breadcrumbs={renderBreadcrumbs}
            notificationInfo={notification}
          />
          <main>{children}</main>
          <Footer menuLinks={navigationLinks} tagline={tagline} />
          {mobile ? <MobileBottomMenu pageType={pageType} /> : null}
          <BookmarkModalPopup />
          <SearchOverlay />
          <ExternalOverlay
            showOverlay={show}
            closeOverlay={close}
            href={link}
            loading={loading}
          />
          <MetaModules />
        </BookmarkContextProvider>
      </GclIdProvider>
    </LayoutProviderWrapper>
  );
};

export default Layout;
