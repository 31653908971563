import { PageType } from '@hotelplan/platform-graphql-api';
import { useAddBookmarkToBookmarkGroupMutation } from 'graphql/bookmark/AddBookmarkToBookmarkGroup.generated';

export function useAddBookmarkToBookmarkGroup() {
  const [mutation, { data, loading }] = useAddBookmarkToBookmarkGroupMutation({
    refetchQueries: [
      `GetBookmarkCount`,
      // eslint-disable-next-line i18next/no-literal-string
      'GetBookmarkGroups',
    ],
  });

  async function addBookmarkToBookmarkGroup(
    pageType: PageType,
    objectId: string,
    groupId: string
  ) {
    return mutation({
      variables: {
        pageType,
        objectId,
        groupId,
      },
    });
  }

  return {
    addBookmarkToBookmarkGroup,
    data,
    loading,
  };
}
