import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ThirdHeading } from 'theme/headings';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Icon } from '@hotelplan/components.common.icon';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import {
  AddressDetails,
  AuthChannelType,
  SrlProductInfoB2B,
  SrlProductInfoB2C,
} from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { CardItemBreadcrumbs } from 'components/domain/recommendations/Recommendations.common';
import { OpeningStatus, TeaserDescription } from './Search.common';
import SearchProductInfo from './SearchProductInfo';

interface ISearchItemDescriptionProps {
  name: string;
  highlightedText: string;
  addressDetails?: AddressDetails;
  opened?: boolean;
  breadcrumbs?: string[];
  productInfoB2B?: SrlProductInfoB2B;
  productInfoB2C?: SrlProductInfoB2C;
  sectionKey?: string;
  imageHeight: number;
  mainText?: string;
}

const Title = styled.div<{ mobile: boolean }>(({ mobile }) =>
  sx2CssThemeFn({
    ...cutTextByLinesCount(mobile ? 3 : 2),
    overflow: 'hidden',
    fontSize: '24px',
  })
);

const InnerDescriptionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
  })
);

const searchItemOptions = {
  extraHotels: { highlightedText: false, leadInfoCutLines: 3 },
  regularHotels: { highlightedText: false, leadInfoCutLines: 3 },
  themes: { highlightedText: true },
  blogArticles: { highlightedText: false },
  other: { highlightedText: true },
};

const SearchItemDescription = ({
  breadcrumbs,
  addressDetails,
  highlightedText,
  productInfoB2B,
  productInfoB2C,
  name,
  opened,
  sectionKey,
  imageHeight,
  mainText,
}: ISearchItemDescriptionProps): ReactElement => {
  const { channelType } = useAuthentication();
  const { mobile } = useDeviceType();

  const isB2B = channelType === AuthChannelType.B2B;

  return (
    <TeaserDescription
      imageHeight={imageHeight}
      leadInfoCutLines={searchItemOptions[sectionKey]?.leadInfoCutLines}
    >
      <InnerDescriptionWrapper>
        <Title mobile={mobile}>
          <ThirdHeading>{name}</ThirdHeading>
        </Title>
        {breadcrumbs?.length ? (
          <CardItemBreadcrumbs
            breadcrumbs={breadcrumbs}
            className="search-item-breadcrumbs"
          />
        ) : null}
        {highlightedText && searchItemOptions[sectionKey]?.highlightedText ? (
          <div
            className="description-text"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          />
        ) : null}
        {mainText ? (
          <div
            className="main-text"
            dangerouslySetInnerHTML={{ __html: mainText }}
          />
        ) : null}
        <SearchProductInfo
          duration={isB2B && productInfoB2B?.duration}
          priceFrom={isB2B && productInfoB2B?.priceFrom}
          route={isB2B && productInfoB2B?.route}
          lead={isB2B ? productInfoB2B?.lead : productInfoB2C?.lead}
          typeFlags={
            isB2B ? productInfoB2B?.typeFlags : productInfoB2C?.typeFlags
          }
        />
        {opened || addressDetails ? (
          <>
            <span>{addressDetails.street}</span>
            <br />
            <span>{`${addressDetails.zip} ${addressDetails.city}`}</span>
            {opened !== null ? <OpeningStatus opened={opened} /> : null}
          </>
        ) : null}
      </InnerDescriptionWrapper>
      <Icon name="chevron-long-right" className="icon" size="lg" />
    </TeaserDescription>
  );
};

export default SearchItemDescription;
