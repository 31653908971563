import { PageType } from '@hotelplan/platform-graphql-api';
import { useAddBookmarkItemMutation } from 'graphql/bookmark/AddBookmarkItem.generated';

export function useAddBookmarkItemWithoutGroup() {
  const [mutation, { data, loading }] = useAddBookmarkItemMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: [`GetBookmarkCount`, 'GetBookmarkGroups'],
  });

  async function addBookmarkItem(pageType: PageType, objectId: string) {
    mutation({
      variables: {
        pageType,
        objectId,
      },
    });
  }

  return {
    addBookmarkItem,
    data,
    loading,
  };
}
