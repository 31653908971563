import { useTranslation } from 'next-i18next';
import React from 'react';
import { PageType } from '@hotelplan/platform-graphql-api';
import {
  BookmarkComponentType,
  trackAddBookmark,
  trackRemoveBookmark,
} from 'components/domain/tracking/bookmarkTracking';
import { useBookmarkContext } from './BookmarkContext';
import BookmarkIcon from './BookmarkIcon';
import useBookmarked from './useBookmarked';

interface IWishlistIconPros {
  pageType: PageType;
  objectId: string;
  active?: boolean;
  trackingComponent?: BookmarkComponentType;
}

const SingularBookmarkGroupBlock: React.FC<IWishlistIconPros> = ({
  pageType,
  objectId,
  active,
  trackingComponent,
}) => {
  const { bookmarked } = useBookmarked(objectId, pageType, active);
  const bookmarkContext = useBookmarkContext();
  const [, { language }] = useTranslation();

  async function handleClick() {
    const group = bookmarkContext.bookmarkGroups?.[0];

    if (bookmarked) {
      if (trackingComponent) {
        trackRemoveBookmark(trackingComponent, language);
      }

      bookmarkContext.removeBookmark({
        objectId,
        pageType,
        groupId: group.id,
      });
    } else {
      if (trackingComponent) {
        trackAddBookmark(trackingComponent, language);
      }

      bookmarkContext.addBookmark({ objectId, pageType, groupId: group?.id });
    }
  }

  return <BookmarkIcon onClick={handleClick} active={bookmarked} />;
};

export default SingularBookmarkGroupBlock;
