import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { expandChat } from '@hotelplan/components.common.chat';
import { AuthChannelType } from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Chat from 'components/domain/chat-container/ChatContainer';
import { Container } from 'components/domain/container';
import { LayoutContext } from 'components/domain/layout/Layout.context';
import { useGetAgencyBoxDataQuery } from 'graphql/agency/GetAgencyBoxData.generated';
import { useGetAgencyContactQuery } from 'graphql/agency/GetAgencyContact.generated';
import { useGetHelpOverlayQuery } from 'graphql/layout/GetHelpOverlay.generated';
import {
  HelpOverlayAgencyBox,
  HelpOverlayChatBox,
  HelpOverlayContactBox,
  HelpOverlayGeneralBox,
} from './HelpOverlayBoxes';

const HelpOverlayBackground = styled.div.attrs({
  'data-id': `help-overlay`,
})<{
  headerHeight: number;
}>(({ theme: { colors, shadows, media }, headerHeight }) => ({
  backgroundColor: colors.redVivid,
  width: '100%',
  display: 'flex',
  [media.desktop]: {
    maxHeight: '90vh',
    overflow: 'auto',
  },
  [media.mobile]: {
    paddingBottom: '10vh',
  },
  [media.tablet]: {
    position: 'absolute',
    left: '0px',
    top: `calc(16px + ${headerHeight}px)`,
    boxShadow: shadows.cardShadow,
    zIndex: 11,
  },
}));

const HelpOverlayWrapper = styled(Container)(
  sx2CssThemeFn({
    display: 'flex',
    pt: [3, 9],
    pb: [0, 9],
    px: [3, 4],
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'stretch'],
    justifyContent: 'center',
    width: '100%',
    flex: ['1', 'auto'],
    color: 'black',
    overflowY: [`scroll`, 'auto'],
    '-webkit-overflow-scrolling': `touch`,

    '.image-text': {
      mb: [2, 3],
      fontWeight: 'normal',
      fontSize: 2,
      textAlign: 'center',
    },
  })
);

enum HelpOverlayBoxes {
  BoxContact = 'HelpOverlayBoxContact',
  BoxGeneral = 'HelpOverlayBox',
  BoxChat = 'HelpOverlayBoxChat',
}

const HelpOverlay: React.FC<{
  dropDownRef?: React.MutableRefObject<HTMLDivElement>;
  onClose?(): void;
}> = ({ dropDownRef, onClose }) => {
  const [, { language }] = useTranslation();
  const { agency } = useRequestContext();
  const { headerHeight } = useContext(LayoutContext);

  const { data: helpOverlayData } = useGetHelpOverlayQuery({
    ssr: false,
  });

  const { data: agencyContactData } = useGetAgencyContactQuery({
    variables: { id: agency || '' },
  });

  const { channelType } = useAuthentication();
  const isB2C = channelType !== AuthChannelType.B2B;

  const { data: agencyBoxData } = useGetAgencyBoxDataQuery({
    variables: { id: agency },
    skip: !agency || !isB2C,
  });

  const showAgencyBox = agency && isB2C && agencyBoxData?.agency;

  return (
    <HelpOverlayBackground ref={dropDownRef} headerHeight={headerHeight}>
      <HelpOverlayWrapper>
        {(helpOverlayData?.helpOverlayData.helpOverlayBoxes || []).map(
          (box, index, boxes) => {
            switch (box?.__typename) {
              case HelpOverlayBoxes.BoxGeneral: {
                if (showAgencyBox && index === 0)
                  return (
                    <HelpOverlayAgencyBox
                      box={box}
                      boxesCount={boxes.length}
                      agency={agencyBoxData?.agency}
                      onClick={onClose}
                    />
                  );
                return (
                  <HelpOverlayGeneralBox
                    key={`box-${index}`}
                    box={box}
                    boxesCount={boxes.length}
                    onClick={onClose}
                  />
                );
              }
              case HelpOverlayBoxes.BoxContact: {
                return agencyContactData?.agencyContact ? (
                  <HelpOverlayContactBox
                    key={`box-${index}`}
                    box={box}
                    boxesCount={boxes.length}
                    phone={agencyContactData.agencyContact.phone}
                    timezone={agencyContactData.agencyContact.timezone}
                    openingHours={agencyContactData.agencyContact.openingHours}
                  />
                ) : null;
              }
              case HelpOverlayBoxes.BoxChat: {
                return language === 'de' ? (
                  <React.Fragment key={index}>
                    <HelpOverlayChatBox
                      box={box}
                      boxesCount={boxes.length}
                      onClick={e => {
                        e.preventDefault();
                        expandChat();
                      }}
                    />
                    <Chat />
                  </React.Fragment>
                ) : null;
              }
              default: {
                return null;
              }
            }
          }
        )}
      </HelpOverlayWrapper>
    </HelpOverlayBackground>
  );
};

export default HelpOverlay;
