import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AddBookmarkToBookmarkGroupMutationVariables = Types.Exact<{
  pageType: Types.PageType;
  objectId: Types.Scalars['String'];
  groupId: Types.Scalars['String'];
}>;

export type AddBookmarkToBookmarkGroupMutation = {
  __typename?: 'Mutation';
  addBookmark: {
    __typename?: 'BookmarkQuantityResponse';
    status: Types.Status;
    message?: string | null;
  };
};

export const AddBookmarkToBookmarkGroupDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBookmarkToBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type AddBookmarkToBookmarkGroupMutationFn = Apollo.MutationFunction<
  AddBookmarkToBookmarkGroupMutation,
  AddBookmarkToBookmarkGroupMutationVariables
>;

/**
 * __useAddBookmarkToBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useAddBookmarkToBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookmarkToBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookmarkToBookmarkGroupMutation, { data, loading, error }] = useAddBookmarkToBookmarkGroupMutation({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      objectId: // value for 'objectId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useAddBookmarkToBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddBookmarkToBookmarkGroupMutation,
    AddBookmarkToBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddBookmarkToBookmarkGroupMutation,
    AddBookmarkToBookmarkGroupMutationVariables
  >(AddBookmarkToBookmarkGroupDocument, options);
}
export type AddBookmarkToBookmarkGroupMutationHookResult = ReturnType<
  typeof useAddBookmarkToBookmarkGroupMutation
>;
export type AddBookmarkToBookmarkGroupMutationResult = Apollo.MutationResult<AddBookmarkToBookmarkGroupMutation>;
export type AddBookmarkToBookmarkGroupMutationOptions = Apollo.BaseMutationOptions<
  AddBookmarkToBookmarkGroupMutation,
  AddBookmarkToBookmarkGroupMutationVariables
>;
