import { useEffect, useState } from 'react';
import { PageType } from '@hotelplan/platform-graphql-api';
import { useBookmarkContext } from './BookmarkContext';
import { selectBookmarkItem } from './BookmarkContext.utils';

export default function useBookmarked(
  objectId: string,
  pageType: PageType,
  bookmarkedByDefault?: boolean,
  groupId?: string
): { bookmarked: boolean } {
  const [bookmarked, setBookmarked] = useState<boolean>(
    bookmarkedByDefault ?? false
  );
  const bookmarkContext = useBookmarkContext();

  useEffect(() => {
    const bookmarkedItem = selectBookmarkItem(
      bookmarkContext,
      objectId,
      pageType,
      groupId
    );

    if (bookmarkedItem) {
      setBookmarked(true);
    } else {
      setBookmarked(false);
    }
  }, [bookmarkContext.bookmarkGroups, objectId, pageType, groupId]);

  return { bookmarked };
}
