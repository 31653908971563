import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { PageFragmentDoc } from './Page.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
export type HistoryUserResponseFragment = {
  __typename?: 'HistoryUserResponse';
  userRecords?: Array<{
    __typename?: 'HistoryUserRecord';
    title: string;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
      objectId?: string | null;
    };
  }> | null;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
};

export type HistoryUserRecordFragment = {
  __typename?: 'HistoryUserRecord';
  title: string;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
    objectId?: string | null;
  };
};

export const HistoryUserRecordFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyUserRecord' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HistoryUserRecord' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HistoryUserResponseFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyUserResponse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HistoryUserResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRecords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'historyUserRecord' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
        ],
      },
    },
    ...HistoryUserRecordFragmentDoc.definitions,
    ...PageFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
