import React from 'react';
import styled from 'styled-components';
import { CookiesDisclaimer } from '@hotelplan/components.common.cookies-disclaimer';
import { useGetCookieDisclaimer } from './useGetCookieDisclaimer';

const IPAD_MINI_SCREEN = `@media screen and (min-width: 768px) and (max-width: 1024px)`;

const CookiesDisclaimerWrap = styled(CookiesDisclaimer)(
  ({ theme, theme: { media, space, fontSizes } }) => ({
    [IPAD_MINI_SCREEN]: {
      flexWrap: 'nowrap',
    },
    borderTop: '1px solid white',
    boxShadow: '20px 0px 20px 0px #0000004D',
    '.cookies-disclaimer-text': {
      paddingLeft: space[4],
      fontSize: fontSizes[0],
      color: '#C69E9E',
      lineHeight: 1.2,
      letterSpacing: '1px',
      a: {
        textDecoration: 'underline',
        color: 'inherit',
      },
      [media.tablet]: {
        paddingRight: 0,
        paddingLeft: space[6],
      },
    },
    '.cookies-disclaimer-button': {
      ...theme.buttons.cookieAccept,
      marginLeft: space[4],
      marginBottom: '18px',
      [media.tablet]: {
        marginBottom: '0px',
      },
      [IPAD_MINI_SCREEN]: {
        marginRight: space[4],
        marginLeft: space[0],
      },
      [media.mobile]: {
        width: 'auto',
        marginRight: space[4],
        flexGrow: 1,
      },
    },
  })
);

const CookiesDisclaimerBanner: React.FC = () => {
  const { cookieDisclaimerText } = useGetCookieDisclaimer();
  return cookieDisclaimerText ? (
    <CookiesDisclaimerWrap text={cookieDisclaimerText} />
  ) : null;
};

export default CookiesDisclaimerBanner;
