import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InfoNotification } from '@hotelplan/components.common.notifications';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { NotificationInfo } from '@hotelplan/platform-graphql-api';
import { Container } from 'components/domain/container';
import { LayoutContext } from 'components/domain/layout/Layout.context';

const NotificationInfoContainer = styled.div.attrs({
  id: 'notification-info',
})(({ theme: { colors, space, media } }) => ({
  borderRadius: '0px',
  borderBottom: '1px solid',
  borderColor: colors.borderColor,
  paddingTop: space[1],
  paddingBottom: space[1],
  width: '100%',
  minHeight: '38px',
  backgroundColor: 'white',
  display: 'flex',
  'p, a': {
    fontSize: '16px',
    lineHeight: '22px',
  },
  [media.mobile]: {
    '> div': {
      padding: `0 ${space[3]}`,
    },
  },
}));

const InfoNotificationBanner: React.FC<{
  data?: NotificationInfo;
}> = ({ data }) => {
  const mainText = data?.mainText;
  const icon = data?.icon;
  const containerRef = useRef<HTMLDivElement>(null);
  const { setInfoHeight } = useContext(LayoutContext);

  const { h } = useElementSize(containerRef, { throttle: 100 });

  useEffect(() => {
    setInfoHeight(h);
  }, [h]);

  return (
    <NotificationInfoContainer ref={containerRef}>
      <Container>
        <InfoNotification
          mainText={mainText}
          icon={icon.resized.length ? icon : null}
        />
      </Container>
    </NotificationInfoContainer>
  );
};

export default React.memo(InfoNotificationBanner);
