import React from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import HelpOverlay from './HelpOverlay';

const WhiteBoxModalWrapper = styled(WhiteBoxModal)(({ theme: { colors } }) => ({
  '.modal-header': {
    backgroundColor: colors.redVivid,
    borderBottom: 'none',
    color: colors.white,
    '.icon': {
      width: '30px',
      height: '30px',
    },
  },
  '.modal-wrapper': {
    maxHeight: '100%',
  },
}));

interface IHelpOverlayMobileProps {
  visible: boolean;
  close(): void;
}

export const HelpOverlayMobile: React.FC<IHelpOverlayMobileProps> = ({
  visible,
  close,
}) => {
  return (
    <>
      <WhiteBoxModalWrapper
        fullScreen
        name="mobile-help"
        show={visible}
        onClose={close}
        title=""
      >
        <HelpOverlay onClose={close} />
      </WhiteBoxModalWrapper>
    </>
  );
};
