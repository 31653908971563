import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import TypeFlagItem from './SearchTypeFlagItem';

interface ISearchItemProps {
  typeFlags?: string[];
}

const TypeFlagsWrapper = styled.div(
  sx2CssThemeFn({
    maxHeight: '42px',
    paddingTop: '10px',
    overflow: 'hidden',
    marginTop: 'auto',
    maxWidth: '420px',
    display: 'flex',
    flexWrap: 'wrap',
  })
);

const SearchTypeFlags: FC<ISearchItemProps> = ({ typeFlags }): ReactElement => {
  if (!typeFlags) return null;

  return (
    <TypeFlagsWrapper>
      {typeFlags.map((flag, index) => (
        <TypeFlagItem key={index}>{flag}</TypeFlagItem>
      ))}
    </TypeFlagsWrapper>
  );
};

export default SearchTypeFlags;
