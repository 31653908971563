import React from 'react';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { PageType } from '@hotelplan/platform-graphql-api';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { FeatureList } from 'config/featureList';
import MultipleBookmarkGroupBlock from './MultipleBookmarkGroupBlock';
import SingularBookmarkGroupBlock from './SingularBookmarkGroupBlock';

interface IBookmarkBlockProps {
  pageType: PageType;
  objectId: string;
  active?: boolean;
  trackingComponent?: BookmarkComponentType;
}

const BookmarkBlock: React.FC<IBookmarkBlockProps> = ({
  pageType,
  objectId,
  active,
  trackingComponent,
}) => {
  const { isEnabled } = useFeatureToggle();
  const multipleBookmarksEnabled = isEnabled(FeatureList.MULTIPLE_BOOKMARKS);

  return (
    <>
      {!multipleBookmarksEnabled && (
        <SingularBookmarkGroupBlock
          pageType={pageType}
          objectId={objectId}
          active={active}
          trackingComponent={trackingComponent}
        />
      )}
      {multipleBookmarksEnabled && (
        <MultipleBookmarkGroupBlock
          pageType={pageType}
          objectId={objectId}
          active={active}
          trackingComponent={trackingComponent}
        />
      )}
    </>
  );
};

export default BookmarkBlock;
