import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { AddressDetailsFragmentDoc } from 'graphql/fragments/AddressDetails.generated';
import { OpeningHoursFragmentDoc } from 'graphql/fragments/OpeningHours.generated';
import { PhoneFragmentDoc } from 'graphql/fragments/Phone.generated';
import { ExternalLinkFragmentDoc } from 'graphql/link/ExternalLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetAgencyContactQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetAgencyContactQuery = {
  __typename?: 'Query';
  agencyContact: {
    __typename?: 'AgencyContact';
    title: string;
    timezone: string;
    addressDetails: {
      __typename?: 'AddressDetails';
      city?: string | null;
      country?: string | null;
      region?: string | null;
      street?: string | null;
      zip?: string | null;
    };
    openingHours: Array<{
      __typename?: 'OpeningHours';
      open: boolean;
      shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
    }>;
    phone: {
      __typename?: 'AgencyContactPhone';
      title?: string | null;
      link: {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      };
    };
    emailLink: {
      __typename?: 'ExternalLink';
      openMethod: Types.OpenLinkMethod;
      url: string;
      caption?: string | null;
    };
  };
};

export const GetAgencyContactDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgencyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agencyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'addressDetails' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'openingHours' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'phone' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'externalLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AddressDetailsFragmentDoc.definitions,
    ...OpeningHoursFragmentDoc.definitions,
    ...PhoneFragmentDoc.definitions,
    ...ExternalLinkFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetAgencyContactQuery__
 *
 * To run a query within a React component, call `useGetAgencyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetAgencyContactQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >(GetAgencyContactDocument, options);
}
export function useGetAgencyContactLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >(GetAgencyContactDocument, options);
}
export type GetAgencyContactQueryHookResult = ReturnType<
  typeof useGetAgencyContactQuery
>;
export type GetAgencyContactLazyQueryHookResult = ReturnType<
  typeof useGetAgencyContactLazyQuery
>;
export type GetAgencyContactQueryResult = Apollo.QueryResult<
  GetAgencyContactQuery,
  GetAgencyContactQueryVariables
>;
