import { useMemo } from 'react';
import { getNavigationLinks } from '@hotelplan/components.common.menu';
import { IconMenuItem, Navigable } from '@hotelplan/graphql.types';
import { useGetFooterQuery } from 'graphql/layout/GetFooter.generated';
import { IconMenuEntryFragment } from 'graphql/layout/IconMenuEntry.generated';

export function usePageFooter() {
  const { data, loading } = useGetFooterQuery();

  const navMenu = data?.pageFooter.navigationMenu;
  const navLinks = navMenu ? getNavigationLinks(navMenu as Navigable[]) : [];

  return useMemo(() => {
    return {
      loading,
      navLinks,
      brandLogos: (data?.pageFooter.brandLogos || []) as IconMenuItem[],
      socialMedia: (data?.pageFooter.socialMedia ||
        []) as IconMenuEntryFragment[],
      reasonsOfConfidence: {
        title: data?.pageFooter.reasonsOfConfidence?.title || '',
        text: data?.pageFooter.reasonsOfConfidence?.text || '',
      },
      logo: data?.pageFooter.logo,
    };
  }, [data, loading]);
}
