import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { SearchTypeEnum } from '@hotelplan/platform-graphql-api';
import { useExactSearchContext } from 'components/domain/exact-search/ExactSearchContext';
import { useAutoSuggestQuery as useAutoSuggestQueryGenerated } from 'graphql/search/AutoSuggest.generated';

const AUTO_SUGGEST_RESULTS_PER_PAGE = 5;
const KEYWORD_SEPARATOR = ' ';

export const useAutoSuggestProductQuery = (searchValue: string) => {
  const [exactSearch] = useExactSearchContext();
  const { channelType } = useAuthentication();

  const { data, loading, refetch } = useAutoSuggestQueryGenerated({
    variables: {
      keywords: searchValue.split(KEYWORD_SEPARATOR),
      ...(channelType === AuthChannelType.B2B && {
        exactSearch,
      }),
      types: [SearchTypeEnum.AutosuggestProducts],
      page: {
        pageNumber: 0,
        resultsPerPage: AUTO_SUGGEST_RESULTS_PER_PAGE,
      },
    },
  });

  return {
    data,
    loading,
    refetch,
  };
};

export const useAutoSuggestNonProductQuery = (searchValue: string) => {
  const [exactSearch] = useExactSearchContext();
  const { channelType } = useAuthentication();
  const { data, loading, refetch } = useAutoSuggestQueryGenerated({
    variables: {
      keywords: searchValue.split(KEYWORD_SEPARATOR),
      ...(channelType === AuthChannelType.B2B && {
        exactSearch,
      }),
      types: [SearchTypeEnum.AutosuggestNonProducts],
      page: {
        pageNumber: 0,
        resultsPerPage: AUTO_SUGGEST_RESULTS_PER_PAGE,
      },
    },
  });

  return {
    data,
    loading,
    refetch,
  };
};
