import { FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
} from './BookmarkPopup.styles';
import BookmarkPopupNewGroupForm from './BookmarkPopupNewGroupForm';

interface IBookmarkPopupNewGroupProps {
  onSubmit(
    values: IBookmarkPopupNewGroupFormState,
    helpers: FormikHelpers<IBookmarkPopupNewGroupFormState>
  ): void;
  onCloseButtonClick(): void;
  onBackButtonClick?(): void;
}

interface IBookmarkPopupNewGroupFormState {
  name: string;
}

const BookmarkPopupBackButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: {
    name: 'chevron-long-left',
    size: 'lg',
  },
})({
  marginRight: '12px',
  flex: '0 1 auto',
  '&:hover, &:focus': {
    backgroundColor: 'initial',
    '.icon': {
      fill: 'initial',
    },
  },
});

const BookmarkPopupNewGroup: React.FC<IBookmarkPopupNewGroupProps> = ({
  onSubmit,
  onCloseButtonClick,
  onBackButtonClick,
}) => {
  const [t] = useTranslation(['common']);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        {onBackButtonClick && (
          <BookmarkPopupBackButton
            onClick={event => {
              event.preventDefault();
              onBackButtonClick();
            }}
          >
            {'common:back'}
          </BookmarkPopupBackButton>
        )}
        <BookmarkPopupHeaderText>
          {t('bookmark.group.create.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            onCloseButtonClick();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupNewGroupForm onSubmit={onSubmit} />
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkPopupNewGroup;
