import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/featureList';

enum PaginationVariants {
  IN_HEADER = 'in-header',
  IN_SLIDES = 'in-slides',
}

const usePaginationVariant = (): boolean => {
  const { getVariant } = useFeatureToggle();
  const { mobile } = useDeviceType();

  const variant = getVariant(FeatureList.PAGINATION_VARIANT);
  const isInSlidesPaginationEnabled =
    variant?.enabled && variant?.name === PaginationVariants.IN_SLIDES;

  return isInSlidesPaginationEnabled && !mobile;
};

export default usePaginationVariant;
