import dynamic from 'next/dynamic';
import React from 'react';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { DeviceType } from '@hotelplan/platform-graphql-api';
import BookmarkAddModalPopup from 'components/domain/bookmark/BookmarkAddModalPopup';
import BookmarkEditModal from 'components/domain/bookmark/BookmarkEditModal';
import BookmarkGroupCreateModalPopup from 'components/domain/bookmark/BookmarkGroupCreateModalPopup';
import BookmarkGroupEditModal from 'components/domain/bookmark/BookmarkGroupEditModal';
import BookmarkGroupRemoveModal from 'components/domain/bookmark/BookmarkGroupRemoveModal';
import BookmarkGroupShareErrorModalPopup from './BookmarkGroupShareErrorModalPopup';
import BookmarkGroupShareModalPopup from './BookmarkGroupShareModalPopup';
import {
  BookmarkModalPopupType,
  useBookmarkModalPopupContext,
} from './BookmarkModalPopupContext';

const BookmarkModalContent: React.FC<{
  type: BookmarkModalPopupType | undefined;
}> = ({ type }) => {
  return (
    <>
      {type === BookmarkModalPopupType.ADD_BOOKMARK && (
        <BookmarkAddModalPopup />
      )}
      {type === BookmarkModalPopupType.EDIT_BOOKMARK_GROUP && (
        <BookmarkGroupEditModal />
      )}
      {type === BookmarkModalPopupType.REMOVE_BOOKMARK_GROUP && (
        <BookmarkGroupRemoveModal />
      )}
      {type === BookmarkModalPopupType.EDIT_BOOKMARK && <BookmarkEditModal />}
      {type === BookmarkModalPopupType.CREATE_BOOKMARK_GROUP && (
        <BookmarkGroupCreateModalPopup />
      )}
      {type === BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_REPLACE && (
        <BookmarkGroupShareModalPopup />
      )}
      {type === BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_ERROR && (
        <BookmarkGroupShareErrorModalPopup />
      )}
    </>
  );
};

const BookmarkModalPopupDesktop = dynamic(
  () => import('components/domain/bookmark/BookmarkModalPopupDesktopWrapper')
);
const BookmarkModalPopupMobile = dynamic(
  () => import('components/domain/bookmark/BookmarkModalPopupMobileWrapper')
);

const BookmarkModalPopup: React.FC = () => {
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const { mobile, serverType } = useDeviceType();
  const content = (
    <BookmarkModalContent type={bookmarkModalContext?.modal?.type} />
  );

  const isNotDesktop = serverType !== DeviceType.Desktop;
  const isMobileView = typeof window === 'undefined' ? isNotDesktop : mobile;

  return (
    <>
      {!isMobileView && (
        <BookmarkModalPopupDesktop open={bookmarkModalContext.isOpen}>
          {content}
        </BookmarkModalPopupDesktop>
      )}
      {isMobileView && (
        <BookmarkModalPopupMobile open={bookmarkModalContext.isOpen}>
          {content}
        </BookmarkModalPopupMobile>
      )}
    </>
  );
};

export default withLazyHydrate(BookmarkModalPopup, {
  whenIdle: true,
});
