import { format } from 'url';
import Head from 'next/head';
import React from 'react';
import type { BreadcrumbList, WithContext } from 'schema-dts';
import { INormalizedBreadcrumb } from 'components/domain/breadcrumbs-section/BreadcrumbsSection';

interface IStructuredDataBreadcrumbs {
  breadcrumbs: INormalizedBreadcrumb[];
  origin: string;
}

const structuredDataForBreadcrumbs = ({
  breadcrumbs,
  origin,
}: IStructuredDataBreadcrumbs): WithContext<BreadcrumbList> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((item, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        ...(item.label && {
          name: item.label,
        }),
        ...(item.link?.as && {
          item: `${origin}${format(item.link.as)}`,
        }),
      };
    }),
  };
};

let StructuredDataBreadcrumbs: React.FC<IStructuredDataBreadcrumbs> = () =>
  null;

// structured data is not needed in browser
if (!process.browser) {
  // eslint-disable-next-line react/display-name
  StructuredDataBreadcrumbs = (
    props: IStructuredDataBreadcrumbs
  ): React.ReactElement => {
    if (!props.breadcrumbs?.length) return null;

    return (
      <Head>
        <script
          key="breadcrumbs-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredDataForBreadcrumbs(props)),
          }}
        />
      </Head>
    );
  };
}

export { StructuredDataBreadcrumbs };
