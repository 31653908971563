import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import type { IB2BAuthPanelProps } from '@hotelplan/components.common.auth';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { HeaderLayout } from '@hotelplan/components.common.header';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { DesktopMenu, IMenuLink } from '@hotelplan/components.common.menu';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { NotificationInfo, PageType } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import useB2BAuthOverlay from 'components/domain/auth/useB2BAuthOverlay';
import HeaderRequestButton from 'components/domain/header-request-button/HeaderRequestButton';
import { LayoutContext } from 'components/domain/layout/Layout.context';
import { InfoNotificationBanner } from 'components/domain/meta-modules';
import { openSearchOverlay } from 'components/domain/search';
import ContactsSiteTool from 'components/domain/sitetools/ContactsSiteTool';
import SearchSiteTool from 'components/domain/sitetools/SearchSiteTool';
import { SiteToolsWrapper } from 'components/domain/sitetools/SiteTools.common';
import WishlistSiteTool from 'components/domain/sitetools/WishlistSiteTool';

const DesktopMenuWrapper = styled(DesktopMenu).attrs({
  className: 'desktop-menu',
})(
  sx2CssThemeFn({
    '.sub-link-chevron': { display: 'none' },
    '.menu-link-item': {
      px: 3,
      py: 2,
      height: [null, 'auto'],
      border: 'none',
      letterSpacing: '0.5px',
      '> a': {
        minHeight: [null, 'auto'],
      },
    },
  })
);

const HeaderStickyWrapper = styled.div<{
  infoHeight: number;
}>(({ theme: { media }, infoHeight }) =>
  sx2CssThemeFn({
    position: 'absolute',
    maxWidth: '1480px',
    px: '20px',
    width: '100%',
    zIndex: 100,
    left: 0,
    right: 0,
    margin: '0 auto',
    [media.mobile]: {
      position: 'absolute',
      top: '0px',
      padding: 0,
    },
    '&.sticky': {
      position: 'fixed',
      top: infoHeight ? `-${infoHeight}px` : 0,
    },
  })
);

const HeaderLayoutWrapper = styled.div(
  sx2CssThemeFn({
    height: '100%',
  })
);

const HeaderBox = styled.div(
  sx2CssThemeFn({
    boxShadow: [null, '0px 0px 40px -15px rgba(0, 0, 0, 0.35)'],
    borderRadius: '0 0 5px 5px',
  })
);

const HeaderLayoutInner = styled(HeaderLayout)<{
  isWithBreadcrumbs: boolean;
}>(({ isWithBreadcrumbs }) =>
  sx2CssThemeFn({
    top: 0,
    width: '100%',
    borderBottom: 'none',
    zIndex: '100',
    header: {
      position: 'relative',
      left: '0',
      right: '0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      maxWidth: '1440px',
      background: ['transparent', 'white'],
      borderRadius: !isWithBreadcrumbs && '0px 0px 5px 5px',
      p: ['12px 16px 12px 16px', '12px 12px 12px 20px'],
      '.desktop': { display: ['none', 'flex'] },
      '> a': {
        mt: '-12px',
        mb: '0',
        flexGrow: 1,
      },
      img: {
        width: ['50%', '175px'],
        height: 'auto',
      },
    },
    '.static-request-button-wrapper': {
      marginLeft: [1],
      '.request-button': {
        borderRadius: '5px',
        padding: '9px 26px 11px',
        '.request-button-caption': {
          fontWeight: '400',
          letterSpacing: '0.5px',
          lineHeight: [null, '22px'],
        },
      },
    },
    '.desktop-menu': { p: 0 },
    'button[data-id="markup-control"]': { display: 'none' },
  })
);

interface IHeaderPropsType {
  logo?: INormalizedImage;
  menuLinks: IMenuLink[];
  pageType: PageType;
  isWithBreadcrumbs?: boolean;
  breadcrumbs?: React.ReactNode;
  notificationInfo?: NotificationInfo;
}

const B2BAuthPanelIdle = dynamic<IB2BAuthPanelProps>(
  () =>
    import('components/domain/auth/B2BAuthPanel').then(
      module => module.B2BAuthPanelIdle
    ),
  { ssr: false }
);

const HeaderMenuBar = styled.div({
  padding: '0 24px',
  gap: 4,
});

const Header: React.FC<IHeaderPropsType> = ({
  pageType,
  menuLinks,
  logo,
  breadcrumbs,
  notificationInfo,
}) => {
  const { setHeaderHeight, infoHeight } = useContext(LayoutContext);
  const { mobile } = useDeviceType();

  const headerRef = useRef(null);
  const sticky = useRef<boolean>(false);
  const header = useRef<HTMLDivElement>();
  const headerElement = header.current;
  const isShowInfoNotification = !!notificationInfo?.mainText;

  const { h: headerHeight } = useElementSize(headerRef, { throttle: 0 });

  const onScroll = () => {
    sticky.current = window.scrollY > infoHeight && !mobile;

    if (sticky.current) {
      headerElement.classList.add('sticky');
    } else {
      headerElement.classList.remove('sticky');
    }
  };

  const removeScrollListeners = () => {
    document.removeEventListener('click', onScroll);
    document.removeEventListener('touchstart', onScroll);
    document.removeEventListener('touchend', onScroll);
    document.removeEventListener('scroll', onScroll);
  };

  useEffect(() => {
    if (!headerElement || !infoHeight) return removeScrollListeners;

    document.addEventListener('click', onScroll);
    document.addEventListener('touchstart', onScroll);
    document.addEventListener('touchend', onScroll);
    document.addEventListener('scroll', onScroll);

    return removeScrollListeners;
  }, [headerElement, mobile, infoHeight]);

  useEffect(() => {
    setHeaderHeight(headerHeight - infoHeight);
  }, [headerHeight, infoHeight]);

  return (
    <>
      <HeaderStickyWrapper
        ref={header}
        infoHeight={infoHeight}
        id="header-sticky-wrapper"
        className={!infoHeight && !mobile && 'sticky'}
      >
        <HeaderBox ref={headerRef}>
          {isShowInfoNotification ? (
            <InfoNotificationBanner data={notificationInfo} />
          ) : null}
          {mobile && breadcrumbs ? breadcrumbs : null}
          <HeaderInner
            pageType={pageType}
            menuLinks={menuLinks}
            logo={logo}
            isWithBreadcrumbs={!!breadcrumbs}
          />
          {!mobile && breadcrumbs ? breadcrumbs : null}
        </HeaderBox>
      </HeaderStickyWrapper>
    </>
  );
};

const HeaderInner = ({
  logo,
  menuLinks,
  pageType,
  isWithBreadcrumbs,
}: IHeaderPropsType) => {
  const { authB2BOverlay, close } = useB2BAuthOverlay();
  const { channelType, logout } = useAuthentication();
  const { mobile } = useDeviceType();

  return (
    <HeaderLayoutWrapper>
      <HeaderLayoutInner logo={logo} isWithBreadcrumbs={isWithBreadcrumbs}>
        {!mobile ? (
          <div className="desktop">
            <HeaderMenuBar>
              <DesktopMenuWrapper links={menuLinks} isSubMenuBlocked={true} />
            </HeaderMenuBar>
            <SiteToolsWrapper>
              <WishlistSiteTool />
              <SearchSiteTool onSearch={openSearchOverlay} />
              <ContactsSiteTool />
              <B2BAuthPanelIdle
                authB2BOverlay={authB2BOverlay}
                channelType={channelType}
                close={close}
                logout={logout}
              />
            </SiteToolsWrapper>

            <HeaderRequestButton pageType={pageType} />
          </div>
        ) : null}
      </HeaderLayoutInner>
    </HeaderLayoutWrapper>
  );
};

export default Header;
