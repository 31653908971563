import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Image } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useExactSearchContext } from 'components/domain/exact-search/ExactSearchContext';
import { AllItemsButton } from 'components/domain/search/Search.common';
import {
  getNextAutoSuggestItem,
  getPrevAutoSuggestItem,
  getSearchTermPosition,
  IAutoSuggestItem,
  useSearchAutoSuggestKeyPress,
} from './SearchAutoSuggest.utils';
import {
  useAutoSuggestNonProductQuery,
  useAutoSuggestProductQuery,
} from './useAutoSuggestQuery';

interface ISearchAutoSuggestProps {
  searchValue: string;
  showError: boolean;
  onChange?(state: ISearchAutoSuggestChange): void;
  onSelect?(item: IAutoSuggestItem): void;
  onShowAll?(): void;
}

export interface ISearchAutoSuggestChange {
  count: number;
  activeItem: IAutoSuggestItem | null;
}

const SearchAutoSuggestContent = styled.div<{
  flex: boolean;
  showError: boolean;
}>(({ flex, showError, theme: { media } }) => ({
  position: 'absolute',
  top: showError ? 82 : 70,
  width: '100%',
  ...(flex && { display: 'flex' }),
  [media.mobile]: {
    top: showError ? 82 : 60,
  },
}));

const Column = styled.div(({ theme: { fontSizes } }) => ({
  padding: '20px',
  fontSize: fontSizes[0],
}));

const ProductColumn = styled(Column)<{ flex: boolean }>(
  ({ flex, theme: { colors, media } }) => ({
    ...(flex && { flex: '60%' }),
    backgroundColor: colors.paperGrey,
    borderRadius: '0 8px 8px 0',
    [media.mobile]: {
      borderRadius: '0 0 8px 8px',
      marginBottom: '50px',
    },
  })
);

const NonProductColumn = styled(Column)<{ flex: boolean }>(
  ({ flex, theme: { colors, media } }) => ({
    ...(flex && { flex: '40%' }),
    backgroundColor: colors.background,
    overflow: 'hidden',
    borderRadius: '8px 0 0 8px',
    [media.mobile]: {
      borderRadius: '8px 8px 0 0',
    },
    ':not(:has(+ .product))': {
      borderRadius: '8px',
    },
  })
);

const Header = styled.div(({ theme: { fontSizes } }) => ({
  fontSize: fontSizes[1],
  fontWeight: '400',
  textTransform: 'uppercase',
  padding: '5px 0',
}));

const AutoSuggestLink = styled(Link)({
  fontSize: '16px',
  display: 'block',
  padding: '5px 0',
});

const NonProductLink = styled(AutoSuggestLink)<{ active: boolean }>(
  ({ active, theme: { colors } }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...(active && { backgroundColor: colors.paperGrey }),
  })
);

const ProductHeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const ProductHeader = styled(Header)(({ theme: { space } }) => ({
  flex: '50%',
  textAlign: 'left',
  marginBottom: space[2],
}));

const ProductShowAllLinkContainer = styled(ProductHeader)(
  ({ theme: { colors, fontSizes } }) => ({
    fontSize: fontSizes[1],
    textTransform: 'initial',
    color: colors.primary,
  })
);

const ProductLink = styled(AutoSuggestLink)<{ active: boolean }>(
  ({ active, theme: { colors } }) => ({
    display: 'flex',
    ...(active && { backgroundColor: colors.white }),
  })
);

const ProductImageContainer = styled.div({
  flexBasis: '80px',
  flexShrink: 0,
  flexGrow: 0,
});

const ProductImage = styled(Image)({
  width: '100%',
});

const ProductContent = styled.div({
  padding: '5px 10px',
  fontWeight: 700,
});

const SearchAutoSuggestHighlight = styled.span({
  fontWeight: 700,
});

enum keys {
  DOWN = 'ArrowDown',
  UP = 'ArrowUp',
  ENTER = 'Enter',
}

const SearchAutoSuggestName: React.FC<{
  name: string;
  searchValue: string;
}> = props => {
  const { name, searchValue } = props;
  const searchTermPosition = getSearchTermPosition(name, searchValue);
  const prefix =
    (searchTermPosition && name.substring(0, searchTermPosition[0])) || null;
  const match =
    (searchTermPosition &&
      name.substring(searchTermPosition[0], searchTermPosition[1] + 1)) ||
    null;
  const postfix =
    (searchTermPosition &&
      name.substring(searchTermPosition[0], searchTermPosition[1] + 1)) ||
    null;

  if (searchTermPosition) {
    return (
      <>
        {prefix && (
          <SearchAutoSuggestHighlight>
            {name.substring(0, searchTermPosition[0])}
          </SearchAutoSuggestHighlight>
        )}
        {match ?? name}
        {postfix && (
          <SearchAutoSuggestHighlight>
            {name.substring(searchTermPosition[1] + 1)}
          </SearchAutoSuggestHighlight>
        )}
      </>
    );
  }

  return <>{name}</>;
};

const SearchAutoSuggest: React.FC<ISearchAutoSuggestProps> = props => {
  const { desktop } = useDeviceType();
  const { searchValue, onChange, onSelect, showError, onShowAll } = props;
  const [exactSearch] = useExactSearchContext();
  const [t] = useTranslation(['search']);
  const [active, setActive] = useState<IAutoSuggestItem>(null);
  const [autoSuggestList, setAutoSuggestList] = useState<IAutoSuggestItem[]>(
    []
  );
  const { channelType } = useAuthentication();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  const debounceSearchValueCallback = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
    }, 200),
    []
  );

  useEffect(() => debounceSearchValueCallback(searchValue), [searchValue]);

  const downPress = useSearchAutoSuggestKeyPress(keys.DOWN);
  const upPress = useSearchAutoSuggestKeyPress(keys.UP);
  const enterPress = useSearchAutoSuggestKeyPress(keys.ENTER);

  const {
    data: productData,
    loading: productDataLoading,
    refetch: productRefetch,
  } = useAutoSuggestProductQuery(debouncedSearchValue);

  const {
    data: nonProductData,
    loading: nonProductDataLoading,
    refetch: nonProductRefetch,
  } = useAutoSuggestNonProductQuery(debouncedSearchValue);

  useEffect(() => {
    if (!nonProductDataLoading && !productDataLoading) {
      const nonProducts = (
        nonProductData?.srl?.fullTextSearch?.autoSuggest?.items || []
      ).map((item, index) => {
        return {
          index,
          item,
          type: 'nonProduct',
        } as IAutoSuggestItem;
      });
      const products = (
        productData?.srl?.fullTextSearch?.autoSuggest?.items || []
      ).map((item, index) => {
        return {
          index: nonProducts.length + index,
          item,
          type: 'product',
        } as IAutoSuggestItem;
      });
      setActive(null);
      setAutoSuggestList([].concat(nonProducts, products));
    }
  }, [
    nonProductData?.srl?.fullTextSearch?.autoSuggest?.items,
    nonProductDataLoading,
    productData?.srl?.fullTextSearch?.autoSuggest?.items,
    productDataLoading,
  ]);

  useEffect(() => {
    if (autoSuggestList.length) {
      onChange({ count: autoSuggestList.length, activeItem: active });
    } else {
      onChange({ count: 0, activeItem: null });
    }
  }, [autoSuggestList]);

  useEffect(() => {
    nonProductRefetch();
    productRefetch();
  }, [exactSearch]);

  useEffect(() => {
    if (downPress) {
      setActive(getNextAutoSuggestItem(active, autoSuggestList));
    }
  }, [downPress, autoSuggestList]);

  useEffect(() => {
    if (upPress) {
      setActive(getPrevAutoSuggestItem(active, autoSuggestList));
    }
  }, [upPress, autoSuggestList]);

  useEffect(() => {
    if (onSelect && enterPress && active) {
      onSelect(active);
    }
  }, [active, enterPress]);

  if (!autoSuggestList.length) {
    return null;
  }

  const nonProducts = autoSuggestList.filter(i => {
    return i.type === 'nonProduct';
  });
  const products = autoSuggestList.filter(i => {
    return i.type === 'product';
  });

  return (
    <SearchAutoSuggestContent
      flex={desktop && products.length > 0 && nonProducts.length > 0}
      showError={showError}
    >
      {nonProducts.length > 0 && (
        <NonProductColumn
          flex={desktop && products.length > 0}
          className="non-product"
        >
          <Header>{t('searchAutoSuggest.nonProducts.title')}</Header>
          {nonProducts.map((item, index) => {
            return (
              <NonProductLink
                key={index}
                href={'#'}
                active={active && active.item.link.uri === item.item.link.uri}
                onMouseOver={() => {
                  setActive(item);
                  onChange({
                    count: autoSuggestList.length,
                    activeItem: active,
                  });
                }}
                onMouseOut={() => {
                  setActive(null);
                  onChange({ count: autoSuggestList.length, activeItem: null });
                }}
                onClick={e => {
                  e.preventDefault();
                  onSelect(item);
                }}
              >
                <SearchAutoSuggestName
                  name={item.item.name}
                  searchValue={debouncedSearchValue}
                />
              </NonProductLink>
            );
          })}
        </NonProductColumn>
      )}
      {products.length > 0 && (
        <ProductColumn
          flex={desktop && nonProducts.length > 0}
          className="product"
        >
          <ProductHeaderContainer>
            <ProductHeader>
              {channelType !== AuthChannelType.B2B
                ? t('searchAutoSuggest.products.title')
                : t('searchAutoSuggest.products.title.b2b')}
            </ProductHeader>
          </ProductHeaderContainer>
          {products.map((item, index) => {
            return (
              <ProductLink
                key={index}
                href={'#'}
                active={active && active.item.link.uri === item.item.link.uri}
                onMouseOver={() => {
                  setActive(item);
                  onChange({
                    count: autoSuggestList.length,
                    activeItem: active,
                  });
                }}
                onMouseOut={() => {
                  setActive(null);
                  onChange({ count: autoSuggestList.length, activeItem: null });
                }}
                onClick={e => {
                  e.preventDefault();
                  onSelect(item);
                }}
              >
                <ProductImageContainer>
                  <ProductImage {...item.item.image} />
                </ProductImageContainer>
                <ProductContent>{item.item.name}</ProductContent>
              </ProductLink>
            );
          })}
          {onShowAll && (
            <ProductShowAllLinkContainer>
              <AllItemsButton
                onClick={event => {
                  event.preventDefault();
                  onShowAll();
                }}
              >
                {t('searchAutoSuggest.showAll')}
              </AllItemsButton>
            </ProductShowAllLinkContainer>
          )}
        </ProductColumn>
      )}
    </SearchAutoSuggestContent>
  );
};

export default SearchAutoSuggest;
