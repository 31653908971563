import styled from 'styled-components';

export const BoosterList = styled.div(({ theme: { space, media } }) => ({
  zIndex: 9,
  left: '50%',
  width: '100%',
  bottom: space[3],
  position: 'fixed',
  transform: 'translateX(-50%)',
  maxWidth: 'calc(100% - 48px)',
  [media.tablet]: {
    bottom: space[4],
    maxWidth: '1170px',
  },
}));
