import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { SelectInput } from '@hotelplan/components.common.select-input';
import { BookmarkGroup } from '@hotelplan/platform-graphql-api';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentRow,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
} from './BookmarkPopup.styles';

interface IBookmarkEditDefaultContent {
  bookmarkGroups: BookmarkGroup[];
  onMoveBookmark(bookmarkGroup: BookmarkGroup): void;
  onCopyBookmark(bookmarkGroup: BookmarkGroup): void;
  onRemoveButtonClick(): void;
  onCloseButtonClick(): void;
}

const IconColumn = styled.div(({ theme: { space } }) => ({
  width: space[4],
}));
const ContentColumn = styled.div(({ theme: { space } }) => ({
  flexGrow: 1,
  marginLeft: space[4],
  button: {
    width: '100%',
    margin: 0,
  },
}));

const BookmarkEditDefaultPopup: React.FC<IBookmarkEditDefaultContent> = ({
  bookmarkGroups,
  onCopyBookmark,
  onMoveBookmark,
  onRemoveButtonClick,
  onCloseButtonClick,
}) => {
  const [t] = useTranslation(['common']);
  const [moveGroupId, setMoveGroupId] = useState('');
  const [copyGroupId, setCopyGroupId] = useState('');

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.edit.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            onCloseButtonClick();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupContentRow>
          <IconColumn>
            <Icon name="delete" className="bookmark-delete-icon" />
          </IconColumn>
          <ContentColumn>
            <Button variant="tagBtn" onClick={onRemoveButtonClick}>
              {t('bookmark.edit.remove')}
            </Button>
          </ContentColumn>
        </BookmarkPopupContentRow>
        <BookmarkPopupContentRow>
          <IconColumn>
            <Icon name="move-right" className="bookmark-move-icon" />
          </IconColumn>
          <ContentColumn>
            <SelectInput
              options={[
                {
                  value: '',
                  label: t('bookmark.edit.move'),
                  disabled: true,
                },
                ...bookmarkGroups.map(i => ({
                  value: i.groupId,
                  label: i.name,
                })),
              ]}
              value={moveGroupId}
              onChange={value => {
                const bookmarkGroup = bookmarkGroups.find(
                  i => i.groupId === value
                );

                if (bookmarkGroup) {
                  setMoveGroupId(value);
                  onMoveBookmark(bookmarkGroup);
                }
              }}
            />
          </ContentColumn>
        </BookmarkPopupContentRow>
        <BookmarkPopupContentRow>
          <IconColumn>
            <Icon name="copy" className="bookmark-copy-icon" />
          </IconColumn>
          <ContentColumn>
            <SelectInput
              options={[
                {
                  value: '',
                  label: t('bookmark.edit.copy'),
                  disabled: true,
                },
                ...bookmarkGroups.map(i => ({
                  value: i.groupId,
                  label: i.name,
                })),
              ]}
              value={copyGroupId}
              onChange={value => {
                const bookmarkGroup = bookmarkGroups.find(
                  i => i.groupId === value
                );

                if (bookmarkGroup) {
                  setCopyGroupId(value);
                  onCopyBookmark(bookmarkGroup);
                }
              }}
            />
          </ContentColumn>
        </BookmarkPopupContentRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkEditDefaultPopup;
