import React, { ReactNode, useContext, useState } from 'react';

type ExactSearchType = boolean;
type ExactSearchTypeSetter = (isExactSearch: boolean) => void;

type ExactSearchContextType = [ExactSearchType, ExactSearchTypeSetter] | null;

const ExactSearchContext = React.createContext<ExactSearchContextType>(null);

export function ExactSearchContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const exactSearchContextState = useState<boolean>(true);

  return (
    <ExactSearchContext.Provider value={exactSearchContextState}>
      {children}
    </ExactSearchContext.Provider>
  );
}

export function useExactSearchContext() {
  return useContext(ExactSearchContext);
}
