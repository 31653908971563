import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { RequestButton } from 'components/candidates/request-button';
import { useGetLinkWithJotform } from 'components/domain/jotform/jotformGclid.utils';
import {
  trackRequestFormClick,
  ClickEventSourceType,
} from 'components/domain/tracking/tracking.utils';
import { useGetFloatingRequestButtonQuery } from 'graphql/requestButton/GetFloatingRequestButton.generated';

const HeaderButtonWrapper = styled.div(
  sx2CssThemeFn({
    height: '42px',
    '.request-button': {
      color: 'white',
      borderRadius: '5px',
      height: '100%',
      marginLeft: '5px',
      width: '177px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '.request-button-caption': {
        color: 'white',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
  })
);

const getId = (pageType: PageType, id?: string): string | undefined => {
  switch (pageType) {
    case PageType.GeoObject:
    case PageType.Pdp:
    case PageType.ThemeObject:
    case PageType.BlogArticle:
    case PageType.Static:
    case PageType.CombinedThemeGeo:
      return id;

    case PageType.Home:
      return '/';

    default:
      return;
  }
};

export default function HeaderRequestButton({
  pageType,
}: PropsWithChildren<{ pageType: PageType; id?: string }>): ReactElement {
  const [t] = useTranslation('common');
  const { id: oid } = useObjectId();
  const router = useRouter();

  const id = getId(pageType, oid);

  const { data: requestButtonData } = useGetFloatingRequestButtonQuery({
    variables: { pageType, id, currentUrl: router.asPath },
  });

  const { caption, link } = requestButtonData?.requestButton || {};

  const linkWithJotform = useGetLinkWithJotform(link);

  return (
    <>
      <HeaderButtonWrapper>
        <RequestButton
          caption={caption || t('common:requestButton.floating.default')}
          link={linkWithJotform}
          onClick={() => {
            if (!linkWithJotform) return;
            trackRequestFormClick(ClickEventSourceType.MENU);
          }}
        />
      </HeaderButtonWrapper>
    </>
  );
}
