import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Button } from '@hotelplan/components.common.buttons';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { Status } from '@hotelplan/platform-graphql-api';
import {
  TEditBookmarkGroupModalPopup,
  useBookmarkModalPopupContext,
} from 'components/domain/bookmark/BookmarkModalPopupContext';
import SearchResetButton from 'components/domain/search/SearchResetButton';
import {
  BookmarkPopup,
  BookmarkPopupButtonRow,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentRow,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextInputContainer,
} from './BookmarkPopup.styles';
import { useRenameBookmarkGroup } from './useRenameBookmarkGroup';

interface IBookmarkGroupEditFormState {
  groupName: string;
  groupId: string;
}

const BookmarkGroupEditFormValidationSchema = Yup.object<IBookmarkGroupEditFormState>(
  {
    groupName: Yup.string().required('forms:errors.required'),
    groupId: Yup.string().required('forms:errors.required'),
  }
);

const BookmarkGroupEditModal: React.FC = () => {
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common']);
  const modal = bookmarkModalContext.modal as TEditBookmarkGroupModalPopup;
  const inputRef = useRef<HTMLInputElement>();
  const groupInputName = 'groupName';
  const { mobile } = useDeviceType();
  const { renameBookmarkGroup } = useRenameBookmarkGroup();

  async function onSubmit(values: IBookmarkGroupEditFormState) {
    const { data } = await renameBookmarkGroup(
      values.groupId,
      values.groupName
    );

    if (data?.renameBookmarkGroup?.status === Status.Success) {
      bookmarkModalContext.close();
    }
  }

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.group.edit.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            bookmarkModalContext.close();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <Formik<IBookmarkGroupEditFormState>
          initialValues={{
            groupName: modal.payload.name,
            groupId: modal.payload.groupId,
          }}
          onSubmit={onSubmit}
          validationSchema={BookmarkGroupEditFormValidationSchema}
        >
          {formik => {
            return (
              <form
                noValidate
                onSubmit={(e: unknown) =>
                  formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
                }
              >
                <BookmarkPopupContentRow>
                  <BookmarkPopupTextInputContainer>
                    <ValidatedTextInput
                      required
                      name={groupInputName}
                      className="text-input"
                      showErrorMessage={false}
                      hideLabel={true}
                      inputRef={inputRef}
                    />
                    {formik.values.groupName?.length > 0 && (
                      <SearchResetButton
                        type="reset"
                        variant="iconBtn"
                        icon={{
                          name: 'close-icon',
                          size: mobile ? 'sm' : 'md',
                        }}
                        onClick={() => {
                          formik.setFieldValue(groupInputName, '');
                          inputRef.current?.focus();
                        }}
                      >
                        {t('common:close')}
                      </SearchResetButton>
                    )}
                  </BookmarkPopupTextInputContainer>
                  <BaseInput
                    name="groupId"
                    type="hidden"
                    value={modal.payload.groupId}
                  />
                </BookmarkPopupContentRow>
                <BookmarkPopupButtonRow>
                  <Button
                    variant="tagBtn"
                    onClick={e => {
                      e.preventDefault();
                      bookmarkModalContext.close();
                    }}
                  >
                    {t('bookmark.group.edit.cancel')}
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    className="form-submit-button"
                    type="submit"
                  >
                    {t('bookmark.group.edit.apply')}
                  </Button>
                </BookmarkPopupButtonRow>
              </form>
            );
          }}
        </Formik>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkGroupEditModal;
