import { PageType, Status } from '@hotelplan/platform-graphql-api';
import { useCreateBookmarkGroupMutation } from 'graphql/bookmark/CreateBookmarkGroup.generated';
import { TBookmarkInput } from './Bookmark.types';
import { useAddBookmarkToBookmarkGroup } from './useAddBookmarkToBookmarkGroup';

function useAddBookmarkItemToNewGroup(): {
  addBookmarkItemToNewGroup(
    pageType: PageType,
    objectId: string,
    groupName: string
  ): Promise<TBookmarkInput | undefined>;
} {
  const [createBookmarkMutation] = useCreateBookmarkGroupMutation();
  const { addBookmarkToBookmarkGroup } = useAddBookmarkToBookmarkGroup();

  async function addBookmarkItemToNewGroup(
    pageType: PageType,
    objectId: string,
    groupName: string
  ) {
    const { data: createBookmarkData } = await createBookmarkMutation({
      variables: { groupName },
    });

    const groupId = createBookmarkData?.createBookmarkGroup?.groupId || '';

    if (!groupId.length) {
      return undefined;
    }

    const { data: addBookmarkData } = await addBookmarkToBookmarkGroup(
      pageType,
      objectId,
      groupId
    );

    return addBookmarkData?.addBookmark?.status === Status.Success
      ? { objectId, pageType, groupId }
      : undefined;
  }

  return { addBookmarkItemToNewGroup };
}

export default useAddBookmarkItemToNewGroup;
