import { useDeleteBookmarkGroupMutation } from 'graphql/bookmark/DeleteBookmarkGroup.generated';

export function useDeleteBookmarkGroup() {
  const [mutation, { data, loading }] = useDeleteBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['GetBookmarkGroups'],
  });

  async function deleteBookmarkGroup(groupId: string) {
    return mutation({
      variables: {
        groupId,
      },
    });
  }

  return {
    deleteBookmarkGroup,
    data,
    loading,
  };
}
