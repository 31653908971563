import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Link } from '@hotelplan/components.common.link';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { PageType } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useBookmarkContext } from 'components/domain/bookmark/BookmarkContext';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

export const WishlistIconCounter = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: [null, 'column'],
    alignItems: 'center',
    fontSize: '0px',
    color: 'lightGreySecond',
    fontWeight: 'bold',
    '.icon': {
      width: '20px',
      height: '18px',
      marginTop: '50%',
      transform: 'translateY(-50%)',
      marginBottom: 3,
      color: 'redWeb',
      fill: 'transparent',
    },
    '.counter': {
      color: 'black',
      fontSize: '10px',
      fontWeight: 'normal',
      lineHeight: '11.5px',
      textAlign: 'left',
      position: 'absolute',
      top: '52%',
      right: '1px',
      transform: 'translateY(-50%)',
      letterSpacing: '0px',
      width: '19px',
      backgroundColor: 'paperGrey',
      padding: '0 1px',
    },
  })
);

const WishlistSiteTool: React.FC = () => {
  const [t, { language }] = useTranslation('bookmark');
  const bookmarkContext = useBookmarkContext();

  const routerProps = useMemo(() => {
    return getRouteByPageTypeLocale(PageType.Bookmark, language);
  }, [language]);

  return (
    <Link href={routerProps.match}>
      <SiteToolsButtonsWrapper className="wishlistSiteTool">
        <WishlistIconCounter>
          <Button variant="headerBtn" icon={{ name: 'wishlist-icon' }} />
          {bookmarkContext.count > 0 && (
            <span className="counter">{bookmarkContext.count}</span>
          )}
          <span className="tooltip">{t('bookmarkLink')}</span>
        </WishlistIconCounter>
      </SiteToolsButtonsWrapper>
    </Link>
  );
};

export default WishlistSiteTool;
