import React from 'react';
import styled from 'styled-components';
import BoostersBanner from './BoostersBanner';
import CookiesDisclaimerBanner from './CookiesDisclaimerBanner';
import InformationNotificationsBanner from './InformationNotificationsBanner';
import ScrollToTopButton from './ScrollToTopButton';

const NotificationWrap = styled.div(({ theme: { media, space } }) => ({
  position: 'fixed',
  bottom: '76px',
  [media.tablet]: {
    bottom: space[0],
  },
  left: '0',
  zIndex: '101',
  width: '100%',
}));

const MetaModules = () => {
  return (
    <>
      <NotificationWrap>
        <InformationNotificationsBanner />
        <CookiesDisclaimerBanner />
      </NotificationWrap>
      <ScrollToTopButton />
      <BoostersBanner />
    </>
  );
};

export default MetaModules;
