import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { Status } from '@hotelplan/platform-graphql-api';
import {
  TRemoveBookmarkGroupModalPopup,
  useBookmarkModalPopupContext,
} from 'components/domain/bookmark/BookmarkModalPopupContext';
import {
  BookmarkPopup,
  BookmarkPopupButtonRow,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './BookmarkPopup.styles';
import { useDeleteBookmarkGroup } from './useDeleteBookmarkGroup';

const BookmarkGroupRemoveModal: React.FC = () => {
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common']);
  const modal = bookmarkModalContext.modal as TRemoveBookmarkGroupModalPopup;
  const { deleteBookmarkGroup, loading } = useDeleteBookmarkGroup();

  async function onClick() {
    if (!loading) {
      const { data } = await deleteBookmarkGroup(modal.payload.groupId);

      if (data?.deleteBookmarkGroup?.status === Status.Success) {
        bookmarkModalContext.close();
      }
    }
  }

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.group.remove.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            bookmarkModalContext.close();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupTextRow>
          {t('bookmark.group.remove.text', { name: modal.payload.name })}
        </BookmarkPopupTextRow>
        <BookmarkPopupButtonRow>
          <Button
            variant="tagBtn"
            onClick={e => {
              e.preventDefault();
              bookmarkModalContext.close();
            }}
          >
            {t('bookmark.group.remove.cancel')}
          </Button>
          <Button
            disabled={loading}
            className="form-submit-button"
            onClick={onClick}
          >
            {t('bookmark.group.remove.apply')}
          </Button>
        </BookmarkPopupButtonRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkGroupRemoveModal;
