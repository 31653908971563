import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetCookieDisclaimerQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetCookieDisclaimerQuery = {
  __typename?: 'Query';
  cookieDisclaimer: { __typename?: 'CookieDisclaimer'; text?: string | null };
};

export const GetCookieDisclaimerDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCookieDisclaimer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cookieDisclaimer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetCookieDisclaimerQuery__
 *
 * To run a query within a React component, call `useGetCookieDisclaimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCookieDisclaimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCookieDisclaimerQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetCookieDisclaimerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCookieDisclaimerQuery,
    GetCookieDisclaimerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCookieDisclaimerQuery,
    GetCookieDisclaimerQueryVariables
  >(GetCookieDisclaimerDocument, options);
}
export function useGetCookieDisclaimerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCookieDisclaimerQuery,
    GetCookieDisclaimerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCookieDisclaimerQuery,
    GetCookieDisclaimerQueryVariables
  >(GetCookieDisclaimerDocument, options);
}
export type GetCookieDisclaimerQueryHookResult = ReturnType<
  typeof useGetCookieDisclaimerQuery
>;
export type GetCookieDisclaimerLazyQueryHookResult = ReturnType<
  typeof useGetCookieDisclaimerLazyQuery
>;
export type GetCookieDisclaimerQueryResult = Apollo.QueryResult<
  GetCookieDisclaimerQuery,
  GetCookieDisclaimerQueryVariables
>;
