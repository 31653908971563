import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

export const LogoutIconHolder = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: [null, 'column'],
    alignItems: 'center',
    fontSize: '0px',
    color: 'lightGreySecond',
    fontWeight: 'bold',
    marginRight: '8px',
    '.icon': {
      width: '18px',
      height: '18px',
      my: '0',
      transform: 'none',
      color: 'redWeb',
      fill: 'transparent',
    },
  })
);

const MobileLogoutMenuItem: React.FC = () => {
  const [t] = useTranslation('auth');
  const { logout } = useAuthentication();

  const logoutHandler = useCallback(async () => {
    await logout();
    window.location.reload();
  }, [logout]);

  return (
    <Link onClick={logoutHandler}>
      <SiteToolsButtonsWrapper className="logoutSiteTool">
        <LogoutIconHolder>
          <Icon name="logout-b2b-v2" />
        </LogoutIconHolder>
        <span className="icon-label">{t('b2b.logout')}</span>
      </SiteToolsButtonsWrapper>
    </Link>
  );
};

export default MobileLogoutMenuItem;
