import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const TypeFlag = styled.span(
  sx2CssThemeFn({
    display: 'block',
    marginRight: '8px',
    borderRadius: '5px',
    backgroundColor: 'paperGrey',
    padding: '4px 8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis' as const,
    overflow: 'hidden',
  })
);

const TypeFlagItem: FC = ({ children }): ReactElement => {
  return <TypeFlag>{children}</TypeFlag>;
};

export default TypeFlagItem;
