import { useExactSearchContext } from 'components/domain/exact-search/ExactSearchContext';
import useGetFulltextSearchLink from './useGetFulltextSearchLink';

const useGetFullTextSearchLinkWithSection = () => {
  const {
    requestLink: requestFullSearchLink,
    setSectionKeys,
  } = useGetFulltextSearchLink();
  const [exactSearch] = useExactSearchContext();

  const requestLink = (keywordsInput: string[], sectionKeys: string[]) => {
    setSectionKeys(sectionKeys);
    requestFullSearchLink({
      variables: { keywords: keywordsInput, exactSearch },
    });
  };

  return {
    requestLink,
  };
};

export default useGetFullTextSearchLinkWithSection;
