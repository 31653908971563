/* eslint-disable @typescript-eslint/no-unused-vars,i18next/no-literal-string */
import {
  FieldMergeFunction,
  FieldPolicy,
  TypePolicies,
  TypePolicy,
} from '@apollo/client';
import {
  AgencyAutocomplete,
  AutocompleteComponent,
  BlogArticles,
  HistoryUserResponse,
  SrlFullTextSearchResult,
  GeoSiblingsComponent,
} from '@hotelplan/graphql.types';
import { PageFragment } from 'graphql/fragments/Page.generated';
import { KeyArgsReducer } from './apolloTypePolicies.types';

function mergePage(
  existing: PageFragment | undefined,
  incoming: PageFragment,
  itemsCount: number
): PageFragment {
  const perPageChanged = existing?.resultsPerPage !== incoming.resultsPerPage;
  const countedPages = Math.floor((itemsCount - 1) / incoming.resultsPerPage);
  const pageNumber = perPageChanged
    ? Math.max(incoming.pageNumber, countedPages)
    : Math.max(incoming.pageNumber, existing?.pageNumber);

  const resultsTotal = perPageChanged
    ? incoming.resultsTotal
    : Math.max(incoming.resultsTotal, existing?.resultsTotal);

  return { ...incoming, pageNumber, resultsTotal };
}

function mergePaginatedItems<TItem>(
  incomingPage: PageFragment,
  existingPage: PageFragment,
  existingItems: TItem[],
  incomingItems: TItem[]
): TItem[] {
  const { pageNumber, resultsPerPage } = incomingPage;

  const resultsTotal = Math.max(
    incomingPage.resultsTotal,
    existingPage?.resultsTotal || 0
  );

  if (!resultsTotal) return [];

  const storedItemsCount = existingItems.length;
  const firstIncomingItemIndex = pageNumber * resultsPerPage;

  const storedElements = existingItems.slice(0, firstIncomingItemIndex);

  const emptyElements = Array.from({
    length: firstIncomingItemIndex - storedItemsCount,
  }).map(() => null) as Array<TItem>;

  const postElements = existingItems.slice(
    firstIncomingItemIndex + incomingItems.length
  );
  const toIndex =
    resultsTotal === existingItems.length
      ? postElements.length
      : postElements.length - (postElements.length % resultsPerPage);

  return [
    ...storedElements,
    ...emptyElements,
    ...incomingItems,
    ...postElements.slice(0, toIndex),
  ].slice(0, resultsTotal);
}

const possibleTypeNames = [
  `AccountContext`,
  `AccountLoginPage`,
  `AccountMutationResponse`,
  `AccountMyProfilePage`,
  `AccountPage`,
  `AccountPersonalDataResponse`,
  `AddressDetails`,
  `AddTravelCompanionResponse`,
  `Agency`,
  `AgencyAppointmentResponse`,
  `AgencyAutocomplete`,
  `AgencyContact`,
  `AgencyContactPhone`,
  `AgencyOverviewPageContext`,
  `AgencyRecommendationGroup`,
  `Airline`,
  `Airport`,
  `AuthMethod`,
  `AuthMethodListResponse`,
  `AutocompleteComponent`,
  `BD4TravelRecommendation`,
  `BD4TravelRecommendationInfo`,
  `BD4TravelRecommendationTracking`,
  `BlogArticleRecommendationItem`,
  `BlogOverview`,
  `BoardType`,
  `BookingBoardType`,
  `BookingDetails`,
  `BookingDetailsResponse`,
  `BookingExtraService`,
  `BookingHotelRoom`,
  `BookingItem`,
  `BookingResponse`,
  `BookingRoomType`,
  `BookingTransfer`,
  `Booster`,
  `BrandBox`,
  `BrandsComponent`,
  `Breadcrumb`,
  `BreadcrumbsComponent`,
  `BusinessUnit`,
  `Catalog`,
  `CatalogOrderResponse`,
  `CatalogOverviewContext`,
  `CheckboxFilterComponent`,
  `ClimateChart`,
  `ClimateChartEntry`,
  `ClimateComponent`,
  `ContactForm`,
  `ContactFormResponse`,
  `ContactInformation`,
  `ContactPageContext`,
  `ContactRecommendationGroup`,
  `CookieDisclaimer`,
  `DeleteHistoryResponse`,
  `DynamicComponent`,
  `DoubleRangeSliderFilterComponent`,
  `EmailAdvertisingGetEmailFromTokenResponse`,
  `EmailAdvertisingGetTokenFromEmailResponse`,
  `EmailAdvertisingRecommendationGroup`,
  `EmailAdvertisingRevocationConfirmationPageContext`,
  `EmailAdvertisingRevocationPageContext`,
  `EmailAdvertisingRevocationResponse`,
  `EmailAdvertisingStaticContent`,
  `EmployeeBox`,
  `EmployeesComponent`,
  `EmployeeSocialProfile`,
  `ExactTravelPeriod`,
  `ExternalLink`,
  `ExternalMediaItem`,
  `FaqComponent`,
  `FilterComponent`,
  `FilterItem`,
  `FlexibleTravelPeriod`,
  `Flight`,
  `FlightBaggageInfo`,
  `FlightCheckoutComponent`,
  `FlightDestinationInfo`,
  `FlightHistoryResponse`,
  `FlightHomeContext`,
  `FlightHomeRecommendationGroup`,
  `FlightOffer`,
  `FlightPartition`,
  `FlightRecommendationWithoutPriceItem`,
  `FlightRecommendationWithPriceItem`,
  `FlightSearchControlComponent`,
  `FlightSegment`,
  `FlightSrlComponent`,
  `FlightSrlContainer`,
  `FlightSrlContext`,
  `FlightSrlRecommendationGroup`,
  `FlightStopOverDuration`,
  `GeoChildComponent`,
  `GeoChildrenComponent`,
  `GeoContext`,
  `GeoCoordinates`,
  `GeoDefaultGeoRecommendationsComponent`,
  `GeoFeature`,
  `GeoFeatureGroup`,
  `GeoInfoComponent`,
  `GeoLocation`,
  `GeoObject`,
  `GeoObjectRecommendationGroupComponent`,
  `GeoOverviewChildComponent`,
  `GeoOverviewContext`,
  `GeoOverviewRecommendationGroupComponent`,
  `GeoRecommendationItem`,
  `GeoSiblingsComponent`,
  `GroupOrlItem`,
  `HelpOverlayBox`,
  `HelpOverlayBoxChat`,
  `HelpOverlayBoxContact`,
  `HelpOverlayData`,
  `HeroMediaGallery`,
  `HistoryContext`,
  `HistoryFlightRecord`,
  `HistoryProductRecord`,
  `HistorySrlRecord`,
  `HistoryUserRecord`,
  `HistoryUserResponse`,
  `HolidayFinderInfo`,
  `HolidayFinderLandingPage`,
  `HolidayFinderOffer`,
  `HolidayFinderPage`,
  `HolidayFinderPageContext`,
  `HolidayFinderProduct`,
  `HolidayFinderTracking`,
  `HolidayFinderVotingResponse`,
  `HomeContext`,
  `HomeRecommendationGroup`,
  `HomeStaticContent`,
  `HomeTitle`,
  `HotelDestinationInfo`,
  `IconMenuItem`,
  `Image`,
  `ImageMediaItem`,
  `InternalLink`,
  `LinkListComponent`,
  `LinkListItem`,
  `MapSuggestion`,
  `MarketingRecommendationItem`,
  `MediaGallery`,
  `Menu`,
  `MusicMediaItem`,
  `Mutation`,
  `NewsArticle`,
  `NewsArticlePage`,
  `NewsArticlesComponent`,
  `NewsArticlesFilter`,
  `NewsArticlesOverview`,
  `NewsArticlesOverviewPage`,
  `NewsletterConfirmationContext`,
  `NewsletterFinalizationContext`,
  `NewsletterGetEmailFromTokenResponse`,
  `NewsletterGetTokenFromEmailResponse`,
  `NewsletterRecommendationGroup`,
  `NewsletterSubscription`,
  `NewsletterSubscriptionContext`,
  `NewsletterSubscriptionResponse`,
  `NewsletterUnsubscriptionContext`,
  `NewsletterUnsubscriptionFinalizationContext`,
  `NewsletterUnsubscriptionResponse`,
  `Notification`,
  `NotificationInfo`,
  `NudgeItem`,
  `OpeningHours`,
  `OrlCheckoutComponent`,
  `OrlContext`,
  `OrlFlightAlternative`,
  `OrlGroupListComponent`,
  `OrlHistoryResponse`,
  `OrlIncludedInPriceComponent`,
  `OrlPriceExplanation`,
  `OrlPriceExplanationComponent`,
  `OrlRoom`,
  `OrlSearchContainer`,
  `OrlSingleListComponent`,
  `Page`,
  `PageB2BLoginData`,
  `PageFooterData`,
  `PageHeaderData`,
  `PageMetaData`,
  `PageNotFound404Data`,
  `PdfMediaItem`,
  `PDOItem`,
  `PdpContainer`,
  `PdpContext`,
  `PdpDescriptionComponent`,
  `PdpDestinationInfoComponent`,
  `PdpFeatureRating`,
  'PdpInformationGroup',
  `PdpMapComponent`,
  `PdpMapHotel`,
  `PdpMoreOffersButton`,
  `PdpOverviewComponent`,
  `PdpPriceDateOverviewComponent`,
  `PdpRecommendationGroup`,
  `PdpTripAdvisorComponent`,
  `PhoneDetails`,
  `Price`,
  `ProductFeature`,
  `ProductFeatureGroup`,
  `ProductRecommendationItem`,
  `Query`,
  `RadiobuttonFilterComponent`,
  `ReasonsOfConfidence`,
  `ResizedImage`,
  `ResortTopHotelsComponent`,
  `RoomType`,
  `SearchControlComponent`,
  `Shift`,
  `SingleOrlItem`,
  `SingleValueFilterComponent`,
  `SliderFilterComponent`,
  `SrlContext`,
  `SrlEmptyResult`,
  `SrlGeoGroupItem`,
  `SrlGeoGroupResult`,
  `SrlGeoItem`,
  `SrlGhostOfferItem`,
  `SrlGhostOffersComponent`,
  `SrlFullTextSearchResultComponent`,
  `SrlHistoryResponse`,
  `SrlMapGeoPin`,
  `SrlMapPinsComponent`,
  `SrlMapProductPin`,
  `SrlProductItem`,
  `SrlRecommendationGroupComponent`,
  `SrlResultContext`,
  `SrlSearchControlsContext`,
  `SrlSingleResult`,
  `SrlSortComponent`,
  `SrlSubGeoFilterComponent`,
  `SrlSubGeoItem`,
  `StaticContext`,
  `StaticRecommendationGroup`,
  `TextComponent`,
  `TextMenuItem`,
  `ThemeContext`,
  `ThemeOverviewContext`,
  `ThemeOverviewPage`,
  `ThemePageRecommendationGroup`,
  `ThemePreviewComponent`,
  `ThemeRecommendationItem`,
  `ThemeStaticContent`,
  `TransferDate`,
  `TransferDetailInfo`,
  `TransferFlightInfo`,
  `TransferHotelInfo`,
  `TransferInfo`,
  `TravelCompanion`,
  `TravelComponentResponse`,
  `TravelDestination`,
  `Traveller`,
  `TravellerInfo`,
  `Travellers`,
  `TravelPeriodComponent`,
  `TripAdvisorRating`,
  `TripAdvisorReview`,
  `TripAdvisorSubrating`,
  `UserFinalizationResponse`,
  `UserParamsFromTokenResponse`,
  `UserRegistrationResponse`,
  `UspBox`,
  `UspBoxesComponent`,
  `VideoMediaItem`,
  `WaitingScreen`,
  `Wishlist`,
  `WishlistActiveComponent`,
  `WishlistAddMultipleToWishlistResponse`,
  `WishlistAddToWishlistResponse`,
  `WishlistContext`,
  `WishlistMutationResponse`,
  `WishlistOffer`,
  `WishlistOfferRoom`,
  `WishlistOverviewComponent`,
  `WishlistProduct`,
  `WishlistProductItem`,
  `StaticProductRecommendation`,
  `StaticGeoRecommendation`,
  `StaticThemeRecommendation`,
  `MarketingRecommendation`,
  'BookmarkPage',

  // `HeroMediaGallery`,
  // `MediaGallery`,
  // `TextComponent`,
  // `TextComponent`,
  // `UspBoxesComponent`,
  // `FaqComponent`,
  // `TextComponent`,
  // `ContactModuleComponent`,
  // `ProductGalleryWithMapComponent`,
] as const;

type TypeNames = typeof possibleTypeNames[number];

// add default behaviour to all types
const possibleTypePolicies: Record<
  TypeNames,
  TypePolicy
> = possibleTypeNames.reduce(
  (policies, policyType) => ({
    ...policies,
    [policyType]: { keyFields: false, merge: true },
  }),
  {} as any
);

const addAllArgsReducer: KeyArgsReducer = (res, args) => {
  return [...res, ...Object.keys(args)].filter((v, i, a) => a.indexOf(v) === i);
};

const excludeContextArgReducer: KeyArgsReducer = res => {
  return res.filter(key => key !== 'context' && key !== 'requestContext');
};

const buildFieldPolicy = (reducers: KeyArgsReducer[]): FieldPolicy => ({
  keyArgs: (args, ctx) => {
    const k = reducers.reduce((keys, reducer) => {
      return reducer(keys, args, ctx);
    }, []);
    // console.log(k);  todo: remove
    return k;
  },
});

const defaultFieldPolicy = buildFieldPolicy([
  addAllArgsReducer,
  excludeContextArgReducer,
]);

function assign<K extends TypeNames>(typeName: K, policy: TypePolicy) {
  Object.assign(
    (possibleTypePolicies[typeName] = possibleTypePolicies[typeName] || {}),
    policy
  );
}

assign('Query', {
  // merge: (existing, incoming, options) => {
  //   return options.mergeObjects(existing, incoming);
  // },
  fields: {
    account: defaultFieldPolicy,
    agency: defaultFieldPolicy,
    agencyContact: defaultFieldPolicy,
    agencyOverview: defaultFieldPolicy,
    boosters: defaultFieldPolicy,
    catalogOverview: defaultFieldPolicy,
    contact: defaultFieldPolicy,
    components: { keyArgs: ['input'] },
    cookieDisclaimer: defaultFieldPolicy,
    emailAdvertisingRevocation: defaultFieldPolicy,
    emailAdvertisingRevocationConfirmation: defaultFieldPolicy,
    flightHome: defaultFieldPolicy,
    flightSrl: defaultFieldPolicy,
    flightWaitingScreen: defaultFieldPolicy,
    geo: { keyArgs: ['id'] },
    geoOverview: defaultFieldPolicy,
    helpOverlayData: defaultFieldPolicy,
    history: { keyArgs: false },
    holidayFinder: defaultFieldPolicy,
    home: defaultFieldPolicy,
    newsletterConfirmation: defaultFieldPolicy,
    newsletterSubscription: defaultFieldPolicy,
    newsletterFinalization: defaultFieldPolicy,
    newsletterUnsubscription: defaultFieldPolicy,
    newsletterUnsubscriptionFinalization: defaultFieldPolicy,
    notificationData: defaultFieldPolicy,
    notificationInfoData: defaultFieldPolicy,
    orl: defaultFieldPolicy,
    pageB2BLogin: defaultFieldPolicy,
    pageFooter: defaultFieldPolicy,
    pageHeader: defaultFieldPolicy,
    pageNotFound404: defaultFieldPolicy,
    pdp: { keyArgs: ['id'] },
    productWaitingScreen: defaultFieldPolicy,
    srl: defaultFieldPolicy,
    static: defaultFieldPolicy,
    theme: defaultFieldPolicy,
    themeGeo: {
      keyArgs: ['themeId', 'geoId'],
    },
    themeOverview: defaultFieldPolicy,
    wishlist: defaultFieldPolicy,
    bookmarkPage: defaultFieldPolicy,
  },
});

const autoCompleteMerge: FieldMergeFunction<AutocompleteComponent> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  return {
    page: incoming.page,
    destinations: existing.destinations.concat(incoming.destinations),
  };
};

assign('AccountContext', {
  fields: {
    bookingDetails: {
      keyArgs: ['bookingId'],
    },
    upcomingBookings: {
      keyArgs: ['page'],
    },
    pastBookings: {
      keyArgs: ['page'],
    },
  },
});

assign('Agency', { merge: false });

const agencyAutocompleteMerge: FieldMergeFunction<AgencyAutocomplete> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  return {
    page: incoming.page,
    status: incoming.status,
    objects: existing.objects.concat(incoming.objects),
  };
};

const historyMerge: FieldMergeFunction<HistoryUserResponse> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  const userRecords = mergePaginatedItems(
    incoming.page,
    undefined,
    existing.userRecords,
    incoming.userRecords
  );

  return {
    ...incoming,
    page: mergePage(existing.page, incoming.page, userRecords.length),
    userRecords,
  };
};

const blogArticlesMerge: FieldMergeFunction<BlogArticles> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  const articles = mergePaginatedItems(
    incoming.page,
    undefined,
    existing.articles,
    incoming.articles
  );

  return {
    ...incoming,
    page: mergePage(existing.page, incoming.page, articles.length),
    articles,
  };
};

assign('AgencyOverviewPageContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input']],
      merge: agencyAutocompleteMerge,
    },
  },
});

assign('GeoSiblingsComponent', {
  fields: {
    siblings: {
      keyArgs: false,
      merge(existing, incoming) {
        return existing ? [...existing, ...incoming] : incoming;
      },
    },
  },
});

assign('GeoContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input']],
      merge: autoCompleteMerge,
    },
  },
});

assign('HomeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input']],
      merge: autoCompleteMerge,
    },
  },
});

assign('HistoryContext', {
  fields: {
    recentFullTextSearchesWithPage: {
      keyArgs: false,
      merge: historyMerge,
    },
    recentViewedWithPage: {
      keyArgs: false,
      merge: historyMerge,
    },
  },
});

assign('BlogOverview', {
  fields: {
    articles: {
      keyArgs: false,
      merge: blogArticlesMerge,
    },
  },
});

assign('Image', { merge: false });

assign('ImageMediaItem', { merge: false });

assign('PdpContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria'],
    },
    dynamicComponents: {
      keyArgs: ['criteria'],
    },
    url: {
      keyArgs: ['targetPage', 'searchCriteria'], // todo: searchCriteria may be missing
    },
  },
});

assign('ProductFeatureGroup', { merge: false });

assign('ProductFeature', { merge: false });

assign('PdpInformationGroup', { merge: false });

assign('ResizedImage', { merge: false, keyFields: false });

assign('SrlContext', {
  fields: {
    search: {
      keyArgs: ['searchCriteria'],
    },
    searchControls: {
      merge: true,
      keyArgs: (args, ctx) => {
        const {
          encodedCriteria = 'default',
          searchCriteria,
          travelType,
        } = args;

        return args.encodedCriteria
          ? encodedCriteria
          : [searchCriteria, travelType];
      },
    },
    autocomplete: {
      keyArgs: ['criteria', ['input']],
      merge: autoCompleteMerge,
    },
    fullTextSearch: {
      merge: true,
      keyArgs: ['keywords'],
    },
  },
});

assign('SrlMapProductPin', { merge: false });

export const fulltextSearchMerge: FieldMergeFunction<SrlFullTextSearchResult> = (
  existing,
  incoming
) => {
  const items = mergePaginatedItems(
    incoming.page,
    existing?.page,
    existing?.items || [],
    incoming.items
  );

  return {
    ...incoming,
    page: mergePage(existing?.page, incoming.page, items.length),
    items,
  };
};

assign('SrlFullTextSearchResultComponent', {
  fields: {
    geo: { keyArgs: false, merge: fulltextSearchMerge },
    themes: { keyArgs: false, merge: fulltextSearchMerge },
    agenciesWithDetails: { keyArgs: false, merge: fulltextSearchMerge },
    adventuretravel: { keyArgs: false, merge: fulltextSearchMerge },
    roundtrip: { keyArgs: false, merge: fulltextSearchMerge },
    extraHotels: { keyArgs: false, merge: fulltextSearchMerge },
    regularHotels: { keyArgs: false, merge: fulltextSearchMerge },
    blogArticles: { keyArgs: false, merge: fulltextSearchMerge },
    other: { keyArgs: false, merge: fulltextSearchMerge },
  },
});

assign('SrlResultContext', {
  fields: {
    geoGroup: {
      keyArgs: ['groupId', 'page'],
    },
  },
});

assign('ThemeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input']],
      merge: autoCompleteMerge,
    },
    dynamicContent: {
      keyArgs: ['searchCriteria'],
    },
    searchControl: {
      keyArgs: ['travelType'],
    },
    link: {
      keyArgs: ['searchCriteria', 'targetPageType'],
    },
  },
});

assign('ThemeOverviewContext', {
  fields: {
    autocompleteDestinations: {
      keyArgs: ['criteria', ['input']],
      merge: autoCompleteMerge,
    },
  },
});

assign('WishlistContext', {
  fields: {
    activeOffer: {
      keyArgs: ['productId', 'searchCriteria'],
    },
    shareUrl: {
      keyArgs: ['wishlistId'],
    },
  },
});

export const apolloTypePolicies: TypePolicies = { ...possibleTypePolicies };
