import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button } from '@hotelplan/components.common.buttons';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import SearchResetButton from 'components/domain/search/SearchResetButton';
import { useGetBookmarkGroupsQuery } from 'graphql/bookmark/GetBookmarkGroups.generated';
import {
  BookmarkPopupButtonRow,
  BookmarkPopupContentRow,
  BookmarkPopupFormSubmitButton,
  BookmarkPopupTextInputContainer,
} from './BookmarkPopup.styles';

export interface IBookmarkPopupNewGroupFormState {
  name: string;
}

const BookmarkPopupNewGroupFormValidationSchema = Yup.object<IBookmarkPopupNewGroupFormState>(
  { name: Yup.string().required('forms:errors.required') }
);

const BookmarkGroupNameForm = styled.form({
  width: '100%',
});

interface IBookmarkPopupNewGroupFormProps {
  onSubmit(
    values: IBookmarkPopupNewGroupFormState,
    helpers: FormikHelpers<IBookmarkPopupNewGroupFormState>
  ): void;
  onCancel?(): void;
}

const BookmarkPopupNewGroupForm: React.FC<IBookmarkPopupNewGroupFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { data } = useGetBookmarkGroupsQuery();
  const [t] = useTranslation(['common']);
  const defaultBookmarkGroupNameCount =
    (data?.bookmarkPage?.allBookmarkGroups?.bookmarkGroups?.length ?? 0) + 1;
  const inputName = 'name';
  const inputRef = useRef<HTMLInputElement>();
  const { mobile } = useDeviceType();

  return (
    <Formik<IBookmarkPopupNewGroupFormState>
      initialValues={{
        name: t('bookmark.group.defaultName', {
          count: defaultBookmarkGroupNameCount,
        }),
      }}
      validationSchema={BookmarkPopupNewGroupFormValidationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          <BookmarkGroupNameForm
            noValidate
            onSubmit={(e: unknown) =>
              formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
            }
          >
            <BookmarkPopupContentRow>
              <BookmarkPopupTextInputContainer>
                <ValidatedTextInput
                  required
                  name={inputName}
                  className="text-input"
                  showErrorMessage={false}
                  hideLabel={true}
                  inputRef={inputRef}
                />
                {formik.values.name?.length > 0 && (
                  <SearchResetButton
                    type="reset"
                    variant="iconBtn"
                    icon={{
                      name: 'close-icon',
                      size: mobile ? 'sm' : 'md',
                    }}
                    onClick={() => {
                      formik.setFieldValue(inputName, '');
                      inputRef.current?.focus();
                    }}
                  >
                    {t('common:close')}
                  </SearchResetButton>
                )}
              </BookmarkPopupTextInputContainer>
            </BookmarkPopupContentRow>
            {!onCancel && (
              <BookmarkPopupContentRow>
                <BookmarkPopupFormSubmitButton
                  disabled={formik.isSubmitting}
                  className="form-submit-button"
                  type="submit"
                >
                  {t('bookmark.group.createAndSave.button')}
                </BookmarkPopupFormSubmitButton>
              </BookmarkPopupContentRow>
            )}
            {onCancel && (
              <BookmarkPopupButtonRow>
                <Button variant="tagBtn" onClick={onCancel}>
                  {t('bookmark.group.edit.cancel')}
                </Button>
                <Button
                  disabled={formik.isSubmitting}
                  className="form-submit-button"
                  type="submit"
                >
                  {t('bookmark.group.edit.apply')}
                </Button>
              </BookmarkPopupButtonRow>
            )}
          </BookmarkGroupNameForm>
        );
      }}
    </Formik>
  );
};

export default BookmarkPopupNewGroupForm;
