import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { SrlProductInfoB2B } from '@hotelplan/graphql.types';
import { removeExtraSpaces } from 'components/domain/string/string.utils';
import SearchTypeFlags from './SearchTypeFlags';

const SearchProductInfo = ({
  route,
  duration,
  priceFrom,
  lead,
  typeFlags,
}: SrlProductInfoB2B): ReactElement => {
  const [t] = useTranslation('search');

  return (
    <>
      {removeExtraSpaces(duration) ? (
        <div>
          <span>{t('search.results.duration')}</span> <span>{duration}</span>
        </div>
      ) : null}
      {removeExtraSpaces(route) ? (
        <div>
          <span>{route}</span>
        </div>
      ) : null}
      {removeExtraSpaces(priceFrom) ? (
        <div>
          <span>{t('search.results.price')}</span> <span>{priceFrom}</span>
        </div>
      ) : null}
      {removeExtraSpaces(lead) ? (
        <div className="lead-info" dangerouslySetInnerHTML={{ __html: lead }} />
      ) : null}
      {typeFlags?.length ? <SearchTypeFlags typeFlags={typeFlags} /> : null}
    </>
  );
};

export default SearchProductInfo;
