import React, { useRef } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { AuthChannelType, Link as LinkType } from '@hotelplan/graphql.types';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { getRouterLink } from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import MobileLogoutMenuItem from 'components/domain/sitetools/MobileLogoutMenuItem';
import MobileWishlistMenuItem from 'components/domain/sitetools/MobileWishlistMenuItem';
import { usePopUpMenuQueryQuery } from 'graphql/mobileBottomMenu/GetMobileBottomMenu.generated';

const MobileNavBackground = styled.div.attrs({
  'data-id': `mobile-nav`,
})(({ theme: { colors } }) => ({
  backgroundColor: colors.lightGreySecond,
  width: '100%',
  position: 'absolute',
  bottom: '50px',
  left: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  borderRadius: '8px 8px 0 0',
}));

const MobileNavWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    pt: 2,
    pb: 3,
    px: 2,
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flex: '1',
    color: 'black',
    '.image-text': {
      mb: 2,
      fontWeight: 'normal',
      fontSize: 2,
      textAlign: 'start',
    },
  })
);

const BottomMenuItemWrapper = styled.div(
  ({ theme: { colors, fontSizes } }) => ({
    width: '100%',
    height: '42px',
    fontSize: fontSizes[1],
    fontWeight: '500',
    textAlign: 'left',
    backgroundColor: colors.paperGrey,
    borderRadius: '5px',
    marginBottom: '4px',
    '&.stand-alone': { marginTop: '12px' },
    '.bottom-menu-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      img: {
        width: '50px',
        height: '50px',
        marginTop: '-5px',
      },
    },
    '.icon-label': {
      fontSize: '16px',
      color: colors.black,
      fontWeight: '500',
      lineHeight: '22px',
    },
    a: {
      width: '100%',
      height: '42px',
      padding: '10px 12px',
    },
  })
);

const MobileNav: React.FC<{
  dropDownRef?: React.MutableRefObject<HTMLDivElement>;
  onClose?(): void;
}> = ({ dropDownRef, onClose }) => {
  const { data } = usePopUpMenuQueryQuery({});
  const mobileNavButtonRef = useRef<HTMLButtonElement>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);

  const { channelType } = useAuthentication();
  const isB2C = channelType !== AuthChannelType.B2B;

  const [isMobileNavShown, closeMobileNav] = useToggleState(false);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [mobileNavRef, mobileNavButtonRef],
    () => {
      if (!isMobileNavShown) return;
      closeMobileNav();
    }
  );

  return (
    <MobileNavBackground ref={dropDownRef}>
      <MobileNavWrapper>
        {data?.popUpMenu.items.map((item, index) => {
          const { title, link } = item;

          return (
            <BottomMenuItemWrapper
              key={`menu-icon-${index}`}
              data-id={`testId-${title}`}
            >
              <RouterLink {...getRouterLink(link as LinkType)} passHref>
                <Link onClick={onClose}>
                  <div className={'bottom-menu-item'}>
                    <span className={`icon-label`}>{title}</span>
                  </div>
                </Link>
              </RouterLink>
            </BottomMenuItemWrapper>
          );
        })}
        <BottomMenuItemWrapper
          data-id={`testId-bookmarks`}
          className="stand-alone"
        >
          <MobileWishlistMenuItem />
        </BottomMenuItemWrapper>
        {!isB2C ? (
          <BottomMenuItemWrapper
            data-id={`testId-logout`}
            className="stand-alone"
          >
            <MobileLogoutMenuItem />
          </BottomMenuItemWrapper>
        ) : null}
      </MobileNavWrapper>
    </MobileNavBackground>
  );
};

export default MobileNav;
