import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ExternalLinkFragmentDoc } from 'graphql/link/ExternalLink.generated';
export type PhoneFragment = {
  __typename?: 'AgencyContactPhone';
  title?: string | null;
  link: {
    __typename?: 'ExternalLink';
    openMethod: Types.OpenLinkMethod;
    url: string;
    caption?: string | null;
  };
};

export const PhoneFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AgencyContactPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'externalLink' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ExternalLinkFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
