import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const SearchOverlayModal = styled(WhiteBoxModal).attrs({
  name: `search-overlay`,
})(
  sx2CssThemeFn({
    '@media (orientation: landscape)': {
      '.shadow-overlay-children': {
        padding: 0,
      },
    },
    '.modal-body, .modal-header': {
      backgroundColor: 'paperGrey',
    },
    '.modal-wrapper': {
      maxHeight: '100%',
      height: '100%',
    },
    '.modal-header': {
      borderWidth: 0,
      height: 0,
      padding: 0,
    },
    '.close-button': {
      position: 'absolute',
      top: [4, '40px'],
      right: ['30px', '50px'],
      zIndex: 2,
      backgroundColor: 'transparent',
      '.icon': {
        variant: 'icons.xlg',
      },
    },
  })
);

export default SearchOverlayModal;
