import noop from 'lodash/noop';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FlaglineText, ThirdHeading } from 'theme/headings';
import { Schedule } from '@hotelplan/components.common.help-overlay';
import { InfoCard } from '@hotelplan/components.common.info-card';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { mapOpeningHoursToSchedules } from 'components/domain/agency/mapOpeningHoursToSchedules';
import {
  useGetClientGclidValue,
  useGetLinkWithJotform,
} from 'components/domain/jotform/jotformGclid.utils';
import { AgencyBoxFragment } from 'graphql/agency/GetAgencyBoxData.generated';
import { OpeningHoursFragment } from 'graphql/fragments/OpeningHours.generated';
import { PhoneFragment } from 'graphql/fragments/Phone.generated';
import {
  HelpOverlayBoxChatFragment,
  HelpOverlayBoxContactFragment,
  HelpOverlayBoxFragment,
} from 'graphql/layout/HelpOverlayFragments.generated';

const Link = styled(BsLink)(({ theme }) => ({
  ...theme.link.uiPrimary,
}));

const InfoCardWrapper = styled(InfoCard)(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'roseFine',
    borderRadius: '0px',
    border: 'none',
    px: [3, '20px'],
    py: [4, '20px'],
    mb: [3, 0],
    '.info-card-icon img': {
      width: '48px',
      height: '48px',
      display: 'block',
      margin: 'auto',
    },
    '.info-card-icon': {
      textAlign: 'center',
      display: 'block',
      mb: [4, 6],
    },
    button: {
      mt: [0, 3],
      mb: [4, '60px'],
      px: 3,
      fontSize: 3,
    },
    '.help-overlay-content-wrap': {
      borderBottom: '1px solid',
      borderColor: '#B1B1B1',
      minHeight: [null, '340px'],
      textAlign: 'center',
      '.image-tagline': {
        mb: [2, 4],
      },
    },
    '.schedule': {
      paddingLeft: 5,
      fontSize: 2,
      p: {
        fontSize: 2,
        fontWeight: 'normal',
        color: '#000',
        mb: 2,
      },
    },
    a: {
      paddingTop: 4,
      justifyContent: ['center', 'flex-start'],
    },
  })
);

const HelpOverlayContent = styled.div(
  sx2CssThemeFn({
    textAlign: 'center',
    minHeight: [null, '210px'],
    '.image-title': {
      mb: 4,
    },
    '.image-tagline': {
      fontSize: '15px',
      mb: [2, 5],
    },
    '.cut-image-text': cutTextByLinesCount(5),
  })
);

const PhoneLinkWrapper = styled.div(
  sx2CssThemeFn({
    '.link': {
      paddingTop: 0,
      marginBottom: 4,
    },
  })
);

interface ICommonBoxProps {
  boxesCount: number;
  onClick?(e: React.MouseEvent): void;
}

interface IGeneralBoxProps extends ICommonBoxProps {
  box: HelpOverlayBoxFragment;
}

export function HelpOverlayGeneralBox({
  box,
  boxesCount,
  onClick = noop,
}: IGeneralBoxProps): ReactElement {
  const { mobile } = useDeviceType();
  const gclid = useGetClientGclidValue('', true);
  const linkWithJotform = useGetLinkWithJotform(box.targetUrl, gclid);

  return (
    <InfoCardWrapper
      icon={box.icon}
      boxesCount={boxesCount}
      link={linkWithJotform}
      onClick={onClick}
    >
      <div className="help-overlay-content-wrap">
        <HelpOverlayContent>
          {!mobile && (
            <FlaglineText className="image-tagline">
              {box.icon.alt}
            </FlaglineText>
          )}
          <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
          {!mobile && (
            <div
              className="image-text"
              dangerouslySetInnerHTML={{ __html: box.mainText }}
            />
          )}
        </HelpOverlayContent>
      </div>
      <Link
        href={
          box.targetUrl
            ? (getRouterLink(linkWithJotform).href as string)
            : undefined
        }
        target={box.targetUrl ? getOpenMethod(box.targetUrl) : undefined}
        onClick={onClick}
        icon={{ name: 'chevron-long-right' }}
      >
        <span className="link-text">{box.mainTitle}</span>
      </Link>
    </InfoCardWrapper>
  );
}

interface IContactBoxProps extends ICommonBoxProps {
  box: HelpOverlayBoxContactFragment;
  phone: PhoneFragment;
  timezone: string;
  openingHours: OpeningHoursFragment[];
}

export function HelpOverlayContactBox({
  box,
  boxesCount,
  onClick = noop,
  phone,
  timezone,
  openingHours,
}: IContactBoxProps): ReactElement {
  const { mobile } = useDeviceType();

  const { link, title } = phone;

  return (
    <InfoCardWrapper
      icon={box.icon}
      boxesCount={boxesCount}
      onClick={onClick}
      href={link.caption ? `tel:${link.caption}` : undefined}
    >
      <div className="help-overlay-content-wrap">
        <HelpOverlayContent>
          {!mobile && (
            <FlaglineText className="image-tagline">{title}</FlaglineText>
          )}
          <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
          <PhoneLinkWrapper>
            <Link onClick={onClick} icon={{ name: 'chevron-long-right' }}>
              <span className="link-text">{link.caption}</span>
            </Link>
          </PhoneLinkWrapper>
        </HelpOverlayContent>
      </div>
      <Schedule
        openingHours={openingHours}
        timezone={timezone}
        showStatusIcon={true}
        className="schedule"
        mapCustomOpeningHoursToSchedules={mapOpeningHoursToSchedules}
      />
    </InfoCardWrapper>
  );
}

interface IChatBoxProps extends ICommonBoxProps {
  box: HelpOverlayBoxChatFragment;
}

export function HelpOverlayChatBox({
  boxesCount,
  onClick = noop,
  box,
}: IChatBoxProps): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <InfoCardWrapper
      icon={box.icon}
      boxesCount={boxesCount}
      link={box.targetUrl}
      onClick={onClick}
    >
      <div className="help-overlay-content-wrap">
        {box.icon.alt && !mobile ? (
          <FlaglineText className="image-tagline">{box.icon.alt}</FlaglineText>
        ) : null}
        <HelpOverlayContent>
          <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
          {!mobile && (
            <div
              className="image-text"
              dangerouslySetInnerHTML={{ __html: box.mainText }}
            />
          )}
        </HelpOverlayContent>
      </div>
      <Link onClick={onClick} icon={{ name: 'chevron-long-right' }}>
        <span className="link-text">{box.mainTitle}</span>
      </Link>
    </InfoCardWrapper>
  );
}

interface IAgencyBoxProps extends IGeneralBoxProps {
  agency: AgencyBoxFragment;
}

const AgencyAddress = styled(BsLink)(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '.address': {
      fontSize: 2,
    },
  })
);

export function HelpOverlayAgencyBox({
  agency,
  box,
  boxesCount,
  onClick = noop,
}: IAgencyBoxProps): ReactElement {
  const { mobile } = useDeviceType();

  const {
    url,
    title,
    addressDetails: { street, zip, city },
  } = agency;

  return (
    <InfoCardWrapper
      icon={box.icon}
      boxesCount={boxesCount}
      link={url}
      onClick={onClick}
    >
      <div className="help-overlay-content-wrap">
        <HelpOverlayContent>
          {!mobile && (
            <FlaglineText className="image-tagline">{title}</FlaglineText>
          )}
          <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
          {!mobile && (
            <>
              <div
                className="image-text cut-image-text"
                dangerouslySetInnerHTML={{ __html: box.mainText }}
              />
              <AgencyAddress href={url.uri} onClick={e => e.stopPropagation()}>
                {street ? <span className="address">{street}</span> : null}
                {zip ? (
                  <span className="address">{`${zip} ${city}`}</span>
                ) : null}
              </AgencyAddress>
            </>
          )}
        </HelpOverlayContent>
      </div>
      <Link onClick={onClick} icon={{ name: 'chevron-long-right' }}>
        <span className="link-text">{box.mainTitle}</span>
      </Link>
    </InfoCardWrapper>
  );
}
