import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.types';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import ExactSearch from 'components/domain/exact-search/ExactSearch';
import { useAddFullTextSearchHistoryMutation } from 'graphql/search/AddFullTextSearchHistory.generated';
import {
  GetLastSearchedDocument,
  GetLastSearchedQuery,
  useGetLastSearchedQuery,
} from 'graphql/search/GetLastSearched.generated';
import {
  GetLastViewedQuery,
  useGetLastViewedQuery,
} from 'graphql/search/GetLastViewed.generated';
import { useGetSearchOverlayQuery } from 'graphql/search/GetSearchOverlay.generated';
import { SEARCH_VALUE_KEY } from './search.constants';
import SearchOverlayContent from './SearchOverlayContent';
import SearchOverlayModal from './SearchOverlayModal';
import useGetFullTextSearchLinkWithSection from './useGetFullTextSearchLinkWithSection';
import { useLastSearchedViewed } from './useLastSearchedViewed';

const ExactSearchWrapper = styled.div(({ theme: { media, space } }) => ({
  position: 'absolute',
  top: space[4],
  left: space[3],
  zIndex: 2,
  [media.tablet]: {
    top: '40px',
  },
}));

export enum SearchOverlayEvent {
  Open = `search-overlay-open`,
  Close = `search-overlay-close`,
}

export function openSearchOverlay(): void {
  window.dispatchEvent(new Event(SearchOverlayEvent.Open));
}

export function closeSearchOverlay(): void {
  window.dispatchEvent(new Event(SearchOverlayEvent.Close));
}

interface ISearchOverlayProps {
  isInitialOpened?: boolean;
}

export default function SearchOverlay(
  props: ISearchOverlayProps
): React.ReactElement {
  const { isInitialOpened = false } = props;

  const router = useRouter();
  const { data: overlayData } = useGetSearchOverlayQuery({});

  const [isOpened, open, close] = useToggleState(isInitialOpened);
  const onceOpened = useRef(isOpened);

  if (isOpened) {
    onceOpened.current = true;
  }

  const handleCloseOverlay = () => {
    close();
    sessionStorage.removeItem(SEARCH_VALUE_KEY);
  };

  const searchedItems = useLastSearchedViewed<GetLastSearchedQuery>({
    useHistoryQuery: function () {
      return useGetLastSearchedQuery({
        variables: {
          page: 0,
        },
        skip: !onceOpened.current,
        notifyOnNetworkStatusChange: true,
      });
    },
    getHistoryResponse(data) {
      return data?.history.recentSearched;
    },
  });

  const viewedItems = useLastSearchedViewed<GetLastViewedQuery>({
    useHistoryQuery() {
      return useGetLastViewedQuery({
        variables: {
          page: 0,
        },
        skip: !onceOpened.current,
        notifyOnNetworkStatusChange: true,
      });
    },
    getHistoryResponse(data) {
      return data?.history.recentViewed;
    },
  });

  const [addSearchHistory] = useAddFullTextSearchHistoryMutation({
    refetchQueries: [GetLastSearchedDocument],
  });

  const { requestLink } = useGetFullTextSearchLinkWithSection();

  useEffect(function openCloseEventsEffect() {
    window.addEventListener(SearchOverlayEvent.Open, open);
    window.addEventListener(SearchOverlayEvent.Close, handleCloseOverlay);

    return function eventsCleanup() {
      window.removeEventListener(SearchOverlayEvent.Open, open);
      window.removeEventListener(SearchOverlayEvent.Close, handleCloseOverlay);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeSearchOverlay);
    router.events.on('routeChangeError', closeSearchOverlay);

    return () => {
      router.events.off('routeChangeComplete', closeSearchOverlay);
      router.events.off('routeChangeError', closeSearchOverlay);
    };
  }, [router.events]);

  const keywords = overlayData?.searchOverlay.keywords;
  const searchTrends = overlayData?.searchOverlay.searchTrends;
  const mostSearched = overlayData?.searchOverlay.mostSearched;

  async function searchHandler(query: string, sectionKeys?: string[]) {
    const keywordsInput = query.split(' ');

    await requestLink(keywordsInput, sectionKeys);
    await addSearchHistory({
      variables: { keywords: keywordsInput } as any,
    });
  }

  return (
    <SearchOverlayModal
      show={isOpened}
      onClose={handleCloseOverlay}
      title={<ExactSearchHandler />}
    >
      <SearchOverlayContent
        keywords={keywords}
        searchTrends={searchTrends}
        mostSearched={mostSearched}
        onSearch={searchHandler}
        {...{ searchedItems, viewedItems }}
      />
    </SearchOverlayModal>
  );
}

function ExactSearchHandler(): ReactElement {
  const { channelType } = useAuthentication();

  if (channelType !== AuthChannelType.B2B) return null;

  return (
    <ExactSearchWrapper>
      <ExactSearch />
    </ExactSearchWrapper>
  );
}
