import React, { useCallback, useEffect, useReducer } from 'react';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { PageType } from '@hotelplan/platform-graphql-api';
import {
  TAddBookmarkModalPopup,
  useBookmarkModalPopupContext,
} from 'components/domain/bookmark/BookmarkModalPopupContext';
import { useGetBookmarkGroupsQuery } from 'graphql/bookmark/GetBookmarkGroups.generated';
import {
  BookmarkBlockActionType,
  bookmarkBlockInitialState,
  bookmarkBlockReducer,
  BookmarkBlockView,
} from './BookmarkBlock.utils';
import { useBookmarkContext } from './BookmarkContext';
import { selectBookmarkItem } from './BookmarkContext.utils';
import BookmarkPopupAddToGroup from './BookmarkPopupAddToGroup';
import BookmarkPopupNewGroup from './BookmarkPopupNewGroup';

const BookmarkAddModalPopup: React.FC = () => {
  const [bookmarkBlockState, dispatch] = useReducer(
    bookmarkBlockReducer,
    bookmarkBlockInitialState
  );
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const { data, loading: bookmarkGroupsLoading } = useGetBookmarkGroupsQuery();
  const modal = bookmarkModalContext.modal as TAddBookmarkModalPopup;
  const objectId = modal?.payload?.objectId;
  const pageType = modal?.payload?.pageType;
  const bookmarkContext = useBookmarkContext();

  const onBackButtonClick = useCallback(() => {
    dispatch({
      type: BookmarkBlockActionType.SET_VIEW,
      payload: {
        view: BookmarkBlockView.ADD_TO_BOOKMARK_GROUP,
      },
    });
  }, []);

  useEffect(() => {
    if (data) {
      let view = BookmarkBlockView.NEW_BOOKMARK_GROUP;

      if (data?.bookmarkPage?.allBookmarkGroups?.bookmarkGroups?.length) {
        view = BookmarkBlockView.ADD_TO_BOOKMARK_GROUP;
      }

      dispatch({
        type: BookmarkBlockActionType.SET_VIEW,
        payload: {
          view,
        },
      });
    }
  }, [data, bookmarkModalContext.isOpen]);

  function onSubmit(type: PageType, id: string, groupName: string) {
    bookmarkContext.addBookmarkToNewGroup({
      pageType: type,
      objectId: id,
      groupName,
    });
  }

  const onBookmarkGroupItemClick = useCallback(
    (groupId: string) => {
      if (objectId && pageType) {
        if (selectBookmarkItem(bookmarkContext, objectId, pageType, groupId)) {
          bookmarkContext.removeBookmark({ groupId, pageType, objectId });
        } else {
          bookmarkContext.addBookmark({ groupId, pageType, objectId });
        }
      }
    },
    [bookmarkContext.bookmarkGroups, objectId, pageType]
  );

  return (
    <>
      {!bookmarkGroupsLoading &&
        data?.bookmarkPage?.allBookmarkGroups?.bookmarkGroups && (
          <>
            {[
              BookmarkBlockView.NEW_BOOKMARK_GROUP,
              BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK,
            ].includes(bookmarkBlockState.view) && (
              <BookmarkPopupNewGroup
                onSubmit={(values, helpers) => {
                  onSubmit(
                    modal.payload.pageType,
                    modal.payload.objectId,
                    values.name
                  );

                  helpers.resetForm();
                  dispatch({
                    type: BookmarkBlockActionType.RESET,
                  });
                  bookmarkModalContext.close();
                }}
                onCloseButtonClick={() => {
                  dispatch({
                    type: BookmarkBlockActionType.RESET,
                  });
                  bookmarkModalContext.close();
                }}
                onBackButtonClick={
                  bookmarkBlockState.view ===
                  BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK
                    ? onBackButtonClick
                    : undefined
                }
              />
            )}
            {bookmarkBlockState.view ===
              BookmarkBlockView.ADD_TO_BOOKMARK_GROUP && (
              <BookmarkPopupAddToGroup
                bookmarkGroups={
                  data.bookmarkPage.allBookmarkGroups.bookmarkGroups
                }
                currentObject={
                  objectId && pageType
                    ? {
                        id: objectId,
                        pageType: pageType,
                      }
                    : undefined
                }
                onCloseButtonClick={() => {
                  dispatch({
                    type: BookmarkBlockActionType.RESET,
                  });
                  bookmarkModalContext.close();
                }}
                onNewBookmarkGroupButtonClick={() => {
                  dispatch({
                    type: BookmarkBlockActionType.SET_VIEW,
                    payload: {
                      view: BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK,
                    },
                  });
                }}
                onBookmarkGroupItemClick={onBookmarkGroupItemClick}
              />
            )}
          </>
        )}
    </>
  );
};

export default withLazyHydrate(BookmarkAddModalPopup, {
  whenIdle: true,
  noWrapper: true,
});
