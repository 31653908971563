import '@hotelplan/libs.polyfills';
import 'routes.config';
import compose from 'lodash/fp/compose';
import merge from 'lodash/fp/merge';
import React, { useEffect, useRef } from 'react';
import { GlobalStyle } from 'theme/globalStyles';
import { createBootstrapApp, getRoutePageProp } from '@hotelplan/boot.web';
import { PageType } from '@hotelplan/graphql.types';
import { getObjectIdPageProp } from '@hotelplan/libs.context.object-id';
import { getPageTypeProp } from '@hotelplan/libs.context.page-type';
import {
  setSentryFeaturesConfig,
  useAppSentryConfig,
} from '@hotelplan/libs.error-tracking';
import { appWithFeatureToggle } from '@hotelplan/libs.feature-toggle';
import thTheme from '@hotelplan/style.travelhouse-style';
import appWithBookmarkModalPopup from 'components/domain/bookmark/appWithBookmarkModalPopup';
import appWithExactSearch from 'components/domain/exact-search/appWithExactSearch';
import Layout from 'components/domain/layout/Layout';
import { apolloPossibleTypes } from 'config/apolloPossibleTypes';
import { apolloTypePolicies } from 'config/apolloTypePolicies';
import { FeatureList } from 'config/featureList';
import { useAddViewedHistoryMutation } from 'graphql/search/AddViewedHistory.generated';
import { GetLastViewedDocument } from 'graphql/search/GetLastViewed.generated';
import nextI18NextConfig from 'next-i18next.config.js';
import theme from 'theme';
import 'normalize.css';
import 'public/css/fonts.css';

function useInitHistoryMutations(pageProps: any) {
  const { id: payloadId } = getObjectIdPageProp(pageProps);
  const pageType = getPageTypeProp<PageType>(pageProps);
  const { pathname } = getRoutePageProp(pageProps);
  const prevCalledKey = useRef('');
  let id;

  if (
    [
      PageType.GeoObject,
      PageType.ThemeObject,
      PageType.AgencyObject,
      PageType.Pdp,
    ].includes(pageType)
  ) {
    id = payloadId;
  }

  if (pageType === PageType.Static) {
    id = pathname;
  }

  const [addRecord] = useAddViewedHistoryMutation({
    variables: {
      pageType,
      objectId: id,
    },
    refetchQueries: [GetLastViewedDocument],
  });
  useEffect(
    function () {
      // Prepare call key to identify the specific call
      const callKey = `${pageType}:${id}`;
      if (!id || prevCalledKey.current === callKey) return;

      // Record called key to prevent double invoking
      prevCalledKey.current = callKey;
      addRecord();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageType, id]
  );
}

const App = compose(
  appWithExactSearch,
  appWithFeatureToggle({
    featureUpdatesEndpoint: `/api/feature-toggle/updates`,
    onGetConfig(config) {
      setSentryFeaturesConfig(config);
    },
  }),
  appWithBookmarkModalPopup
)(
  createBootstrapApp({
    version: process.env.VERSION,
    theme: merge(theme, thTheme),
    GlobalStyle,
    apolloPossibleTypes,
    apolloTypePolicies,
    i18nextConfig: nextI18NextConfig,
    metaOptions: ({ language }) => ({
      favIconDefault: `/favicon.ico`,
      favIconSvg: `/meta-data/${language}/favicon.svg`,
      appleTouchIcon: `/apple-touch-icon.png`,
      webManifest: `/meta-data/${language}/manifest.webmanifest`,
      favIconColor: '#E73A31',
    }),
    endpointBasePath: `/api/auth`,
    getLayoutComponent({ pageProps }): React.ReactElement {
      return (
        <Layout
          tagline={pageProps.tagline}
          pageType={pageProps.page}
          gclidValue={pageProps.gclidValue}
          breadcrumbs={pageProps.breadcrumbs}
          origin={pageProps.origin}
        />
      );
    },
    useSideEffects({ pageProps, user }) {
      useInitHistoryMutations(pageProps);
      useAppSentryConfig({
        user,
      });
    },
    pageViewOptions: {
      exposeFeatures: true,
      omitScriptStrategy: true,
      splitPageViewEventFeatureToggle: FeatureList.SplitPageviewEvent,
    },
    openGraphOptions: {
      enable: true,
      fallback: locale => {
        return {
          title: {
            ['de']: `Grosse Reisen nach Mass weltweit und individuell - travelhouse`,
            ['fr']: `tourisme pour tous - Inspiration et voyages sur mesure`,
          }[locale],
        };
      },
    },
  })
);

export default App;
