import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

interface ISearchSiteToolProps {
  onSearch?(): void;
}

function SearchSiteTool(props: ISearchSiteToolProps): React.ReactElement {
  const { onSearch } = props;
  const [t] = useTranslation('search');

  return (
    <SiteToolsButtonsWrapper
      className="site-tool-button"
      role="button"
      data-id="search-site-tool"
      onClick={onSearch}
    >
      <Button variant="headerBtn" icon={{ name: 'search' }} />
      <span className="tooltip">{t('search:searchInput.submit')}</span>
    </SiteToolsButtonsWrapper>
  );
}

export default SearchSiteTool;
