import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AutoSuggestQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  keywords: Array<Types.Scalars['String']> | Types.Scalars['String'];
  types: Array<Types.SearchTypeEnum> | Types.SearchTypeEnum;
  page: Types.PageInput;
  exactSearch?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type AutoSuggestQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    fullTextSearch: {
      __typename?: 'SrlFullTextSearchResultComponent';
      autoSuggest: {
        __typename?: 'SrlFullTextSearchResult';
        title: string;
        items: Array<{
          __typename?: 'FullTextSearchItem';
          name: string;
          link: {
            __typename?: 'InternalLink';
            uri: string;
            targetPageType: Types.PageType;
            caption?: string | null;
          };
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              id: string;
              url: string;
            }>;
          };
        }>;
      };
    };
  };
};

export const AutoSuggestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoSuggest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'types' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SearchTypeEnum' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exactSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fullTextSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'keywords' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fullMatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exactSearch' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoSuggest' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'types' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'types' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uri' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'targetPageType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'criteria',
                                        },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                  },
                                                  value: {
                                                    kind: 'StringValue',
                                                    value: '1x_mobile',
                                                    block: false,
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'width',
                                                  },
                                                  value: {
                                                    kind: 'IntValue',
                                                    value: '80',
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'height',
                                                  },
                                                  value: {
                                                    kind: 'IntValue',
                                                    value: '67',
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                  },
                                                  value: {
                                                    kind: 'StringValue',
                                                    value: '1x_desktop',
                                                    block: false,
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'width',
                                                  },
                                                  value: {
                                                    kind: 'IntValue',
                                                    value: '80',
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'height',
                                                  },
                                                  value: {
                                                    kind: 'IntValue',
                                                    value: '67',
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'alt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'resized',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useAutoSuggestQuery__
 *
 * To run a query within a React component, call `useAutoSuggestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoSuggestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoSuggestQuery({
 *   variables: {
 *      context: // value for 'context'
 *      keywords: // value for 'keywords'
 *      types: // value for 'types'
 *      page: // value for 'page'
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useAutoSuggestQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AutoSuggestQuery,
    AutoSuggestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AutoSuggestQuery, AutoSuggestQueryVariables>(
    AutoSuggestDocument,
    options
  );
}
export function useAutoSuggestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AutoSuggestQuery,
    AutoSuggestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AutoSuggestQuery,
    AutoSuggestQueryVariables
  >(AutoSuggestDocument, options);
}
export type AutoSuggestQueryHookResult = ReturnType<typeof useAutoSuggestQuery>;
export type AutoSuggestLazyQueryHookResult = ReturnType<
  typeof useAutoSuggestLazyQuery
>;
export type AutoSuggestQueryResult = Apollo.QueryResult<
  AutoSuggestQuery,
  AutoSuggestQueryVariables
>;
