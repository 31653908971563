import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactNode } from 'react';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './BookmarkPopup.styles';

interface IBookmarkEditTextPopupProps {
  headerText?: ReactNode;
  onCloseButtonClick(): void;
}

const BookmarkEditTextPopup: React.FC<
  PropsWithChildren<IBookmarkEditTextPopupProps>
> = ({ children, headerText, onCloseButtonClick }) => {
  const [t] = useTranslation(['common']);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>{headerText}</BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            onCloseButtonClick();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupTextRow>{children}</BookmarkPopupTextRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkEditTextPopup;
