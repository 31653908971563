import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlaglineText } from 'theme/headings';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Button } from '@hotelplan/components.common.buttons';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { Link } from '@hotelplan/components.common.link';
import RouterLink from '@hotelplan/components.common.router-link';
import { AuthChannelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { track } from '@hotelplan/libs.tracking-events';
import { FeatureList } from 'config/featureList';
import { SEARCH_VALUE_KEY } from './search.constants';
import { clearSectionsPosition } from './Search.utils';
import SearchAutoSuggest from './SearchAutoSuggest';
import SearchResetButton from './SearchResetButton';
import type { IHistoryItems } from './useLastSearchedViewed';

interface ISearchDetailsOverlayProps {
  keywords?: string[];
  searchTrends?: string[];
  mostSearched?: string[];
  onSearch?(query: string, sectionKeys?: string[]): void;
  minLength?: number;
  searchedItems: IHistoryItems;
  viewedItems: IHistoryItems;
}

const SearchDetailsContent = styled.div(({ theme: { space, media } }) => ({
  position: 'relative',
  maxWidth: '988px',
  width: '100%',
  paddingLeft: space[4],
  paddingRight: space[4],
  margin: `111px auto ${space[7]}`,
  [media.tablet]: {
    margin: '123px auto 0px',
  },
  [media.mobile]: {
    paddingLeft: space[3],
    paddingRight: space[3],
  },
}));

const SearchResultTagsWrapper = styled.div<{
  autoSuggestOverlayShown: boolean;
}>(({ autoSuggestOverlayShown }) => ({
  ...(autoSuggestOverlayShown && {
    marginTop: '98px',
  }),
}));

const SearchResultTags = styled.div(({ theme: { space, media } }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.search-result-tags': {
    display: 'flex',
    flexWrap: 'unset',
    gap: space[2],
    height: '60px',
    overflow: 'auto',
    [media.tablet]: {
      height: 'auto',
      overflow: 'hidden',
      flexWrap: 'wrap',
    },
  },
  '.search-result-tags-items': {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
  '.tagline-text': {
    width: '100%',
    fontSize: '15px',
    fontWeight: '400',
    marginBottom: '24px',
    [media.tablet]: {
      marginRight: space[3],
    },
  },
  marginBottom: space[6],
  [media.tablet]: {
    marginBottom: space[7],
  },
}));

const SearchResultList = styled.div(
  ({ theme: { space, fontSizes, media, colors } }) => ({
    '.heading-wrap': {
      marginBottom: space[3],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '.icon': {
        marginRight: space[3],
      },
    },
    '.tagline-text': {
      fontSize: '15px',
      fontWeight: '400',
    },
    '.list': {
      li: {
        listStyle: 'none',
        fontSize: fontSizes[1],
        fontWeight: '700',
        a: {
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        'a:hover': {
          textDecoration: 'underline',
          color: colors.black,
        },
      },
    },
    button: {
      '.icon': {
        marginRight: space[2],
      },
      '&:focus': {
        outline: '1px auto',
        outlineColor: colors.primary,
      },
    },
    marginBottom: space[6],
    [media.tablet]: {
      marginBottom: space[7],
    },
  })
);

const SearchInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchInput,
  ({ theme: { colors } }) => ({
    flex: 1,
    '.input': {
      borderRadius: '5px 0 0 5px',
    },
    '&.error input': {
      borderBottom: '1px solid',
      borderColor: colors.primary, // @todo use `error` color here, refactor if it's needed
    },
  })
);

const SearchInputWrap = styled.div({
  position: 'relative',
  flex: 1,
});

const SearchInputError = styled.div(({ theme: { space, colors } }) => ({
  position: 'absolute',
  paddingTop: space[1],
  paddingLeft: space[2],
  color: colors.primary, // @todo use `error` color here, refactor if it's needed
  fontSize: '12px',
}));

const SearchForm = styled.form<{ autoSuggestOverlayShown: boolean }>(
  ({ autoSuggestOverlayShown, theme: { space, media, colors } }) => ({
    display: 'flex',
    position: autoSuggestOverlayShown ? 'absolute' : 'relative',
    marginBottom: space[7],
    svg: {
      fill: 'transparent',
      color: colors.primary,
    },
    ...(autoSuggestOverlayShown && {
      left: 0,
      right: 0,
      marginLeft: space[4],
      marginRight: space[4],
      [media.mobile]: {
        marginLeft: space[3],
        marginRight: space[3],
      },
    }),
  })
);

export const SearchButton = styled(Button)(
  ({ theme: { space, media, fontSizes, colors } }) => ({
    borderRadius: '0 5px 5px 0',
    fontWeight: 'normal',
    fontSize: fontSizes[1],
    '@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)': {
      display: 'block',
    },
    [media.mobile]: {
      height: '50px',
      width: '52px',
      padding: '0',
      '.search-submit-button-text': {
        display: 'none',
      },
      '.icon': {
        marginTop: '3px',
        fill: 'white',
      },
    },
    [media.tablet]: {
      marginBottom: space[0],
      '.icon': {
        display: 'none',
      },
    },
    '&:focus': {
      outline: '2px solid',
      outlineColor: colors.redHover,
    },
  })
);

const AutoSuggestOverlay = styled.div<{ visible: boolean }>(({ visible }) => ({
  ...(!visible && { display: 'none' }),
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
}));

const DEFAULT_MIN_LENGTH = 3;
const AUTO_SUGGEST_SEARCH_VALUE_LENGTH = 2;
const ADVENTURE_TRAVEL_SECTION_KEY = 'adventuretravel';

const trackSuggestion = (source: string, target: string) => {
  track({
    event: `🚀 click.searchSuggestion`,
    _clear: true,
    eventMetadata: {
      source,
      target,
      component: `search-overlay`,
    },
  });
};

const SearchOverlayContent: React.FC<ISearchDetailsOverlayProps> = props => {
  const {
    keywords = [],
    searchTrends = [],
    mostSearched = [],
    onSearch,
    minLength = DEFAULT_MIN_LENGTH,
    searchedItems,
    viewedItems,
  } = props;

  const { mobile } = useDeviceType();
  const [t] = useTranslation(['common', 'search']);
  const { isEnabled } = useFeatureToggle();
  const inputRef = useRef<HTMLInputElement>(null);

  const [showError, setShowError] = useState(false);
  const [autoSuggestState, setAutoSuggestState] = useState(null);
  const [autoSuggestOverlayShown, setAutoSuggestOverlayShown] = useState(false);
  const [blockAutoSuggest, setBlockAutoSuggest] = useState(false);
  const [searchValue, setSearchValue] = useState(() => keywords.join(' '));
  const { channelType } = useAuthentication();
  const sessionStorageSearchValue = sessionStorage.getItem(SEARCH_VALUE_KEY);
  const setDefaultValue = () => {
    setShowError(false);
    setSearchValue('');
  };

  useEffect(function inputAutoFocusEffect() {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (autoSuggestState?.count > 0) {
      setAutoSuggestOverlayShown(true);
    } else {
      setAutoSuggestOverlayShown(false);
    }
  }, [autoSuggestState]);

  useEffect(() => {
    if (!sessionStorageSearchValue || searchValue === sessionStorageSearchValue)
      return;

    setBlockAutoSuggest(false);
  }, [searchValue, sessionStorageSearchValue]);

  useEffect(() => {
    if (!sessionStorageSearchValue) return;

    setSearchValue(sessionStorageSearchValue);
    setBlockAutoSuggest(true);
  }, [sessionStorageSearchValue]);

  function submitHandler(submittedQuery = searchValue, sectionKeys?: string[]) {
    if (submittedQuery.length < minLength) {
      setShowError(true);
      return;
    }

    if (autoSuggestState?.activeItem) {
      return;
    }

    onSearch(submittedQuery, sectionKeys);
  }

  function goFor(query: string, sectionKeys?: string[]) {
    clearSectionsPosition();
    setSearchValue(query);
    requestAnimationFrame(() => {
      submitHandler(query, sectionKeys);
    });
  }

  const onShowAll = useCallback(() => {
    goFor(searchValue, [ADVENTURE_TRAVEL_SECTION_KEY]);
  }, [searchValue]);

  const {
    items: recentSearched,
    nextPage: nextSearchedPage,
    hasMore: hasMoreSearched,
    loading: searchedLoading,
  } = searchedItems;

  const {
    items: recentViewed,
    nextPage: nextViewedPage,
    hasMore: hasMoreViewed,
    loading: viewedLoading,
  } = viewedItems;

  return (
    <SearchDetailsContent>
      <AutoSuggestOverlay
        visible={autoSuggestOverlayShown}
        onClick={() => {
          setDefaultValue();
          setAutoSuggestOverlayShown(false);
          inputRef.current.focus();
        }}
      />
      <SearchForm
        autoSuggestOverlayShown
        onSubmit={e => {
          e.preventDefault();
          clearSectionsPosition();
          submitHandler();
        }}
        action={`#`}
        role="search"
      >
        <SearchInputWrap>
          <SearchInput
            className={showError ? 'error' : undefined}
            inputRef={inputRef}
            hideLabel
            type="search"
            placeholder={t('search:searchInput.placeholder')}
            icon={{
              name: `search`,
              size: mobile ? 'sm' : 'md',
            }}
            value={searchValue}
            onChange={e => {
              const nextValue = e.target.value;
              if (showError && nextValue.length >= minLength) {
                setShowError(false);
              }
              setSearchValue(nextValue);
            }}
          />
          {searchValue.length ? (
            <SearchResetButton
              type="reset"
              variant="iconBtn"
              icon={{
                name: 'close-icon',
                size: mobile ? 'sm' : 'md',
              }}
              onClick={setDefaultValue}
            >
              {t('search:searchInput.submit')}
            </SearchResetButton>
          ) : null}
          {showError ? (
            <SearchInputError>{t('search:searchInput.error')}</SearchInputError>
          ) : null}
        </SearchInputWrap>
        <SearchButton
          type="submit"
          icon={{
            name: 'chevron-long-right',
            size: 'md',
          }}
        >
          <span className="search-submit-button-text">
            {t('search:searchInput.submit')}
          </span>
        </SearchButton>
        {isEnabled(FeatureList.AutoSuggest) &&
        searchValue.length >= AUTO_SUGGEST_SEARCH_VALUE_LENGTH &&
        !blockAutoSuggest ? (
          <SearchAutoSuggest
            searchValue={searchValue}
            showError={showError}
            onChange={state => {
              setAutoSuggestState(state);
            }}
            onSelect={({ type, item }) => {
              if (type === 'nonProduct') {
                goFor(item?.name);
              } else {
                if (typeof window === 'undefined') return;
                window.location.href = item?.link?.uri;
              }
            }}
            onShowAll={
              channelType !== AuthChannelType.B2B ? onShowAll : undefined
            }
          />
        ) : null}
      </SearchForm>
      <SearchResultTagsWrapper autoSuggestOverlayShown>
        <SearchResultTags>
          <FlaglineText className="tagline-text">
            {t('search:mostSearched')}
          </FlaglineText>

          {/*most-used*/}
          <div className="search-result-tags most-searched">
            {mostSearched?.map(mostSearchedQuery => (
              <Button
                key={mostSearchedQuery}
                variant="tagBtn"
                className="search-result-tags-items"
                onClick={e => {
                  trackSuggestion(`most-used`, mostSearchedQuery);
                  setSearchValue((e.target as Element).textContent);
                  goFor(mostSearchedQuery);
                }}
              >
                {mostSearchedQuery}
              </Button>
            ))}
          </div>
        </SearchResultTags>
        <SearchResultTags>
          <FlaglineText className="tagline-text">
            {t('search:inTrends')}
          </FlaglineText>

          {/*in-trend*/}
          <div className="search-result-tags search-trends">
            {searchTrends?.map(trendQuery => (
              <Button
                key={trendQuery}
                variant="tagBtn"
                className="search-result-tags-items"
                onClick={e => {
                  trackSuggestion(`in-trend`, trendQuery);
                  setSearchValue((e.target as Element).textContent);
                  goFor(trendQuery);
                }}
              >
                {trendQuery}
              </Button>
            ))}
          </div>
        </SearchResultTags>
      </SearchResultTagsWrapper>
      <SearchResultList>
        <div className="heading-wrap">
          <FlaglineText className="tagline-text">
            {t('search:recentSearched')}
          </FlaglineText>
        </div>

        {/*last-searched*/}
        {recentSearched?.length > 0 ? (
          <ul className="list">
            {recentSearched.map((record, index) => (
              <li key={index}>
                <RouterLink {...getTargetPageData(record.link)} passHref={true}>
                  <Link
                    onClick={() => {
                      trackSuggestion(`last-searched`, record.title);
                      clearSectionsPosition();
                    }}
                  >
                    {record.title}
                  </Link>
                </RouterLink>
              </li>
            ))}
          </ul>
        ) : null}
        {searchedLoading ? t('search:loading') : null}
        {!searchedLoading && hasMoreSearched ? (
          <Button
            variant="linkBtn"
            onClick={nextSearchedPage}
            icon={{
              name: 'chevron-long-right',
              size: 'sm',
            }}
          >
            {t('loadMore.button')}
          </Button>
        ) : null}
      </SearchResultList>

      <SearchResultList>
        <div className="heading-wrap">
          <FlaglineText className="tagline-text">
            {t('search:recentViewed')}
          </FlaglineText>
        </div>

        {/*last-seen*/}
        {recentViewed?.length > 0 ? (
          <ul className="list">
            {recentViewed.map((record, index) => (
              <li key={index}>
                <RouterLink {...getTargetPageData(record.link)} passHref={true}>
                  <Link
                    onClick={() => {
                      trackSuggestion(`last-seen`, record.title);
                    }}
                  >
                    {record.title}
                  </Link>
                </RouterLink>
              </li>
            ))}
          </ul>
        ) : null}
        {viewedLoading ? t('search:loading') : null}
        {!viewedLoading && hasMoreViewed ? (
          <Button
            variant="linkBtn"
            onClick={nextViewedPage}
            icon={{
              name: 'chevron-long-right',
              size: 'sm',
            }}
          >
            {t('loadMore.button')}
          </Button>
        ) : null}
      </SearchResultList>
    </SearchDetailsContent>
  );
};

export default SearchOverlayContent;
