export enum BookmarkBlockActionType {
  RESET,
  SET_VIEW,
}

export enum BookmarkBlockView {
  DEFAULT,
  NEW_BOOKMARK_GROUP,
  ADD_TO_BOOKMARK_GROUP,
  NEW_BOOKMARK_GROUP_BACK,
}

type BookmarkBlockActionPayload = {
  view: BookmarkBlockView;
};

type TBookmarkBlockAction = {
  type: BookmarkBlockActionType;
  payload?: BookmarkBlockActionPayload;
};

type TBookmarkBlockState = {
  view: BookmarkBlockView;
};

export const bookmarkBlockReducer = (
  state: TBookmarkBlockState,
  action: TBookmarkBlockAction
): TBookmarkBlockState => {
  switch (action.type) {
    case BookmarkBlockActionType.SET_VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    default:
      return {
        ...state,
        view: BookmarkBlockView.DEFAULT,
      };
  }
};

export const bookmarkBlockInitialState: TBookmarkBlockState = {
  view: BookmarkBlockView.DEFAULT,
};
