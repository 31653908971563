import { PageType } from '@hotelplan/platform-graphql-api';

export const StructuredDataBreadcrumbsPages: PageType[] = [
  PageType.GeoOverview,
  PageType.GeoObject,
  PageType.Pdp,
  PageType.ThemeOverview,
  PageType.ThemeObject,
  PageType.BlogOverview,
  PageType.BlogArticle,
  PageType.AgencyOverview,
  PageType.AgencyObject,
];
