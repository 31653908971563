import { useTranslation } from 'next-i18next';
import React from 'react';
import { useBookmarkModalPopupContext } from './BookmarkModalPopupContext';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentView,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './BookmarkPopup.styles';
import useRedirectToBookmarkPage from './useRedirectToBookmarkPage';

const BookmarkGroupShareModalPopup: React.FC = () => {
  const bookmarkModalPopupContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common', 'bookmark']);
  const { redirect: redirectToBookmarkPage } = useRedirectToBookmarkPage();

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark:group.share.replace.error.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            bookmarkModalPopupContext.close();
            redirectToBookmarkPage();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent view={BookmarkPopupContentView.COMPACT}>
        <BookmarkPopupTextRow error={true}>
          {t('bookmark:group.share.replace.error.text')}
        </BookmarkPopupTextRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkGroupShareModalPopup;
