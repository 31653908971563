import React from 'react';
import styled from 'styled-components';
import { InformationNotifications } from '@hotelplan/components.common.notifications';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useGetNotificationsQuery } from 'graphql/notifications/GetNotifications.generated';

const InformationNotificationsWrapper = styled.div(({ theme: { text } }) =>
  sx2CssThemeFn({
    width: '100%',
    maxWidth: '770px',
    mx: 'auto',
    '.inform-notification': {
      position: 'relative',
      marginTop: 4,
      borderRadius: '0px',
      bottom: 0,
      '.warning-notification-title': {
        marginBottom: '0px',
        mr: 3,
        ...text.groupHeading,
      },
    },
  })
);

const InformationNotificationsBanner = () => {
  const { data } = useGetNotificationsQuery();

  return (
    <InformationNotificationsWrapper>
      <InformationNotifications
        notificationData={data?.notificationData}
        className="inform-notification"
      />
    </InformationNotificationsWrapper>
  );
};

export default InformationNotificationsBanner;
