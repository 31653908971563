import throttle from 'lodash/throttle';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { optionsWithPassive } from '@hotelplan/libs.polyfills';
import { isBrowser } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const ScrollToTopButtonWrapper = styled.div(
  (props: { show: boolean }) => ({
    display: props.show ? 'block' : 'none',
  }),
  sx2CssThemeFn({
    position: 'fixed',
    zIndex: '3',
    bottom: ['72px', 4],
    right: [3, 4],
  })
);

const ScrollButton = styled(Button).attrs({
  testId: 'scroll-to-top',
  variant: 'iconBtn',
  icon: { name: 'scroll-to-top-icon' },
})(({ theme: { colors, media, space } }) => ({
  width: '42px',
  height: '42px',
  background: colors.paperGrey,
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: '5px',
  [media.mobile]: {
    marginBottom: space[2],
  },
  '&:hover, &:focus, &:focus-visible, &:active': {
    cursor: 'pointer',
    background: colors.paperGrey,
    border: `1px solid ${colors.black}`,
  },
  svg: {
    width: '20px',
    height: '20px',
    fill: 'transparent',
  },
}));

const ScrollToTopButton = () => {
  const [scrollToTopButton, setShowScrollToTopButton] = useState(false);
  const scrollToTopButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const onScroll = throttle(() => {
      setShowScrollToTopButton(window.scrollY > 20);
    }, 150);

    window.addEventListener('scroll', onScroll, optionsWithPassive);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <ScrollToTopButtonWrapper
      show={scrollToTopButton}
      ref={scrollToTopButtonRef}
    >
      <ScrollButton onClick={scrollToTop} />
    </ScrollToTopButtonWrapper>
  );
};

export default ScrollToTopButton;
