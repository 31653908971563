import { useAddSharedBookmarkGroupMutation } from 'graphql/bookmark/AddSharedBookmarkGroup.generated';

const useAddSharedBookmarkGroup = () => {
  const [
    addSharedBookmarkGroupMutation,
    {
      data: addSharedBookmarkGroupMutationData,
      loading: addSharedBookmarkGroupMutationLoading,
      error: addSharedBookmarkGroupMutationError,
    },
  ] = useAddSharedBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['GetBookmarkGroups'],
  });

  return {
    addSharedBookmarkGroupMutation,
    addSharedBookmarkGroupMutationData,
    addSharedBookmarkGroupMutationLoading,
    addSharedBookmarkGroupMutationError,
  };
};

export default useAddSharedBookmarkGroup;
