import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  AdaptiveCardsSlider,
  CardsSection,
} from 'components/domain/customizable-slider/CardsSection';
import usePaginationVariant from 'components/domain/customizable-slider/usePaginationVariant';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import {
  AgencyWithDetailsFragment,
  FullSearchResultFragment,
} from 'graphql/search/GetFulltextSearch.generated';
import searchCardPlaceholder, {
  geoSearchCardPlaceholder,
} from './ItemCardPlaceholder';
import { AllItemsButton, queriesBySectionKey } from './Search.common';
import { SectionKeyType } from './Search.types';
import SearchItem from './SearchItem';

const MIN_ALL_RESULTS_PAGE_ITEMS_COUNT = 4;
const DEFAULTS_RESULT_PER_PAGE = 3;
const DEFAULT_IMAGE_HEIGHT = 387;
export const SEARCH_SECTION_KEY_PREFIX = 'saved_section_';

const SearchCardsSection = styled(CardsSection)(
  sx2CssThemeFn({
    position: 'relative',
    mb: '20px',
    pt: '40px',
    '.media-card-content': {
      color: 'white',
    },
  })
);

const CardsSlider = styled(AdaptiveCardsSlider)(
  sx2CssThemeFn({
    mx: [-4, 0],
    pl: [4, 0],
  })
);

interface ISearchSectionProps {
  keywords: string[];
  sectionKey: SectionKeyType;
  result: FullSearchResultFragment | AgencyWithDetailsFragment;
  exactSearch?: boolean;
  routingMap?: React.ReactNode;
}

const CARDS_GAP = 20;

const getSection = (data, sectionKey) => data?.srl.fullTextSearch[sectionKey];

const getTotalResults = (data, sectionKey) =>
  getSection(data, sectionKey)?.page?.resultsTotal;

const getVariables = (
  page: number,
  keywords: string[],
  exactSearch?: boolean
) => {
  return {
    keywords,
    exactSearch,
    page: {
      pageNumber: page,
      resultsPerPage: DEFAULTS_RESULT_PER_PAGE,
    },
  };
};

export default function SearchSection({
  keywords,
  sectionKey,
  result,
  exactSearch,
  routingMap,
}: ISearchSectionProps): React.ReactElement {
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();
  const { query, pathname, push } = useRouter();
  const isSliderPagination = usePaginationVariant();

  const SESSION_KEY = useMemo(
    () => `${SEARCH_SECTION_KEY_PREFIX}${sectionKey}_page`,
    [sectionKey]
  );
  const storedPage = Number(sessionStorage.getItem(SESSION_KEY));

  const [page, setPage] = useState(storedPage);

  const [imageHeight, setImageHeight] = useState(DEFAULT_IMAGE_HEIGHT);

  useEffect(() => {
    setPage(!mobile ? Number(sessionStorage.getItem(SESSION_KEY)) || 0 : 0);
  }, [SESSION_KEY, keywords]);

  const [bothLoaded, setBothLoaded] = useState<boolean>(false);
  const getResults = useMemo(() => queriesBySectionKey[sectionKey], [
    sectionKey,
  ]);

  const { data, fetchMore } = getResults({
    variables: getVariables(0, keywords, exactSearch),
  });

  const {
    title,
    items,
    page: { resultsTotal },
  } = getSection(data, sectionKey) || result;

  const paginationNeeded =
    getTotalResults(data, sectionKey) > DEFAULTS_RESULT_PER_PAGE;

  const fetchItems = useCallback(
    (newPage: number, save = true) => {
      const firtsIndex = newPage * DEFAULTS_RESULT_PER_PAGE;
      const lastIndex = (newPage + 1) * DEFAULTS_RESULT_PER_PAGE;

      if (save) {
        setPage(newPage);
        sessionStorage.setItem(SESSION_KEY, `${newPage}`);
      }

      if (items.slice(firtsIndex, lastIndex).filter(Boolean).length > 0)
        return Promise.resolve(data);

      return fetchMore({
        variables: getVariables(newPage, keywords, exactSearch),
      });
    },
    [page, keywords, exactSearch, fetchMore, paginationNeeded, items]
  );

  useEffect(() => {
    // We need to request second page right after first due to BE search issues
    if (paginationNeeded) {
      fetchItems(storedPage || 1, false)?.then(otherData => {
        const results = getTotalResults(otherData.data, sectionKey);
        if (results === 0) {
          sessionStorage.setItem(SESSION_KEY, `0`);
          setPage(0);
        }
        setBothLoaded(true);
      });
    } else setBothLoaded(true);
  }, [exactSearch, fetchMore, keywords, paginationNeeded]);

  function onNextPage() {
    fetchItems(page + 1);
  }

  function onPrevPage() {
    fetchItems(page - 1);
  }

  function onChangePage(nextPage: number): void {
    fetchItems(nextPage);
  }

  const showAllResultBtn =
    !mobile && resultsTotal >= MIN_ALL_RESULTS_PAGE_ITEMS_COUNT;

  const placeholder =
    sectionKey === 'geo' ? geoSearchCardPlaceholder : searchCardPlaceholder;

  const handleImageHeight = useCallback((height: number) => {
    if (!height) return;
    setImageHeight(height);
  }, []);

  if (!items?.length) return null;

  return (
    <SearchCardsSection
      title={title}
      totalCount={resultsTotal}
      page={page}
      totalPages={Math.ceil(resultsTotal / DEFAULTS_RESULT_PER_PAGE)}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      paginationLoading={!bothLoaded}
      isSRL={true}
      isSliderPagination={isSliderPagination}
      imageHeight={imageHeight}
      trackingSource={PaginationArrowClickSource.SRL}
      routingMap={bothLoaded ? routingMap : null}
      headerStyle={{ height: '42px' }}
      underSectionNode={
        showAllResultBtn ? (
          <AllItemsButton
            onClick={() => {
              push({
                pathname: pathname,
                query: { ...query, section: sectionKey },
              });
            }}
          >
            {t('show.all.items', {
              section: title,
              keywords: keywords.join(' '),
            })}
          </AllItemsButton>
        ) : null
      }
    >
      <CardsSlider
        page={page}
        total={resultsTotal || items.length}
        perPage={DEFAULTS_RESULT_PER_PAGE}
        onChangePage={onChangePage}
        placeholder={placeholder}
        hideDelimiter={true}
        cardsGap={CARDS_GAP}
      >
        {items.map((item, index) =>
          item ? (
            <SearchItem
              key={index}
              sectionKey={sectionKey}
              getImageHeight={
                index === page * DEFAULTS_RESULT_PER_PAGE
                  ? handleImageHeight
                  : undefined
              }
              lazy={index + 1 > DEFAULTS_RESULT_PER_PAGE}
              className={`srl-${sectionKey}-${index + 1}`} // For tracking purposes. Should we use UID prefix?
              {...item}
            />
          ) : null
        )}
      </CardsSlider>
    </SearchCardsSection>
  );
}

export function SearchSectionPlaceholder(): ReactElement {
  return (
    <SearchCardsSection
      page={0}
      totalPages={0}
      loading={true}
      isSRL={true}
      headerStyle={{ height: '42px' }}
    >
      <CardsSlider
        page={0}
        total={3}
        perPage={DEFAULTS_RESULT_PER_PAGE}
        placeholder={searchCardPlaceholder}
      />
    </SearchCardsSection>
  );
}
