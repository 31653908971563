import throttle from 'lodash/throttle';
import { useEffect, MutableRefObject } from 'react';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { useSelfUpdatingRef } from '@hotelplan/libs.hooks-react';

export function useScrollPosition(
  wrapperRef: MutableRefObject<HTMLDivElement>,
  firstItemRef: MutableRefObject<HTMLDivElement>,
  onChangePage: (page: number) => void,
  cardsGap: number,
  perPage: number,
  page: number
): void {
  const { w: width } = useElementSize(wrapperRef);
  const currentPage = useSelfUpdatingRef(page);

  useEffect(
    function watchScrollPositionEffect() {
      const wrapperEl = wrapperRef.current;
      const firstItemEl = firstItemRef.current;

      if (!wrapperEl || !onChangePage) return;

      const onScroll = throttle(() => {
        const itemWidth = firstItemEl.offsetWidth;
        const rightSide = wrapperEl.scrollLeft + width;
        const cardToShow = Math.floor(rightSide / (itemWidth + cardsGap) + 1);
        const pageToShow = Math.floor(cardToShow / perPage);
        if (currentPage.current !== pageToShow) {
          onChangePage(pageToShow);
        }
      }, 300);

      wrapperEl.addEventListener('scroll', onScroll, { passive: true });

      return function positionEventCleanup() {
        wrapperEl.removeEventListener('scroll', onScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wrapperRef, width, perPage, currentPage]
  );
}
