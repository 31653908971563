import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { Status } from '@hotelplan/platform-graphql-api';
import { useBookmarkModalPopupContext } from 'components/domain/bookmark/BookmarkModalPopupContext';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentRow,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './BookmarkPopup.styles';
import BookmarkPopupNewGroupForm, {
  IBookmarkPopupNewGroupFormState,
} from './BookmarkPopupNewGroupForm';
import { useCreateBookmarkGroup } from './useCreateBookmarkGroup';

const BookmarkGroupCreateModalPopup: React.FC = () => {
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common']);
  const { createBookmarkGroup } = useCreateBookmarkGroup();

  const onSubmit = useCallback((values: IBookmarkPopupNewGroupFormState) => {
    createBookmarkGroup(values.name).then(response => {
      if (response?.data?.createBookmarkGroup?.status === Status.Success) {
        bookmarkModalContext.close();
      }
    });
  }, []);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.group.create.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            bookmarkModalContext.close();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupTextRow>
          {t('bookmark.group.create.text')}:
        </BookmarkPopupTextRow>
        <BookmarkPopupContentRow>
          <BookmarkPopupNewGroupForm
            onSubmit={onSubmit}
            onCancel={() => {
              bookmarkModalContext.close();
            }}
          />
        </BookmarkPopupContentRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default BookmarkGroupCreateModalPopup;
