import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';

const useRedirectToBookmarkPage = () => {
  const [, { language }] = useTranslation();
  const { push } = useRouter();
  const [loading, setLoading] = useState(false);

  const redirect = useCallback(() => {
    const route = getRouteByPageTypeLocale(PageType.Bookmark, language);

    if (!route || loading) return;

    setLoading(true);

    const uri = route.builder({});

    const routerProps = getTargetPageData({
      targetPageType: route.pageType,
      uri,
    });

    push(route.page, uri, {
      shallow: false,
      scroll: true,
      locale: routerProps.locale,
    }).then(() => {
      setLoading(false);
    });
  }, [language]);

  return {
    redirect,
    loading,
  };
};

export default useRedirectToBookmarkPage;
