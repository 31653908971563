import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { ScheduleOpeningStatus } from '@hotelplan/components.common.help-overlay';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import {
  useGetFulltextSearchAdventureTravelProductsQuery,
  useGetFulltextSearchAgenciesQuery,
  useGetFulltextSearchBlogArticlesQuery,
  useGetFulltextSearchExtraHotelsQuery,
  useGetFulltextSearchGeoQuery,
  useGetFulltextSearchOtherQuery,
  useGetFulltextSearchRegularHotelsQuery,
  useGetFulltextSearchRoundTripProductsQuery,
  useGetFulltextSearchThemesQuery,
} from 'graphql/search/GetFulltextSearch.generated';
import { SectionKeyType } from './Search.types';

export const B2B_ONLY_SECTIONS = [`roundtrip`, `regularHotels`];

export const B2B_KEYS: Array<SectionKeyType> = [
  `adventuretravel`,
  `roundtrip`,
  `extraHotels`,
  `regularHotels`,
  `geo`,
  `themes`,
  `blogArticles`,
  `other`,
  `agenciesWithDetails`,
];

export const B2C_KEYS: Array<SectionKeyType> = [
  `adventuretravel`,
  `extraHotels`,
  `regularHotels`,
  `geo`,
  `themes`,
  `roundtrip`,
  `agenciesWithDetails`,
  `blogArticles`,
  `other`,
];

export const queriesBySectionKey = {
  geo: useGetFulltextSearchGeoQuery,
  themes: useGetFulltextSearchThemesQuery,
  agenciesWithDetails: useGetFulltextSearchAgenciesQuery,
  adventuretravel: useGetFulltextSearchAdventureTravelProductsQuery,
  extraHotels: useGetFulltextSearchExtraHotelsQuery,
  regularHotels: useGetFulltextSearchRegularHotelsQuery,
  roundtrip: useGetFulltextSearchRoundTripProductsQuery,
  blogArticles: useGetFulltextSearchBlogArticlesQuery,
  other: useGetFulltextSearchOtherQuery,
};

export const AllItemsButton = styled(Button).attrs({
  variant: 'link-btn',
  icon: { name: 'chevron-long-right', size: 'lg' },
})<{ back?: boolean }>(({ back }) =>
  sx2CssThemeFn({
    display: 'flex',
    color: 'redWeb',
    fontWeight: '500',
    fontSize: '16px',
    position: 'relative',
    py: back ? 6 : '10px',
    paddingTop: '22px',
    alignItems: 'center',
    '.icon': {
      width: '22px',
      height: '22px',
      mr: '10px',
      fill: 'redWeb',
      transform: `rotate(${back ? '180' : '0'}deg)`,
    },
    '&::after': {
      position: 'absolute',
      bottom: back ? '28px' : '6px',
      right: 0,
      display: 'block',
      height: '2px',
      content: '""',
      width: 'calc(100% - 32px)',
      backgroundColor: 'redWeb',
      opacity: 0.2,
      transition: '0.2s ease-in',
    },
    '&::before': {
      position: 'absolute',
      bottom: back ? '28px' : '6px',
      left: '32px',
      display: 'block',
      height: '2px',
      content: '""',
      width: '2px',
      backgroundColor: 'redWeb',
    },

    '&:hover': {
      color: 'redHover',
      '.icon': {
        fill: 'redHover',
      },
      '&::after': {
        opacity: 1,
        backgroundColor: 'redHover',
      },
    },

    '&:active, &:focus': {
      color: 'redActive',
      '.icon': {
        fill: 'redActive',
      },
      '&::after': {
        opacity: 1,
        backgroundColor: 'redActive',
      },
    },
  })
);

export const TeaserDescription = styled.div<{
  imageHeight?: number;
  leadInfoCutLines?: number;
}>(
  ({ imageHeight, leadInfoCutLines }) =>
    sx2CssThemeFn({
      my: '10px',
      display: 'flex',
      height: `calc(100% - ${imageHeight + 20}px)`,
      justifyContent: 'space-between',
      '.icon': {
        alignSelf: 'flex-end',
        color: 'redWeb',
        minWidth: '25px',
      },
      '.description-text': { ...cutTextByLinesCount(3) },
      '.main-text': { ...cutTextByLinesCount(3) },
      '.search-item-breadcrumbs': {
        ...cutTextByLinesCount(2),
        marginBottom: '10px',
        paddingTop: '10px',
        fontSize: '15px',
        fontWeight: '500',
      },
      '.lead-info': { ...cutTextByLinesCount(leadInfoCutLines || 2) },
    }),
  {
    overflow: 'hidden',
    b: {
      fontWeight: '700',
    },
  }
);

export const OpeningStatus = styled(ScheduleOpeningStatus)<{ opened: boolean }>(
  ({ opened }) =>
    sx2CssThemeFn({
      ml: '20px',
      mt: 1,
      fontSize: [1, 2],
      fontWeight: [null, 'bold'],
      color: opened ? 'success' : 'redWeb',
      '&:before': {
        width: '10px',
        height: '10px',
        left: -20,
      },
    })
);

export const SearchPageContainer = styled(Container)(
  ({ theme: { media, space } }) => ({
    paddingTop: '214px',
    overflow: 'hidden',
    [media.mobile]: {
      overflow: 'visible',
      paddingTop: '180px',
    },
    '.load-more-wrapper': {
      margin: '-20px auto',
      marginBottom: space[7],
    },
  })
);
