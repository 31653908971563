import React from 'react';
import styled from 'styled-components';
import { DisrupterObject } from '@hotelplan/graphql.types';

const DisruptersWrapper = styled.div(({ theme: { media, space } }) => ({
  width: '100%',
  maxWidth: '1480px!important',
  left: 0,
  right: 0,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  margin: `0 auto ${space[2]} auto`,
  [media.tablet]: {
    maxWidth: '1480px',
  },
}));

const DisrupterBody = styled.div<{ color: string; backgroundColor: string }>(
  ({ color, backgroundColor }) => ({
    minHeight: '45px',
    padding: '5px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color,
    backgroundColor,
    fontSize: '12px',
    fontWeight: 900,
  })
);

export const DisruptersSection: React.FC<{
  disrupters: DisrupterObject[];
  className?: string;
}> = ({ disrupters, className }) => {
  return (
    <DisruptersWrapper className={className}>
      {disrupters.map((item, index) => {
        return <Disrupter {...item} key={`disrupter-${index}`} />;
      })}
    </DisruptersWrapper>
  );
};

export const Disrupter: React.FC<DisrupterObject> = ({
  text = `disrupter`,
  fontColor,
  backgroundColor,
}) => {
  return (
    <DisrupterBody
      color={fontColor}
      backgroundColor={backgroundColor}
      className="disrupter"
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </DisrupterBody>
  );
};
