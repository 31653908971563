import { Image, InternalLink, PageType } from '@hotelplan/platform-graphql-api';

export const bookmarkablePageTypes: PageType[] = [
  PageType.Pdp,
  PageType.GeoObject,
  PageType.ThemeObject,
];

export interface IBookmarkItemState {
  id: string;
  pageType: PageType;
}

export interface IBookmarkGroupState {
  uuid: string;
  id: string;
  loading: boolean;
  items: IBookmarkItemState[];
  image?: Image;
  name?: string;
  shareUrl?: InternalLink;
}

export interface IBookmarkContextState {
  loading: boolean;
  bookmarkGroups: IBookmarkGroupState[];
  count: number;
  initialized: boolean;
}

export type TBookmarkInput = {
  objectId: string;
  pageType: PageType;
  groupId: string;
};

export type TAddBookmarkInput = Omit<TBookmarkInput, 'groupId'> & {
  groupId?: string;
};

export type TAddBookmarkToNewGroupInput = Omit<TBookmarkInput, 'groupId'> & {
  groupName: string;
};

export interface IBookmarkContext extends IBookmarkContextState {
  addBookmark(bookmark: TAddBookmarkInput): void;
  addBookmarkToNewGroup(
    bookmark: TAddBookmarkToNewGroupInput
  ): Promise<TBookmarkInput | undefined>;
  removeBookmark(bookmark: TBookmarkInput): void;
}

export enum BookmarkContextActionType {
  'ADD_BOOKMARK_ITEM',
  'REMOVE_BOOKMARK_ITEM',
  'SET_BOOKMARK_GROUPS',
  'SET_BOOKMARK_CONTEXT_LOADING',
  'SET_BOOKMARK_GROUPS_ITEMS',
  'SET_BOOKMARK_GROUPS_ITEMS_LOADING',
  'SET_BOOKMARK_COUNT',
  'INITIALIZE',
}

export type TBookmarkContextAction =
  | {
      type: BookmarkContextActionType.ADD_BOOKMARK_ITEM;
      payload: TBookmarkInput;
    }
  | {
      type: BookmarkContextActionType.REMOVE_BOOKMARK_ITEM;
      payload: TBookmarkInput;
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_GROUPS;
      payload: {
        id: string;
        image?: Image;
        name: string;
        shareUrl?: InternalLink;
      }[];
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_CONTEXT_LOADING;
      payload: boolean;
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS;
      payload: { id: string; items: { id: string; pageType: PageType }[] };
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS_LOADING;
      payload: { id: string; loading: boolean };
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_COUNT;
      payload: number;
    }
  | { type: BookmarkContextActionType.INITIALIZE };
