import { useRouter } from 'next/router';
import { useState } from 'react';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { useGetFulltextSearchLinkLazyQuery } from 'graphql/search/GetFulltextSearchLink.generated';

const useGetFulltextSearchLink = (onCompleted?: () => void) => {
  const [sectionKeys, setSectionKeys] = useState<string[]>([]);
  const router = useRouter();

  const [requestLink] = useGetFulltextSearchLinkLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(searchLinkData) {
      const { as, href } = getTargetPageData(
        searchLinkData.srl.fullTextSearch.link
      );
      const hashString = sectionKeys?.length ? '#' + sectionKeys.join(',') : '';

      router.push(href + hashString, as + hashString);
      onCompleted?.();
    },
  });

  return {
    requestLink,
    setSectionKeys,
  };
};

export default useGetFulltextSearchLink;
