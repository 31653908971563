import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { DisrupterFragmentDoc } from 'graphql/fragments/Disrupter.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetBookmarkItemsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  groupId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetBookmarkItemsQuery = {
  __typename?: 'Query';
  bookmarkPage: {
    __typename?: 'BookmarkPage';
    bookmarkItems: Array<{
      __typename?: 'BookmarkItem';
      name: string;
      leadText?: string | null;
      bookmarkType: Types.BookmarkType;
      link: {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
        objectId?: string | null;
      };
      disrupters: Array<{
        __typename?: 'DisrupterObject';
        text: string;
        fontColor?: string | null;
        backgroundColor?: string | null;
      }>;
      image?: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      } | null;
      googleStaticMapImage?: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      } | null;
      productInfoB2B?: {
        __typename?: 'ProductInfoB2B';
        priceFrom?: string | null;
        duration: string;
        route: string;
        typeFlags?: Array<string> | null;
      } | null;
    } | null>;
  };
};

export const GetBookmarkItemsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBookmarkItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookmarkPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarkItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'groupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leadText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'internalLinkFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disrupters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'disrupter' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'criteria' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_mobile',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '328' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '272' },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_desktop',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '468' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '388' },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ResizedImageWithMeta',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bookmarkType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'googleStaticMapImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'criteria' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_desktop',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '468' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '388' },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_mobile',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '380' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '274' },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ResizedImageWithMeta',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productInfoB2B' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priceFrom' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'route' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'typeFlags' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...DisrupterFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetBookmarkItemsQuery__
 *
 * To run a query within a React component, call `useGetBookmarkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkItemsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetBookmarkItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBookmarkItemsQuery,
    GetBookmarkItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetBookmarkItemsQuery,
    GetBookmarkItemsQueryVariables
  >(GetBookmarkItemsDocument, options);
}
export function useGetBookmarkItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBookmarkItemsQuery,
    GetBookmarkItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetBookmarkItemsQuery,
    GetBookmarkItemsQueryVariables
  >(GetBookmarkItemsDocument, options);
}
export type GetBookmarkItemsQueryHookResult = ReturnType<
  typeof useGetBookmarkItemsQuery
>;
export type GetBookmarkItemsLazyQueryHookResult = ReturnType<
  typeof useGetBookmarkItemsLazyQuery
>;
export type GetBookmarkItemsQueryResult = Apollo.QueryResult<
  GetBookmarkItemsQuery,
  GetBookmarkItemsQueryVariables
>;
