import React, { useState } from 'react';
import { FullTextSearchItem } from '@hotelplan/platform-graphql-api';

export const useSearchAutoSuggestKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export interface IAutoSuggestItem {
  index: number;
  item: FullTextSearchItem;
  type: 'product' | 'nonProduct';
}

export const getNextAutoSuggestItem = (
  active: IAutoSuggestItem,
  list: IAutoSuggestItem[]
): IAutoSuggestItem => {
  if (!active) {
    return list.length ? list[0] : null;
  }

  if (list[active.index + 1]) {
    return list[active.index + 1];
  }

  if (list.length) {
    return list[0];
  }

  return null;
};

export const getPrevAutoSuggestItem = (
  active: IAutoSuggestItem,
  list: IAutoSuggestItem[]
): IAutoSuggestItem => {
  if (!active) {
    return list.length ? list[list.length - 1] : null;
  }

  if (list[active.index - 1]) {
    return list[active.index - 1];
  }

  if (list.length) {
    return list[list.length - 1];
  }

  return null;
};

/** normalizes text input and removes diacritics **/
const normalize = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/gu, '')
    .toLowerCase();
};

export const getSearchTermPosition = (text: string, term: string) => {
  const normalizedText = normalize(text);
  const normalizedTerm = normalize(term);
  const startIndex = normalizedText.indexOf(normalizedTerm);

  if (startIndex > -1) {
    return [
      startIndex,
      normalizedText.indexOf(normalizedTerm) + normalizedTerm.length - 1,
    ];
  }

  return null;
};
