import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { IconMenuEntryFragmentDoc } from './IconMenuEntry.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
import { TextMenuEntryFragmentDoc } from './TextMenuEntry.generated';
import { ContactsFragmentDoc } from './Contacts.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFooterQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFooterQuery = {
  __typename?: 'Query';
  pageFooter: {
    __typename?: 'PageFooterData';
    socialMedia: Array<{
      __typename?: 'IconMenuItem';
      caption: string;
      iconId: string;
      icon: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      };
      link?:
        | {
            __typename?: 'ExternalLink';
            openMethod: Types.OpenLinkMethod;
            url: string;
            caption?: string | null;
          }
        | {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          }
        | null;
    }>;
    navigationMenu: Array<
      | {
          __typename?: 'Menu';
          caption: string;
          target?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
                objectId?: string | null;
              }
            | null;
          entries: Array<{
            __typename?: 'TextMenuItem';
            caption: string;
            link:
              | {
                  __typename?: 'ExternalLink';
                  openMethod: Types.OpenLinkMethod;
                  url: string;
                  caption?: string | null;
                }
              | {
                  __typename?: 'InternalLink';
                  caption?: string | null;
                  uri: string;
                  targetPageType: Types.PageType;
                  objectId?: string | null;
                };
          }>;
        }
      | {
          __typename?: 'TextMenuItem';
          caption: string;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
                objectId?: string | null;
              };
        }
    >;
    paymentMethods: Array<{
      __typename?: 'IconMenuItem';
      caption: string;
      iconId: string;
      icon: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      };
      link?:
        | {
            __typename?: 'ExternalLink';
            openMethod: Types.OpenLinkMethod;
            url: string;
            caption?: string | null;
          }
        | {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          }
        | null;
    }>;
    reasonsOfConfidence?: {
      __typename?: 'ReasonsOfConfidence';
      text?: string | null;
      title?: string | null;
    } | null;
    contactInformation?: {
      __typename?: 'ContactInformation';
      emailAddress?: string | null;
      emailTitle?: string | null;
      mainTitle?: string | null;
      openingHours?: string | null;
      phoneNumber?: string | null;
      phoneTitle?: string | null;
    } | null;
    brandLogos?: Array<{
      __typename?: 'IconMenuItem';
      caption: string;
      iconId: string;
      icon: {
        __typename?: 'Image';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      };
      link?:
        | {
            __typename?: 'ExternalLink';
            openMethod: Types.OpenLinkMethod;
            url: string;
            caption?: string | null;
          }
        | {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
            objectId?: string | null;
          }
        | null;
    }> | null;
    logo?: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  };
};

export type FooterFragment = {
  __typename?: 'PageFooterData';
  socialMedia: Array<{
    __typename?: 'IconMenuItem';
    caption: string;
    iconId: string;
    icon: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        }
      | null;
  }>;
  navigationMenu: Array<
    | {
        __typename?: 'Menu';
        caption: string;
        target?:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
              objectId?: string | null;
            }
          | null;
        entries: Array<{
          __typename?: 'TextMenuItem';
          caption: string;
          link:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
                objectId?: string | null;
              };
        }>;
      }
    | {
        __typename?: 'TextMenuItem';
        caption: string;
        link:
          | {
              __typename?: 'ExternalLink';
              openMethod: Types.OpenLinkMethod;
              url: string;
              caption?: string | null;
            }
          | {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
              objectId?: string | null;
            };
      }
  >;
  paymentMethods: Array<{
    __typename?: 'IconMenuItem';
    caption: string;
    iconId: string;
    icon: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        }
      | null;
  }>;
  reasonsOfConfidence?: {
    __typename?: 'ReasonsOfConfidence';
    text?: string | null;
    title?: string | null;
  } | null;
  contactInformation?: {
    __typename?: 'ContactInformation';
    emailAddress?: string | null;
    emailTitle?: string | null;
    mainTitle?: string | null;
    openingHours?: string | null;
    phoneNumber?: string | null;
    phoneTitle?: string | null;
  } | null;
  brandLogos?: Array<{
    __typename?: 'IconMenuItem';
    caption: string;
    iconId: string;
    icon: {
      __typename?: 'Image';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
    link?:
      | {
          __typename?: 'ExternalLink';
          openMethod: Types.OpenLinkMethod;
          url: string;
          caption?: string | null;
        }
      | {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
          objectId?: string | null;
        }
      | null;
  }> | null;
  logo?: {
    __typename?: 'Image';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  } | null;
};

export const FooterFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'footer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageFooterData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'iconMenuEntry' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'navigationMenu' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Menu' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'target' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'linkFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'textMenuEntry' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TextMenuItem' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'textMenuEntry' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'iconMenuEntry' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonsOfConfidence' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'text' },
                  name: { kind: 'Name', value: 'mainText' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'title' },
                  name: { kind: 'Name', value: 'mainTitle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contacts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brandLogos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '100' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '40' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '180' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '60' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'linkFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '226' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '72' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '226' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '72' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...IconMenuEntryFragmentDoc.definitions,
    ...LinkFragmentFragmentDoc.definitions,
    ...TextMenuEntryFragmentDoc.definitions,
    ...ContactsFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetFooterDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFooter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageFooter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'footer' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FooterFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFooterQuery__
 *
 * To run a query within a React component, call `useGetFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFooterQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFooterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFooterQuery,
    GetFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetFooterQuery, GetFooterQueryVariables>(
    GetFooterDocument,
    options
  );
}
export function useGetFooterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFooterQuery,
    GetFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetFooterQuery, GetFooterQueryVariables>(
    GetFooterDocument,
    options
  );
}
export type GetFooterQueryHookResult = ReturnType<typeof useGetFooterQuery>;
export type GetFooterLazyQueryHookResult = ReturnType<
  typeof useGetFooterLazyQuery
>;
export type GetFooterQueryResult = Apollo.QueryResult<
  GetFooterQuery,
  GetFooterQueryVariables
>;
