import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link } from '@hotelplan/platform-graphql-api';
import { useGclIdValue } from './useGclIdValue';

export const GCLID_COOKIE_KEY = '_gcl_aw';

export const useGetClientGclidValue = (
  ssrGclid?: string | undefined,
  onlyClient?: boolean
): string | undefined => {
  const [gclid, setGclid] = useState(ssrGclid);

  const getClientGclidValue = () => {
    const clientGclId = Cookies.get(GCLID_COOKIE_KEY);

    if (clientGclId) {
      setGclid(clientGclId);
    }
  };

  useEffect(() => {
    if (gclid || typeof window === 'undefined' || onlyClient) return;

    window.addEventListener('load', getClientGclidValue);

    return () => {
      window.removeEventListener('load', getClientGclidValue);
    };
  }, [ssrGclid, gclid, onlyClient]);

  if (onlyClient) {
    return Cookies.get(GCLID_COOKIE_KEY);
  }

  return gclid;
};

export const addGclIdQueryToJotformUrl = (
  url: string,
  gclidValue: string | undefined
): string => {
  if (!gclidValue) return url;

  const gclid = gclidValue.split('.')[2];

  const prefix = /[?]/.test(url) ? '&' : '?';
  const gclidQuery = `${prefix}gclid=${gclid}`;

  return url + gclidQuery;
};

export const getLinkWithJotform = <T extends Link>(
  link: T,
  gclidValue: string | undefined
): T => {
  if (!link) return undefined;
  if (link.__typename !== 'ExternalLink') return link;

  const href = link?.url;

  if (!/jotform/.test(href) || !gclidValue) return link;

  const url = addGclIdQueryToJotformUrl(href, gclidValue);

  return {
    ...link,
    url,
  };
};

export const useGclid = (): string | undefined => {
  const ssrGclid = useGclIdValue();
  return useGetClientGclidValue(ssrGclid);
};

export const useGetLinkWithJotform = <T extends Link>(
  link: T,
  providedGclid?: string
): T => {
  const gclid = useGclid();
  return getLinkWithJotform(link, providedGclid || gclid);
};

export const useGetLinkUrlWithJotform = (url: string): string => {
  const gclid = useGclid();
  return addGclIdQueryToJotformUrl(url, gclid);
};
