import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { PageType } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useBookmarkContext } from 'components/domain/bookmark/BookmarkContext';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

export const WishlistIconCounter = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: [null, 'column'],
    alignItems: 'center',
    fontSize: '0px',
    color: 'lightGreySecond',
    fontWeight: 'bold',
    marginRight: 3,
    '.icon': {
      width: '18px',
      height: '18px',
      my: '0',
      transform: 'none',
      color: 'redWeb',
      fill: 'transparent',
    },
    '.counter': {
      color: 'black',
      fontSize: '10px',
      fontWeight: 'normal',
      lineHeight: '11.5px',
      textAlign: 'left',
      position: 'absolute',
      top: '52%',
      right: '-12px',
      transform: 'translateY(-50%)',
      letterSpacing: '0px',
      width: '19px',
      backgroundColor: 'paperGrey',
      padding: '0 1px',
    },
  })
);

const MobileWishlistMenuItem: React.FC = () => {
  const [t, { language }] = useTranslation('bookmark');

  const routerProps = useMemo(() => {
    return getRouteByPageTypeLocale(PageType.Bookmark, language);
  }, [language]);

  const bookmarkContext = useBookmarkContext();

  return (
    <Link href={routerProps.match}>
      <SiteToolsButtonsWrapper className="wishlistSiteTool">
        <WishlistIconCounter>
          <Icon name="wishlist-icon" />
          {bookmarkContext.count > 0 && (
            <span className="counter">{bookmarkContext.count}</span>
          )}
        </WishlistIconCounter>
        <span className="icon-label">{t('bookmarkLink')}</span>
      </SiteToolsButtonsWrapper>
    </Link>
  );
};

export default MobileWishlistMenuItem;
