import noop from 'lodash/noop';
import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { PageType } from '@hotelplan/platform-graphql-api';

export enum BookmarkModalPopupType {
  ADD_BOOKMARK,
  EDIT_BOOKMARK_GROUP,
  REMOVE_BOOKMARK_GROUP,
  EDIT_BOOKMARK,
  CREATE_BOOKMARK_GROUP,
  SHARE_BOOKMARK_GROUP_REPLACE,
  SHARE_BOOKMARK_GROUP_ERROR,
}

export type TAddBookmarkModalPopup = {
  type: BookmarkModalPopupType.ADD_BOOKMARK;
  payload: {
    pageType: PageType;
    objectId: string;
  };
};

export type TEditBookmarkGroupModalPopup = {
  type: BookmarkModalPopupType.EDIT_BOOKMARK_GROUP;
  payload: {
    groupId: string;
    name: string;
  };
};

export type TRemoveBookmarkGroupModalPopup = {
  type: BookmarkModalPopupType.REMOVE_BOOKMARK_GROUP;
  payload: {
    groupId: string;
    name: string;
  };
};

export type TEditBookmarkModalPopup = {
  type: BookmarkModalPopupType.EDIT_BOOKMARK;
  payload: {
    pageType: PageType;
    objectId: string;
    groupId: string;
  };
};

export type TCreateBookmarkModalPopup = {
  type: BookmarkModalPopupType.CREATE_BOOKMARK_GROUP;
};

export type TShareBookmarkGroupReplace = {
  type: BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_REPLACE;
  payload: {
    bookmarkGroup: string;
  };
};

export type TShareBookmarkGroupError = {
  type: BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_ERROR;
};

export type TBookmarkModalPopupType =
  | TAddBookmarkModalPopup
  | TEditBookmarkGroupModalPopup
  | TRemoveBookmarkGroupModalPopup
  | TEditBookmarkModalPopup
  | TCreateBookmarkModalPopup
  | TShareBookmarkGroupReplace
  | TShareBookmarkGroupError;

export interface IBookmarkModalPopupContext {
  modal: TBookmarkModalPopupType | undefined;
  isOpen: boolean;
  open(modal: TBookmarkModalPopupType): void;
  close(): void;
}

const bookmarkModalPopUpInitialContext: IBookmarkModalPopupContext = {
  modal: undefined,
  isOpen: false,
  open: noop,
  close: noop,
};

const BookmarkModalPopupContext = React.createContext<IBookmarkModalPopupContext>(
  bookmarkModalPopUpInitialContext
);

export function BookmarkModalPopupContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [type, setType] = useState<TBookmarkModalPopupType>(undefined);

  const handlers = useMemo(
    () => ({
      open: (modal: TBookmarkModalPopupType) => {
        setIsOpen(true);
        setType(modal);
      },
      close: () => {
        setIsOpen(false);
      },
    }),
    []
  );

  const context: IBookmarkModalPopupContext = useMemo(
    () => ({
      isOpen,
      modal: type,
      ...handlers,
    }),
    [handlers, isOpen, type]
  );

  return (
    <BookmarkModalPopupContext.Provider value={context}>
      {children}
    </BookmarkModalPopupContext.Provider>
  );
}

export function useBookmarkModalPopupContext() {
  return useContext(BookmarkModalPopupContext);
}
