import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type AddBookmarkItemsMutationVariables = Types.Exact<{
  bookmarks:
    | Array<Types.BookmarkRequestItemInput>
    | Types.BookmarkRequestItemInput;
}>;

export type AddBookmarkItemsMutation = {
  __typename?: 'Mutation';
  addBookmarks: { __typename?: 'BookmarkBulkResponse'; status: Types.Status };
};

export const AddBookmarkItemsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBookmarkItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bookmarks' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'BookmarkRequestItemInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bookmarks' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bookmarks' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type AddBookmarkItemsMutationFn = Apollo.MutationFunction<
  AddBookmarkItemsMutation,
  AddBookmarkItemsMutationVariables
>;

/**
 * __useAddBookmarkItemsMutation__
 *
 * To run a mutation, you first call `useAddBookmarkItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookmarkItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookmarkItemsMutation, { data, loading, error }] = useAddBookmarkItemsMutation({
 *   variables: {
 *      bookmarks: // value for 'bookmarks'
 *   },
 * });
 */
export function useAddBookmarkItemsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddBookmarkItemsMutation,
    AddBookmarkItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddBookmarkItemsMutation,
    AddBookmarkItemsMutationVariables
  >(AddBookmarkItemsDocument, options);
}
export type AddBookmarkItemsMutationHookResult = ReturnType<
  typeof useAddBookmarkItemsMutation
>;
export type AddBookmarkItemsMutationResult = Apollo.MutationResult<AddBookmarkItemsMutation>;
export type AddBookmarkItemsMutationOptions = Apollo.BaseMutationOptions<
  AddBookmarkItemsMutation,
  AddBookmarkItemsMutationVariables
>;
