import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetBoostersQueryVariables = Types.Exact<{
  pageType: Types.PageType;
  exclude?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  objectId?: Types.InputMaybe<Types.Scalars['String']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetBoostersQuery = {
  __typename?: 'Query';
  boosters: Array<{
    __typename?: 'Booster';
    delay?: number | null;
    id: string;
    text: string;
    duration?: number | null;
    frequency: Types.BoosterFrequency;
  }>;
};

export const GetBoostersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBoosters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exclude' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boosters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'exclude' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'exclude' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetBoostersQuery__
 *
 * To run a query within a React component, call `useGetBoostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoostersQuery({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      exclude: // value for 'exclude'
 *      objectId: // value for 'objectId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetBoostersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetBoostersQuery,
    GetBoostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBoostersQuery, GetBoostersQueryVariables>(
    GetBoostersDocument,
    options
  );
}
export function useGetBoostersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBoostersQuery,
    GetBoostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetBoostersQuery,
    GetBoostersQueryVariables
  >(GetBoostersDocument, options);
}
export type GetBoostersQueryHookResult = ReturnType<typeof useGetBoostersQuery>;
export type GetBoostersLazyQueryHookResult = ReturnType<
  typeof useGetBoostersLazyQuery
>;
export type GetBoostersQueryResult = Apollo.QueryResult<
  GetBoostersQuery,
  GetBoostersQueryVariables
>;
