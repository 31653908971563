import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { BookmarkGroup, Status } from '@hotelplan/platform-graphql-api';
import {
  TEditBookmarkModalPopup,
  useBookmarkModalPopupContext,
} from 'components/domain/bookmark/BookmarkModalPopupContext';
import {
  trackEditBookmarkCopy,
  trackEditBookmarkDelete,
  trackEditBookmarkMove,
} from 'components/domain/tracking/bookmarkTracking';
import { useCopyBookmarkMutation } from 'graphql/bookmark/CopyBookmark.generated';
import { useGetBookmarkGroupsQuery } from 'graphql/bookmark/GetBookmarkGroups.generated';
import { useMoveBookmarkMutation } from 'graphql/bookmark/MoveBookmark.generated';
import { useBookmarkContext } from './BookmarkContext';
import BookmarkEditDefaultPopup from './BookmarkEditDefaultPopup';
import {
  BookmarkEditModalActionType,
  bookmarkEditModalInitialState,
  bookmarkEditModalReducer,
  BookmarkEditModalView,
} from './BookmarkEditModal.utils';
import BookmarkEditRemovePopup from './BookmarkEditRemovePopup';
import BookmarkEditTextPopup from './BookmarkEditTextPopup';

const BookmarkEditModal: React.FC = () => {
  const [t, { language }] = useTranslation(['common']);
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const modal = bookmarkModalContext.modal as TEditBookmarkModalPopup;
  const [bookmarkEditModalState, dispatch] = useReducer(
    bookmarkEditModalReducer,
    bookmarkEditModalInitialState
  );
  const {
    data: bookmarkGroupsData,
    loading: bookmarkGroupsLoading,
    refetch: refetchBookmarkGroups,
  } = useGetBookmarkGroupsQuery();
  const [
    moveBookmark,
    { loading: moveBookmarkLoading },
  ] = useMoveBookmarkMutation();
  const [
    copyBookmark,
    { loading: copyBookmarkLoading },
  ] = useCopyBookmarkMutation();
  const [loading, setLoading] = useState(false);
  const bookmarkContext = useBookmarkContext();

  useEffect(() => {
    setLoading(moveBookmarkLoading || copyBookmarkLoading || loading);
  }, [moveBookmarkLoading, copyBookmarkLoading, bookmarkContext.loading]);

  const onMoveBookmark = useCallback(
    (bookmarkGroup: BookmarkGroup) => {
      trackEditBookmarkMove(language);

      if (!loading) {
        moveBookmark({
          variables: {
            pageType: modal.payload.pageType,
            objectId: modal.payload.objectId,
            groupIdFrom: modal.payload.groupId,
            groupIdTo: bookmarkGroup.groupId,
          },
        }).then(response => {
          if (response?.data?.moveBookmark?.status === Status.Success) {
            dispatch({
              type: BookmarkEditModalActionType.SET_VIEW,
              payload: {
                view: BookmarkEditModalView.MOVE,
                bookmarkGroup,
              },
            });
            refetchBookmarkGroups();
          }
        });
      }
    },
    [modal.payload, loading]
  );

  const onCopyBookmark = useCallback(
    (bookmarkGroup: BookmarkGroup) => {
      if (!loading) {
        trackEditBookmarkCopy(language);

        copyBookmark({
          variables: {
            pageType: modal.payload.pageType,
            objectId: modal.payload.objectId,
            groupIdFrom: modal.payload.groupId,
            groupIdTo: bookmarkGroup.groupId,
          },
        }).then(response => {
          if (response?.data?.copyBookmark?.status === Status.Success) {
            dispatch({
              type: BookmarkEditModalActionType.SET_VIEW,
              payload: {
                view: BookmarkEditModalView.COPY,
                bookmarkGroup,
              },
            });
            refetchBookmarkGroups();
          }
        });
      }
    },
    [modal.payload, loading]
  );

  const onRemoveBookmark = useCallback(() => {
    if (!loading) {
      bookmarkContext.removeBookmark({
        objectId: modal.payload.objectId,
        pageType: modal.payload.pageType,
        groupId: modal.payload.groupId,
      });
      dispatch({
        type: BookmarkEditModalActionType.RESET,
      });
      bookmarkModalContext.close();
    }
  }, [modal.payload, loading]);

  const onCloseButtonClick = useCallback(() => {
    dispatch({ type: BookmarkEditModalActionType.RESET });
    bookmarkModalContext.close();
  }, []);

  const onRemoveButtonClick = useCallback(() => {
    trackEditBookmarkDelete(language);

    dispatch({
      type: BookmarkEditModalActionType.SET_VIEW,
      payload: {
        view: BookmarkEditModalView.REMOVE,
      },
    });
  }, []);

  const onBackButtonClick = useCallback(() => {
    dispatch({ type: BookmarkEditModalActionType.RESET });
  }, []);

  if (bookmarkGroupsLoading) {
    return null;
  }

  return (
    <>
      {bookmarkEditModalState.view === BookmarkEditModalView.DEFAULT && (
        <BookmarkEditDefaultPopup
          bookmarkGroups={(
            bookmarkGroupsData?.bookmarkPage?.allBookmarkGroups
              ?.bookmarkGroups || []
          ).filter(i => {
            return i.groupId !== modal.payload.groupId;
          })}
          onMoveBookmark={onMoveBookmark}
          onCopyBookmark={onCopyBookmark}
          onRemoveButtonClick={onRemoveButtonClick}
          onCloseButtonClick={onCloseButtonClick}
        />
      )}
      {bookmarkEditModalState.view === BookmarkEditModalView.REMOVE && (
        <BookmarkEditRemovePopup
          onRemoveBookmark={onRemoveBookmark}
          onCloseButtonClick={onCloseButtonClick}
          onBackButtonClick={onBackButtonClick}
        />
      )}
      {bookmarkEditModalState.view === BookmarkEditModalView.MOVE && (
        <BookmarkEditTextPopup onCloseButtonClick={onCloseButtonClick}>
          {t('bookmark.move.text', {
            name: bookmarkEditModalState.bookmarkGroup.name,
          })}
        </BookmarkEditTextPopup>
      )}
      {bookmarkEditModalState.view === BookmarkEditModalView.COPY && (
        <BookmarkEditTextPopup onCloseButtonClick={onCloseButtonClick}>
          {t('bookmark.copy.text', {
            name: bookmarkEditModalState.bookmarkGroup.name,
          })}
        </BookmarkEditTextPopup>
      )}
    </>
  );
};

export default BookmarkEditModal;
