import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BOOSTERS_CLOSED_COOKIE_NAME,
  getBrowserMetaModulesContext,
  setBrowserMetaModulesContext,
  useMetaModulesContext,
} from '@hotelplan/components.common.meta-modules';
import { Booster, BoosterFrequency } from '@hotelplan/graphql.types';
import { optionsWithPassive } from '@hotelplan/libs.polyfills';
import { BoosterCloseButton } from 'components/candidates/boosters/BoosterCloseButton';
import { BoosterList } from 'components/candidates/boosters/BoosterList';
import { BoosterWrap } from 'components/candidates/boosters/BoosterWrap';

interface IBoostersProps {
  boosters: Booster[];
  timesToRepeat?: number;
  className?: string;
}

const DEFAULT_TIMES_TO_REPEAT = 2;
const MS_IN_SECOND = 1000;
const CLOSED_BOOSTERS_FLAG_VALUE = 'true';

const areBoostersClosedOnce = (): boolean => {
  return !!Cookies.get(BOOSTERS_CLOSED_COOKIE_NAME);
};

const Boosters: React.FC<IBoostersProps> = ({
  boosters,
  timesToRepeat = DEFAULT_TIMES_TO_REPEAT,
  className,
}) => {
  const [t] = useTranslation('common');
  const { hideBoosters } = useMetaModulesContext();
  const [boostersOnceClosed, setBoostersOnceClosed] = useState(hideBoosters);

  const boostersToShow = useMemo(() => {
    // NOTE: We need to repeat boosters TIMES_TO_REPEAT times.
    return [].concat(...Array(timesToRepeat).fill(boosters)) as Booster[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boosters]);

  const isFirstBoosterDelayed = boostersToShow[0]?.delay;

  const [currentBoosterId, setCurrentBoosterId] = useState<string | undefined>(
    boostersOnceClosed || isFirstBoosterDelayed
      ? undefined
      : boostersToShow[0]?.id
  );

  useEffect(() => {
    if (boostersOnceClosed) return;
    setCurrentBoosterId(
      isFirstBoosterDelayed ? undefined : boostersToShow[0]?.id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boostersToShow]);

  const timerIDsRef = useRef<number[]>([]);

  const startTimersRef = useRef((boostersList: Booster[]) => {
    let durationToWait = 0;
    let showBoosterIndefinitely = false;

    boostersList.map(({ duration, frequency, delay }, index) => {
      // NOTE: If the zero duration was found or there is only one booster, then other boosters should not be shown
      // and the booster with 0 duration should stay indefinitely.
      if (
        index === boostersList.length - 1 &&
        (!duration ||
          boosters.length === 1 ||
          frequency === BoosterFrequency.Infinite)
      ) {
        showBoosterIndefinitely = true;
      }

      durationToWait += duration && !delay ? duration * MS_IN_SECOND : 0;

      if (
        delay &&
        !(
          !duration &&
          index === boostersList.length - 1 &&
          boostersList.length > 1
        )
      ) {
        const boosterTimer = setTimeout(
          (() => {
            setCurrentBoosterId(undefined);
          }) as TimerHandler,
          durationToWait
        );

        durationToWait += delay * MS_IN_SECOND;

        timerIDsRef.current.push(boosterTimer);
      }

      if (!showBoosterIndefinitely) {
        const boosterTimer = setTimeout(
          (() => {
            if (areBoostersClosedOnce()) return;
            const boosterIndex = delay ? index : index + 1;

            // NOTE: After timeout set the next booster.
            setCurrentBoosterId(
              boostersToShow[boosterIndex]
                ? boostersToShow[boosterIndex].id
                : undefined
            );
          }) as TimerHandler,
          durationToWait
        );

        timerIDsRef.current.push(boosterTimer);
      }

      durationToWait +=
        duration &&
        delay &&
        boostersList.length !== 1 &&
        !showBoosterIndefinitely
          ? duration * MS_IN_SECOND
          : 0;

      if (delay && boostersList.length - 1 === index) {
        const boosterTimer = setTimeout(
          (() => {
            if (areBoostersClosedOnce()) return;

            setCurrentBoosterId(
              showBoosterIndefinitely ? boostersToShow[index].id : undefined
            );
          }) as TimerHandler,
          durationToWait
        );

        timerIDsRef.current.push(boosterTimer);
      }
    });
  });

  useEffect(() => {
    if (boostersOnceClosed) return;
    if (!currentBoosterId && !isFirstBoosterDelayed) return;

    function onLoad() {
      startTimersRef.current(boostersToShow);
    }

    function clearTimeouts() {
      timerIDsRef.current.map(timerID => clearTimeout(timerID));
      timerIDsRef.current = [];
    }

    if (document.readyState === 'complete') {
      const requestId = window.requestAnimationFrame(onLoad);

      return function () {
        clearTimeouts();
        window.cancelAnimationFrame(requestId);
      };
    }

    window.addEventListener('load', onLoad, optionsWithPassive);

    return () => {
      clearTimeouts();
      window.removeEventListener('load', onLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boosters]);

  const onCloseHandler = useCallback(() => {
    Cookies.set(BOOSTERS_CLOSED_COOKIE_NAME, CLOSED_BOOSTERS_FLAG_VALUE);
    setBoostersOnceClosed(true);
    setBrowserMetaModulesContext({
      ...getBrowserMetaModulesContext(),
      hideBoosters: true,
    });
    setCurrentBoosterId(undefined);
  }, []);

  if (boostersOnceClosed) return null;

  return (
    <BoosterList className={className} data-id={`booster-list`}>
      {boosters?.map((booster, key) => {
        if (currentBoosterId !== booster.id) return;

        return (
          <BoosterWrap
            key={key}
            data-id={`booster-${booster.id}`}
            className="booster-wrapper"
          >
            <div
              className="booster-text"
              dangerouslySetInnerHTML={{ __html: booster.text }}
            />
            <BoosterCloseButton onClick={onCloseHandler}>
              {t('common:close')}
            </BoosterCloseButton>
          </BoosterWrap>
        );
      })}
    </BoosterList>
  );
};

export default Boosters;
