import hoistNonReactStatics from 'hoist-non-react-statics';
import { AppProps } from 'next/app';
import React, { ReactElement } from 'react';
import { ExactSearchContextProvider } from './ExactSearchContext';

export default function appWithExactSearch(
  WrappedAppComponent:
    | React.ComponentType<AppProps>
    | React.ElementType<AppProps>
) {
  function AppWithExactSearch(props: AppProps): ReactElement {
    return (
      <ExactSearchContextProvider>
        <WrappedAppComponent {...props} />
      </ExactSearchContextProvider>
    );
  }

  return hoistNonReactStatics(AppWithExactSearch, WrappedAppComponent);
}
