import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const BoosterCloseButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: {
    name: 'close',
  },
})(
  sx2CssThemeFn({
    '.icon': {
      variant: [null, 'icons.lg'],
    },
    mr: [null, 3],
    backgroundColor: 'transparent',
    mt: ['6px', '20px'],
    flexShrink: '0',
  })
);
