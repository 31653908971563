import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';

const BookmarkButton = styled.button({
  zIndex: 1,
});

interface IBookmarkIconPros {
  onClick(): void;
  active: boolean;
}

const BookmarkIcon: React.FC<IBookmarkIconPros> = ({ active, onClick }) => {
  const [t] = useTranslation('bookmark');
  const icon = active ? (
    <>
      <Icon name="wishlist-active" className="wishlist-icon active" />
      <span className="button-text">{t('bookmark:removeFromBookmark')}</span>
    </>
  ) : (
    <>
      <Icon name="wishlist" className="wishlist-icon" />
      <span className="button-text">{t('bookmark:addToBookmark')}</span>
    </>
  );

  return (
    <BookmarkButton
      className={'wishlist-icon-button'}
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      {icon}
    </BookmarkButton>
  );
};

export default BookmarkIcon;
