import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import {
  languageCookieName,
  languageDefault,
  languageList,
} from '@hotelplan/libs.language';
import { useLanguageUrls } from '@hotelplan/libs.language-urls';
import { useCurrentRoute } from '@hotelplan/libs.router-link-utils';

interface IURLsMap {
  [lang: string]: string;
}

const enhanceLanguagePathname = (pathname: string, lang: string): string => {
  const [path] = pathname.split('?');

  if (path === '/' && lang === languageDefault) {
    return `/${languageDefault}`;
  }

  return pathname;
};

function updateUrlsMapWithQuery(urlsMap: IURLsMap, query: string): IURLsMap {
  const queryString = query?.replace(/^\?/, '');
  if (!queryString) return urlsMap;
  const res: IURLsMap = {};
  Object.keys(urlsMap).forEach(lang => {
    res[lang] = `${urlsMap[lang]}?${query}`;
  });
  return res;
}

interface IUseGetLanguageBasedURLsResults {
  loading: boolean | undefined;
  language: string;
  urls: IURLsMap;
}

export function useGetLanguageBasedURLs(): IUseGetLanguageBasedURLsResults {
  const [, { language }] = useTranslation();
  const { urls } = useLanguageUrls();
  const currentRouting = useCurrentRoute();

  return useMemo(() => {
    const { getAlternative, routeParams, query } = currentRouting;

    const routeBasedUrls = getAlternative
      ? languageList.reduce((urlsMap, lang) => {
          const altRoute = getAlternative(lang);
          let url;
          if (altRoute) {
            const altURL = altRoute.builder(routeParams);
            url = altURL.toString();
          }
          if (!url) return urlsMap;
          return {
            ...urlsMap,
            [lang]: enhanceLanguagePathname(url, lang),
          };
        }, {} as Record<string, string>)
      : {};

    return {
      loading: false,
      language,
      urls: updateUrlsMapWithQuery({ ...routeBasedUrls, ...urls }, query),
    };
  }, [urls, currentRouting, language]);
}

export function navigateLangBasedURL(
  urlsMap: IURLsMap,
  nextLanguage: string
): void {
  if (urlsMap[nextLanguage]) {
    const nextURL = urlsMap[nextLanguage];

    if (nextURL) {
      window.location.href = `${nextURL}`;
    }
  }
}

export default function useLanguage() {
  const { urls: langBasedURLs, language } = useGetLanguageBasedURLs();

  const changeLanguage = useCallback(
    async (nextLanguage: string): Promise<void> => {
      Cookies.set(languageCookieName, nextLanguage, {
        // just make the cookie not session based
        expires: new Date(new Date().getFullYear() + 1, 1),
        path: '/',
      });

      requestAnimationFrame(() => {
        navigateLangBasedURL(langBasedURLs, nextLanguage);
      });
    },
    [langBasedURLs]
  );

  return [language, changeLanguage] as const;
}
