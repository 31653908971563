import React from 'react';
import styled from 'styled-components';
import { PageType } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import HeaderRequestButton from 'components/domain/header-request-button/HeaderRequestButton';
import { openSearchOverlay } from 'components/domain/search';
import ContactsSiteTool from 'components/domain/sitetools/ContactsSiteTool';
import MenuSiteTool from 'components/domain/sitetools/MenuSiteTool';
import SearchSiteTool from 'components/domain/sitetools/SearchSiteTool';
import { SiteToolsWrapper } from 'components/domain/sitetools/SiteTools.common';

const MobileBottomMenuWrapper = styled.div({
  position: 'sticky',
  bottom: '16px',
  height: 'auto',
  minHeight: '58px',
  zIndex: 99,
  transition: '0.3s ease',
});

const BottomMenuItemsWrapper = styled.div(({ theme: { colors } }) =>
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    width: 'fit-content',
    margin: '0 auto',
    backgroundColor: colors.lightGreySecond,
    borderRadius: '8px',
    padding: '8px',
    alignItems: 'flex-start',
    '.static-request-button-wrapper': {
      marginLeft: [1],
      '.request-button': {
        borderRadius: '5px',
        padding: '9px 26px 11px',
        '.request-button-caption': {
          fontWeight: '400',
          letterSpacing: '0.5px',
          lineHeight: [null, '22px'],
        },
      },
    },
    button: {
      '&:hover': {
        '~ .tooltip': {
          bottom: '53px',
          top: 'auto',
          width: 'max-content',
          '&:before': {
            bottom: '-8px',
            top: 'unset',
          },
          '&:first-letter': {
            textTransform: `capitalize`,
          },
        },
      },
    },
  })
);

const MobileBottomMenu: React.FC<{ pageType: PageType }> = ({ pageType }) => {
  return (
    <MobileBottomMenuWrapper className={`mobile-bottom-menu`}>
      <BottomMenuItemsWrapper>
        <SiteToolsWrapper>
          <MenuSiteTool />
          <SearchSiteTool onSearch={openSearchOverlay} />
          <ContactsSiteTool />
        </SiteToolsWrapper>
        <HeaderRequestButton pageType={pageType} />
      </BottomMenuItemsWrapper>
    </MobileBottomMenuWrapper>
  );
};

export default MobileBottomMenu;
