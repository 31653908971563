import { ParsedUrlQuery } from 'querystring';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { PageType } from '@hotelplan/platform-graphql-api';

type TProps = { pageType: PageType };

function getGeoType(query: ParsedUrlQuery) {
  const id = query?.id || '';

  return id.includes('c-')
    ? 'Country'
    : id.includes('k-')
    ? 'Continent'
    : 'Region';
}

function getPdpType(query: ParsedUrlQuery) {
  const id = query?.id || '';

  return id.includes('a-')
    ? 'PDP Adventure Travel'
    : id.includes('h-')
    ? 'PDP Extraordinary Hotel'
    : '';
}

function getType(pageType: PageType, query: ParsedUrlQuery) {
  switch (pageType) {
    case PageType.GeoObject:
      return `Geo Page (${getGeoType(query)})`;

    case PageType.Home:
      return `Home`;

    case PageType.GeoOverview:
      return `Geo Overview Page`;

    case PageType.ThemeObject:
      return `Theme page`;

    case PageType.ThemeOverview:
      return `Theme overview page`;

    case PageType.Pdp:
      return getPdpType(query);

    case PageType.BlogOverview:
      return `Blog overview page`;

    case PageType.BlogArticle:
      return `Blog Detailpage`;

    case PageType.CatalogOverview:
      return `Catalogues`;

    case PageType.AgencyOverview:
      return `Agency overview`;

    case PageType.AgencyObject:
      return `Agency Detailpage`;

    case PageType.Static:
      return `Static page`;

    default:
      return '';
  }
}

const OgType: React.FC<TProps> = ({ pageType }: TProps) => {
  const { query } = useRouter();
  const type = getType(pageType, query);

  return (
    <Head>
      <meta key="og:type" property="og:type" content={type} />
    </Head>
  );
};

export default OgType;
