import clamp from 'lodash/clamp';
import max from 'lodash/max';
import { useTranslation } from 'next-i18next';
import React, { HTMLAttributes, PropsWithChildren, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { ArrowPagination } from 'components/domain/arrow-pagination/ArrowPagination';
import { SliderArrowPagination } from 'components/domain/arrow-pagination/SliderArrowPagination';
import {
  CARDS_GAP,
  CARDS_PER_PAGE,
  SWIPER_ITEM_SIZE_RATIO,
  CARD_MAX_WIDTH,
  SECTION_WIDTH,
  ICardSectionSlidersProps,
} from 'components/domain/customizable-slider/CustomizableSlider.constants';
import {
  SectionWrapper,
  CardsSectionContent,
  SectionHeader,
  SectionTitle,
  SectionMainTitle,
  SectionTotalCount,
  PaginationControlLoader,
  SwiperWrapper,
  SliderWrapper,
  SliderItems,
} from './CardsSection.styles';
import {
  ICardsSectionHeaderProps,
  TCardsSectionProps,
} from './CardsSection.types';
import { useScrollPosition } from './useScrollPosition';

export function CardsSectionHeaderPlaceholder({
  style,
  className,
  isSRL,
}: Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> &
  Pick<ICardsSectionHeaderProps, 'isSRL'>): React.ReactElement {
  const [t] = useTranslation('search');
  return (
    <SectionHeader style={style} className={className} isSRL={isSRL}>
      <SectionTitle isSRL={isSRL} key="title">
        {t('search:loading')}
      </SectionTitle>
    </SectionHeader>
  );
}

export function CardsSectionHeader(
  props: ICardsSectionHeaderProps &
    Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'>
): React.ReactElement {
  const {
    title,
    totalCount,
    className,
    style,
    paginationLoading,
    isSRL,
    isSliderPagination,
    sectionTitle,
    routingMap,
    ...paginationProps
  } = props;
  const { mobile } = useDeviceType();

  return (
    <>
      {!isSRL && title ? (
        <SectionMainTitle style={style} key="mainTitle">
          {title}
        </SectionMainTitle>
      ) : null}

      <SectionHeader style={style} className={className} isSRL={isSRL}>
        {(isSRL && title) || sectionTitle ? (
          <SectionTitle key="title" isSRL={isSRL}>
            {sectionTitle ? sectionTitle : title}
            {totalCount && title ? ':' : null}
          </SectionTitle>
        ) : null}
        {totalCount ? (
          <>
            {paginationLoading ? (
              <PaginationControlLoader isSRL={isSRL} />
            ) : (
              <SectionTotalCount isSRL={isSRL} key="count" className={'count'}>
                {totalCount}
              </SectionTotalCount>
            )}
          </>
        ) : null}
        {routingMap}
        {!mobile && paginationProps.totalPages > 1 && !isSliderPagination ? (
          <>
            {paginationLoading ? (
              <PaginationControlLoader side="right" isSRL={isSRL} />
            ) : (
              <ArrowPagination
                className="card-section-arrow-pagination"
                {...paginationProps}
              />
            )}
          </>
        ) : null}
      </SectionHeader>
    </>
  );
}

export function CardsSection(
  props: PropsWithChildren<TCardsSectionProps>
): React.ReactElement {
  const {
    children,
    className,
    style,
    headerStyle,
    loading,
    underSectionNode,
    ...headerProps
  } = props;

  const isSliderPagination = headerProps.isSliderPagination || false;

  return (
    <SectionWrapper {...{ className, style }}>
      {loading ? (
        <CardsSectionHeaderPlaceholder
          style={headerStyle}
          isSRL={headerProps.isSRL}
        />
      ) : (
        <CardsSectionHeader style={headerStyle} {...headerProps} />
      )}
      <CardsSectionContent>
        {!isSliderPagination || headerProps.paginationLoading ? (
          <>{children}</>
        ) : (
          <SliderArrowPagination {...headerProps}>
            {children}
          </SliderArrowPagination>
        )}
      </CardsSectionContent>
      {underSectionNode}
    </SectionWrapper>
  );
}

export const SwiperItemWrapper = styled.div<{
  hideDelimiter: boolean;
  cardsGap: number;
}>(({ theme: { media }, cardsGap }) => ({
  position: 'relative',
  flexShrink: 0,
  display: 'flex',
  height: `fit-content`,
  flexDirection: 'column',
  maxHeight: `-webkit-fill-available`,
  width: `${SWIPER_ITEM_SIZE_RATIO * 100}%`,
  marginRight: `${cardsGap}px`,
  '&:not(:last-child)': {
    marginRight: `${cardsGap}px`,
  },
  img: {
    pointerEvents: 'none',
  },
  a: {
    userSelect: 'none',
    '-webkit-user-drag': 'none',
    width: '100%',
  },
  [media.mobile]: {
    width: '80%',
    maxWidth: `${CARD_MAX_WIDTH}px`,
  },
}));

export function CardsSectionSwiper({
  className,
  total,
  page,
  perPage = CARDS_PER_PAGE,
  onChangePage,
  placeholder,
  style,
  children,
  hideDelimiter,
  cardsGap = CARDS_GAP,
}: PropsWithChildren<ICardSectionSlidersProps>): React.ReactElement {
  const count = React.Children.count(children);
  const restCount = total - count;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const firstItemRef = useRef<HTMLDivElement>(null);

  const minIndexToShow = (page - 1) * perPage;
  const maxIndexToShow = (page + 1) * perPage + (perPage - 1);

  useScrollPosition(
    wrapperRef,
    firstItemRef,
    onChangePage,
    cardsGap,
    perPage,
    page
  );

  return (
    <SwiperWrapper ref={wrapperRef} className={className} style={style}>
      {React.Children.map(children, (child, index) => (
        <SwiperItemWrapper
          ref={index === 0 ? firstItemRef : undefined}
          key={index}
          hideDelimiter={hideDelimiter}
          cardsGap={cardsGap}
        >
          {index >= minIndexToShow && index <= maxIndexToShow
            ? child
              ? child
              : placeholder?.(index)
            : null}
        </SwiperItemWrapper>
      ))}
      {Array.from({ length: restCount }).map((_, index) => {
        const realIdx = count + index;
        return (
          <SwiperItemWrapper
            key={realIdx}
            hideDelimiter={hideDelimiter}
            cardsGap={cardsGap}
          >
            {realIdx >= minIndexToShow && realIdx <= maxIndexToShow
              ? placeholder?.(realIdx)
              : null}
          </SwiperItemWrapper>
        );
      })}
    </SwiperWrapper>
  );
}

export const SliderItemWrapper = styled(SwiperItemWrapper)<{
  perPage: number;
  cardsGap: number;
}>(({ perPage, cardsGap }) => ({
  width: `calc(${SECTION_WIDTH - cardsGap * (perPage - 1)}px / ${perPage})`,
  flexShrink: 1,
  transition: 'all 0.5s ease',
  '& a': {
    width: '100%',
    display: 'block',
  },
  '&:last-child': {
    marginRight: 0,
  },
  '&:nth-child(3n):after, &:empty:after, &.last:after': {
    width: 0,
  },
  '.teaser-media img': {
    transition: 'all 0.5s ease',
  },
  '&:hover': {
    '.teaser-media img': {
      transform: 'scale(1.1)',
      transition: 'all 0.5s ease',
    },
    '.breadcrumb-wrap': {
      '.icon': {
        transform: 'translateX(10px)',
        transition: 'all 0.5s ease',
      },
    },
  },
}));

const SingleItemWrapper = styled.div<{
  possiblePagesCount: number;
  cardsGap: number;
}>(({ possiblePagesCount, cardsGap }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: `calc((100% - ${
    (possiblePagesCount - 1) * cardsGap
  }px) / ${possiblePagesCount})`,
  flexShrink: 0,
  img: {
    pointerEvents: 'none',
  },
  a: {
    userSelect: 'none',
    '-webkit-user-drag': 'none',
    width: '100%',
  },
}));

export function CardSectionSlider({
  children,
  className,
  page,
  perPage = CARDS_PER_PAGE,
  total = 0,
  placeholder,
  style,
  onChangePage,
  hideDelimiter,
  cardsGap = CARDS_GAP,
}: PropsWithChildren<ICardSectionSlidersProps>): React.ReactElement {
  const count = React.Children.count(children);
  const notLoadedCount = max([total - count, 0]);
  const possiblePagesCount = total ? Math.ceil(total / perPage) : 1;
  const loadedCeilCount = possiblePagesCount * perPage;
  const missingPlaceCount = loadedCeilCount - total;
  const singleItemPerPage = perPage === 1;

  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    onSwiped({ dir }) {
      const nextPage = clamp(
        dir === 'Left' ? page + 1 : dir === 'Right' ? page - 1 : page,
        0,
        possiblePagesCount - 1
      );
      nextPage !== page && onChangePage?.(nextPage);
    },
  });

  const minIndexToShow = (page - 1) * perPage;
  const maxIndexToShow = (page + 1) * perPage + (perPage - 1);

  const SliderItem = singleItemPerPage
    ? SingleItemWrapper
    : (SliderItemWrapper as React.ElementType);
  const leftGap = singleItemPerPage ? 0 : page * cardsGap;
  const extraProps = {
    ...(!singleItemPerPage && { hideDelimiter, cardsGap, perPage }),
  };

  return (
    <SliderWrapper
      {...handlers}
      className={className}
      style={style}
      draggable={false}
    >
      <SliderItems
        style={{
          width: `calc(${possiblePagesCount * 100}% + ${
            (possiblePagesCount - 1) * cardsGap
          }px)`,
          left: page > 0 ? `calc(${-100 * page}% - ${leftGap}px)` : 0,
        }}
      >
        {React.Children.map(children, (child, index) => (
          <SliderItem
            className={count - 1 === index ? `last` : undefined}
            key={index}
            possiblePagesCount={possiblePagesCount}
            cardsGap={cardsGap}
            {...extraProps}
          >
            {/* Do not render items out of bounds */}
            {index >= minIndexToShow && index <= maxIndexToShow
              ? child
                ? child
                : placeholder?.(index)
              : null}
          </SliderItem>
        ))}
        {Array.from({ length: notLoadedCount }).map((_, index) => {
          const realIdx = count + index;
          return (
            <SliderItem key={realIdx} {...extraProps}>
              {realIdx >= minIndexToShow && realIdx <= maxIndexToShow
                ? placeholder?.(realIdx)
                : null}
            </SliderItem>
          );
        })}
        {Array.from({ length: missingPlaceCount }).map((_, index) => (
          <SliderItem key={total + index} {...extraProps} />
        ))}
      </SliderItems>
    </SliderWrapper>
  );
}

export function AdaptiveCardsSlider(
  props: PropsWithChildren<ICardSectionSlidersProps>
): React.ReactElement {
  const { mobile } = useDeviceType();
  return mobile ? (
    <CardsSectionSwiper {...props} />
  ) : (
    <CardSectionSlider {...props} />
  );
}
