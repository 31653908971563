import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Booster, BoosterFrequency } from '@hotelplan/graphql.types';
import { Boosters } from 'components/candidates/boosters';
import { useGetBoostersData } from './useGetBoostersData';

const BoostersWrapper = styled(Boosters)(
  ({ theme: { colors, fontSizes, media, space } }) => ({
    bottom: '76px',
    [media.tablet]: {
      bottom: space[3],
    },
    '.booster-wrapper': {
      backgroundColor: colors.primary,
      borderRadius: '0',
      marginBottom: '0px',
      '.booster-text': {
        padding: `16px 22px 16px 12px`,
        lineHeight: '1.2',
        [media.tablet]: {
          padding: `16px 100px 16px 120px`,
        },
      },
      '.booster-text p': {
        fontSize: fontSizes[2],
        [media.tablet]: {
          fontSize: '22px',
        },
      },
      '.booster-close-btn': {
        marginTop: space[2],
        marginBottom: space[2],
        [media.tablet]: {
          marginTop: space[3],
          marginBottom: space[3],
        },
        '.icon': {
          width: '20px',
          height: '20px',
        },
      },
    },
  })
);

const BoostersBanner: React.FC = () => {
  const { boosters } = useGetBoostersData();

  const frequencyBoosters: Booster[] = useMemo(() => {
    return boosters
      .slice(0)
      .concat(
        boosters
          .filter(
            b =>
              b.frequency === BoosterFrequency.Twice ||
              b.frequency === BoosterFrequency.Infinite
          )
          .map((b, i) => ({ ...b, id: `${b.id}_copy-${i}` }))
      );
  }, [boosters]);

  if (frequencyBoosters.length === 0) return null;

  return <BoostersWrapper boosters={frequencyBoosters} timesToRepeat={1} />;
};

export default BoostersBanner;
