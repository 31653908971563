import { useRenameBookmarkGroupMutation } from 'graphql/bookmark/RenameBookmarkGroup.generated';

export function useRenameBookmarkGroup() {
  const [mutation, { data, loading }] = useRenameBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['GetBookmarkGroups'],
  });

  async function renameBookmarkGroup(groupId: string, groupName: string) {
    return mutation({
      variables: {
        groupId,
        groupName,
      },
    });
  }

  return {
    renameBookmarkGroup,
    data,
    loading,
  };
}
