import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';

interface IBreadcrumbsSkeletonProps {
  itemCount?: number;
  delimiter?: string;
  className?: string;
}

const MAX_WIDTH = 120;
const ORIGINAL_WIDTH = 120;
const ORIGINAL_HEIGHT = 20;

const BreadcrumbSkeletonWrapper = styled.div({
  display: 'flex',
  width: '100%',
  maxWidth: MAX_WIDTH,
});

// @todo add delimiter content `/` as :after or :before pseudo el
const BreadcrumbDelimiter = styled.div(({ theme: { space } }) => ({
  display: 'inline',
  margin: `0 ${space[2]}`,
}));

const BreadcrumbSkeleton: React.FC<IBreadcrumbsSkeletonProps> = props => {
  const { className, itemCount = 1, delimiter = '/' } = props;

  return (
    <>
      {Array.from({ length: itemCount }, (__, i) => (
        <React.Fragment key={i}>
          <BreadcrumbSkeletonWrapper className={className}>
            <PlaceholderLoader
              height={ORIGINAL_HEIGHT}
              width={ORIGINAL_WIDTH}
              uid={`breadcrumbSkeleton-${i}`}
              style={{ height: '100%', width: '100%' }}
            >
              <rect
                x="0"
                y="0"
                rx="2"
                ry="2"
                width={ORIGINAL_WIDTH}
                height={ORIGINAL_HEIGHT}
              />
            </PlaceholderLoader>
          </BreadcrumbSkeletonWrapper>
          {i !== itemCount - 1 && (
            <BreadcrumbDelimiter>{delimiter}</BreadcrumbDelimiter>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default BreadcrumbSkeleton;
