import React from 'react';
import styled from 'styled-components';
import { IRouterLinkProps } from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Breadcrumb } from 'components/candidates/breadcrumb';

export interface INormalizedBreadcrumb {
  label?: string | null;
  link?: IRouterLinkProps;
  disabled?: boolean;
  onClick?: () => void;
}

interface IBreadcrumbsSectionProps {
  breadcrumbs: INormalizedBreadcrumb[];
}

const BreadcrumbsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexGrow: '1',
    width: '100%',
    maxWidth: '1440px',
    borderRadius: '0 0 5px 5px',
    padding: '10px 16px 2px 16px',
    backgroundColor: 'paperGrey',
    '.geo-breadcrumb': {
      fontSize: '12px',
    },
  })
);

const BreadcrumbsSection: React.FC<IBreadcrumbsSectionProps> = ({
  breadcrumbs,
}) => {
  const { mobile } = useDeviceType();
  const pageType = usePageType();

  const breadcrumbsWithoutTitle = breadcrumbs.filter(
    ({ disabled }) => !disabled
  );

  return (
    <BreadcrumbsWrapper>
      <Breadcrumb
        testId="GeoBreadCrumbs"
        className="geo-breadcrumb"
        items={
          mobile && pageType === PageType.Pdp
            ? breadcrumbsWithoutTitle
            : breadcrumbs
        }
        delimiter={`›`}
      />
    </BreadcrumbsWrapper>
  );
};

export default BreadcrumbsSection;
