import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type AddressDetailsFragment = {
  __typename?: 'AddressDetails';
  city?: string | null;
  country?: string | null;
  region?: string | null;
  street?: string | null;
  zip?: string | null;
};

export const AddressDetailsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'addressDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
