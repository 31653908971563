import React from 'react';
import styled from 'styled-components';
import BreadcrumbsSection, {
  INormalizedBreadcrumb,
} from 'components/domain/breadcrumbs-section/BreadcrumbsSection';

type THeaderBreadcrumbsProps = {
  breadcrumbs?: INormalizedBreadcrumb[];
};

const BreadcrumbsWrapper = styled.div(({ theme: { media } }) => {
  return {
    width: '100%',
    maxWidth: '1480px',
    margin: '0 auto',
    zIndex: '99',
    [media.mobile]: {
      boxShadow: '0px 5px 10px -5px rgba(0, 0, 0, 0.1)',
    },
  };
});

const HeaderBreadcrumbs: React.FC<THeaderBreadcrumbsProps> = ({
  breadcrumbs,
}) => {
  return (
    <>
      {breadcrumbs?.length ? (
        <BreadcrumbsWrapper>
          <BreadcrumbsSection breadcrumbs={breadcrumbs} />
        </BreadcrumbsWrapper>
      ) : null}
    </>
  );
};

export default HeaderBreadcrumbs;
