import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { RequestButtonFragmentDoc } from './RequestButton.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFloatingRequestButtonQueryVariables = Types.Exact<{
  pageType: Types.PageType;
  context: Types.RequestContextInput;
  id?: Types.InputMaybe<Types.Scalars['String']>;
  currentUrl?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetFloatingRequestButtonQuery = {
  __typename?: 'Query';
  requestButton?: {
    __typename?: 'RequestButtonComponent';
    fontColor?: string | null;
    backgroundColor?: string | null;
    caption: string;
    link: {
      __typename?: 'ExternalLink';
      openMethod: Types.OpenLinkMethod;
      url: string;
      caption?: string | null;
    };
  } | null;
};

export const GetFloatingRequestButtonDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFloatingRequestButton' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PageType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentUrl' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestButton' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestContext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currentUrl' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'requestButton' },
                },
              ],
            },
          },
        ],
      },
    },
    ...RequestButtonFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFloatingRequestButtonQuery__
 *
 * To run a query within a React component, call `useGetFloatingRequestButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloatingRequestButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloatingRequestButtonQuery({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      context: // value for 'context'
 *      id: // value for 'id'
 *      currentUrl: // value for 'currentUrl'
 *   },
 * });
 */
export function useGetFloatingRequestButtonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFloatingRequestButtonQuery,
    GetFloatingRequestButtonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFloatingRequestButtonQuery,
    GetFloatingRequestButtonQueryVariables
  >(GetFloatingRequestButtonDocument, options);
}
export function useGetFloatingRequestButtonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFloatingRequestButtonQuery,
    GetFloatingRequestButtonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFloatingRequestButtonQuery,
    GetFloatingRequestButtonQueryVariables
  >(GetFloatingRequestButtonDocument, options);
}
export type GetFloatingRequestButtonQueryHookResult = ReturnType<
  typeof useGetFloatingRequestButtonQuery
>;
export type GetFloatingRequestButtonLazyQueryHookResult = ReturnType<
  typeof useGetFloatingRequestButtonLazyQuery
>;
export type GetFloatingRequestButtonQueryResult = Apollo.QueryResult<
  GetFloatingRequestButtonQuery,
  GetFloatingRequestButtonQueryVariables
>;
