import React, { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import {
  AddressDetails,
  SrlProductInfoB2B,
  SrlProductInfoB2C,
} from '@hotelplan/graphql.types';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { bookmarkablePageTypes } from 'components/domain/bookmark/Bookmark.types';
import BookmarkBlock from 'components/domain/bookmark/BookmarkBlock';
import { DisruptersSection } from 'components/domain/disrupters/Disrupters';
import { TeaserImageCard } from 'components/domain/teaser-image-card';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { DisrupterFragment } from 'graphql/fragments/Disrupter.generated';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';
import SearchItemDescription from './SearchItemDescription';

interface ISearchItemProps {
  link: InternalLinkFragmentFragment;
  disrupters: DisrupterFragment[];
  name: string;
  image: INormalizedImage;
  highlightedText: string;
  lazy: boolean;
  addressDetails?: AddressDetails;
  opened?: boolean;
  breadcrumbs?: string[];
  debugInfo?: string;
  className?: string;
  bookmarked?: boolean;
  productInfoB2B?: Omit<SrlProductInfoB2B, '__typename'>;
  productInfoB2C?: SrlProductInfoB2C;
  sectionKey?: string;
  mainText?: string;
  googleStaticMapImage?: INormalizedImage;
  getImageHeight?: (height: number) => number;
}

const TeaserLink = styled(Link)({
  position: 'relative',
  height: '100%',
  width: '100%',
  '&:hover': {
    color: 'inherit',
  },
});

const TeaserImageCardWrapper = styled(TeaserImageCard)(
  ({ theme: { colors, space, fontSizes } }) => ({
    color: 'white',
    '.debug-info': {
      position: 'absolute',
      top: space[3],
      '&:hover': {
        fontSize: fontSizes[3],
        background: colors.primary,
        padding: '5px',
        borderRadius: '5px',
        transition: '0.3s ease',
      },
    },
    '.media-card-content': {
      '.disrupter-wrapper': {
        marginBottom: space[2],
      },
    },
    '&.is-geo': {
      '.teaser-media': {
        maxHeight: '274px',
        '&:before': {
          zIndex: 0,
        },
        '&:after': {
          content: "''",
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: colors.imageColorOverlay,
          mixBlendMode: 'multiply',
          zIndex: 1,
        },
      },
    },
    '.teaser-media:before': {
      zIndex: 0,
    },
  })
);

const SearchItemWrapper = styled.div({
  position: 'relative',
  '.wishlist-icon-button': {
    position: 'absolute',
    right: '16px',
    top: '16px',
    cursor: 'pointer',
    zIndex: 5,
    '.wishlist-icon': {
      width: '26px',
      height: '26px',
      color: 'white',
    },
    '.button-text': {
      display: 'none',
    },
  },
});

export default function SearchItem(props: ISearchItemProps): ReactElement {
  const {
    image,
    name,
    highlightedText,
    link,
    disrupters,
    lazy,
    opened,
    addressDetails,
    breadcrumbs,
    debugInfo,
    className,
    bookmarked,
    sectionKey,
    productInfoB2B,
    productInfoB2C,
    mainText,
    googleStaticMapImage,
    getImageHeight,
  } = props;

  const imageRef = useRef<HTMLDivElement>(null);
  const { h } = useElementSize(imageRef);
  const { channelType } = useAuthentication();

  useEffect(() => {
    getImageHeight && getImageHeight(h);
  }, [h, getImageHeight]);

  const isB2B = channelType === AuthChannelType.B2B;

  return (
    <SearchItemWrapper>
      <RouterLink {...getTargetPageData(link)}>
        <TeaserLink className={className} target={isB2B ? '_blank' : undefined}>
          <div ref={imageRef}>
            <TeaserImageCardWrapper
              image={
                sectionKey === 'adventuretravel' &&
                isB2B &&
                googleStaticMapImage
                  ? googleStaticMapImage
                  : image
              }
              lazy={lazy}
              className={sectionKey === 'geo' ? 'is-geo' : ''}
            >
              <DisruptersSection
                disrupters={disrupters}
                className="disrupter-wrapper"
              />
              {debugInfo ? (
                <div
                  dangerouslySetInnerHTML={{ __html: debugInfo }}
                  className="debug-info"
                />
              ) : null}
            </TeaserImageCardWrapper>
          </div>
          <SearchItemDescription
            imageHeight={h}
            name={name}
            highlightedText={highlightedText}
            addressDetails={addressDetails}
            breadcrumbs={breadcrumbs}
            opened={opened}
            productInfoB2B={productInfoB2B}
            productInfoB2C={productInfoB2C}
            mainText={mainText}
            sectionKey={sectionKey}
          />
        </TeaserLink>
      </RouterLink>
      {bookmarkablePageTypes.includes(link.targetPageType) && (
        <BookmarkBlock
          pageType={link.targetPageType}
          objectId={link.objectId}
          active={bookmarked}
          trackingComponent={BookmarkComponentType.SRL}
        />
      )}
    </SearchItemWrapper>
  );
}
